// podle src/features/carSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getFetchURL } from "src/helpers/actionHelpers";
import { getSessionCompanyId } from "src/helpers/sessionHelper";

import {  fetchCompanyList } from "src/features/listsSlice";

const initialState = {
  companyList: []/*,
  userSpecialProfile: [],
  userProfileTypeList: [],
  companiesUserList: []*/
};

//console.log('DEBUG 1');
/*
export const fetchCompaniesByUser = createAsyncThunk("lists/fetchCompaniesByUser", async (userId, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/company-user/user/get/${userId}`));
  return response.data;
});

export const fetchUserProfileTypes = createAsyncThunk("lists/userProfileTypes", async (_, { dispatch }) => {
  const response = await axios.get(getFetchURL("/user-profile-type/get"));
  return response.data;
});

export const requestUserSpecialProfileBy = createAsyncThunk(
  "auth/requestUserSpecialProfileBy",
  async (userId, { dispatch }) => {
    const response = await axios.get(getFetchURL(`/user-profile/get/${userId}`));

    return response.data;
  }
);*/

/*
export const fetchUserListByCompanyId = createAsyncThunk("users/fetchUserListByCompanyId", async (_, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/user/company/get-get-limited/${getSessionCompanyId()}`));
  return response.data;
}); 2022 3 3 */


//console.log('DEBUG 2');
/*
export const fetchCarListByCompanyId = createAsyncThunk("company/fetchCompanyListByCompanyId", async (_, { dispatch }) => {
  //err ?const response = await axios.get(getFetchURL(`/company/company/get-by-company/${getSessionCompanyId()}`)).catch(  error=> this.setState({error, isLoading: false}));;
	//console.log('DEBUG 3');//vola se
  const response = await axios.get(getFetchURL(`/company/company/get-by-company/${getSessionCompanyId()}`));
   console.log('DEBUG companySlice.js export const fetchCompanyListByCompanyId = createAsyncThunk ; const response =  ', response); 

//	console.log('DEBUG 3.1');//vola se 
  return response.data;
});*/

/*
// protoze potrebujeme obnovit data v dialogu; v listsSlice je duplicit.
export const fetchCompanyList = createAsyncThunk("company/fetchCompanyList", async (_, { dispatch }) => {
  const response = await axios.get(getFetchURL("/company/get"));

  console.log('DEBUG fetchCompanyList: response.data', response.data);

  return response.data;
});*/

export const insertCompany = createAsyncThunk("company/insertCompany", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/company/insert")
  };

  const response = await axios(options).then((_) => {
    dispatch(fetchCompanyList());
  });

  return response.data;
});

export const editCompany = createAsyncThunk("company/editCompany", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/company/edit")
  };

  console.log('DEBUG editCompany: options', options); //ok vcetne data
  //undef. console.log('DEBUG editCompany: options.apiData', options.apiData);

  const response = await axios(options).then((_) => {
    console.log('DEBUG pred dispatch(fetchCompanyList());');
    dispatch(fetchCompanyList());
    //, response.data);
  });

  //nic console.log('DEBUG editCompany response.data: ', response.data);//, response.data);

  console.log('DEBUG editCompany pred return');

  return response.data;
});
/*
export const editCompanyCar = createAsyncThunk("company/editCompanyCar", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/company-user/user/put")
  };

  const response = await axios(options).then((_) => {
    dispatch(fetchCarListByCompanyId());
  });

  return response.data;
});*/
/*
export const editCarSpecial = createAsyncThunk("users/editCarSpecial", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/user-profile/put")
  };

  const response = await axios(options).then((_) => {});

  return response.data;
});*/
/*
export const fetchCompanies = createAsyncThunk("company/fetchCompanies", async (id, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/company/get/`));

//ok   console.log('DEBUG  fetchCompanies response, response.data: ', response, response.data );
  return response.data;
});*/

export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //.addCase(fetchCarListByCompanyId.fulfilled, (state, action) => { 
      .addCase(fetchCompanyList.fulfilled, (state, action) => { 
//.addCase(fetchUserListByCompanyId.fulfilled, (state, action) => {
        state.companyList = action.payload;
      })/*
      .addCase(fetchUserProfileTypes.fulfilled, (state, action) => {
        state.userProfileTypeList = action.payload;
      })
      .addCase(requestUserSpecialProfileBy.fulfilled, (state, action) => {
        state.userSpecialProfile = action.payload;
      })
      .addCase(fetchCompaniesByUser.fulfilled, (state, action) => {
        state.companiesUserList = action.payload;
      })*/
      /*.addCase(fetchCompanies.fulfilled, (state, action) => {
	// DEBUG TEST ReferenceError: fetchCompanies2 is not defined
        state.companyList = action.payload;
      })*/
;
  }
});

/*
  console.log('DEBUG companySlice.js  export const companySlice  ', companySlice); 
  console.log('DEBUG companySlice.js  export const companySlice.state  ', companySlice.state); 
  console.log('DEBUG  export const companySlice.company  ', companySlice.company); //undef
  console.log('DEBUG  export const companySlice.companyList  ', companySlice.companyList); //undef
  console.log('DEBUG  export const companySlice.companyList3  ', companySlice.companyList3); //undef
*/
export default companySlice.reducer;
