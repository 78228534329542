import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getFetchURL } from "src/helpers/actionHelpers";
import { getSessionCompanyId } from "src/helpers/sessionHelper";

const initialState = {
  //masseurList: [],
  receptionistList: [],
  typeOfMassageList: [],
  companyList: [],
  allowedCompanyList: [],
  //roomList: [],
  aclRoleList: [],
  isRoomListFetching: false,
//  carList: [],
  carTypeList: []
};

export const fetchReceptionists = createAsyncThunk("lists/fetchReceptionists", async (_, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/user/company/receptionists/get/${getSessionCompanyId()}`));

  return response.data;
});

export const fetchMasseurs = createAsyncThunk("lists/fetchMasseurs", async (_, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/user/company/masseurs/get/${getSessionCompanyId()}`));
//  console.log('DEBUG listsSlice fetchMasseurs response, response.data: ', response, response.data );
  return response.data;
});

//export const fetchRooms = createAsyncThunk("lists/fetchRooms", async (id, { dispatch }) => {
//  const response = await axios.get(getFetchURL(`/room/company/get/${id}`));
//  return response.data;
//});
/*					// lists/fetchCarsDEBUG chyba se neprojevi
export const fetchCars = createAsyncThunk("lists/fetchCarsDEBUG", async (id, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/car/company/get-by-company/${getSessionCompanyId()}`));
  // /carERR/company/get-by-company
  // se projevi

//ok  console.log('DEBUG listsSlice fetchCars response, response.data: ', response, response.data );
  return response.data;
});*/


export const fetchAclRoles = createAsyncThunk("lists/roles", async (_, { dispatch }) => {
  const response = await axios.get(getFetchURL("/acl-role/get-limited"));
  return response.data;
});

export const fetchCarTypes = createAsyncThunk("lists/cartypes", async (_, { dispatch }) => {
  //ok console.log('DEBUG listsSlice fetchCarTypes' ); 
  const response = await axios.get(getFetchURL("/car/car-types"));
  //ok console.log('DEBUG listsSlice fetchCarTypes, response: ', response );
  return response.data;
});

export const fetchCompanyList = createAsyncThunk("lists/fetchCompanyList", async (_, { dispatch }) => {
  const response = await axios.get(getFetchURL("/company/get"));
  return response.data;
});

export const fetchAllowedCompanyList = createAsyncThunk("lists/fetchAllowedCompanyList", async (_, { dispatch }) => {
  const response = await axios.get(getFetchURL("/company-user/company/user/get"));
  return response.data;
});

export const listsSlice = createSlice({
  name: "lists",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchReceptionists.fulfilled, (state, action) => {
        state.receptionistList = action.payload;
      })
      .addCase(fetchMasseurs.fulfilled, (state, action) => {
        state.masseurList = action.payload;
      })
      //.addCase(fetchRooms.pending, (state, action) => {
        //state.isRoomListFetching = true;
//      })
//      .addCase(fetchRooms.fulfilled, (state, action) => {
//        state.roomList = action.payload;
//        state.isRoomListFetching = false;
//      })
/*      .addCase(fetchCars.fulfilled, (state, action) => {
	// DEBUG TEST ReferenceError: fetchCars2 is not defined
        state.carList = action.payload;
      })*/

      .addCase(fetchAclRoles.fulfilled, (state, action) => {
        state.aclRoleList = action.payload;
      })

      .addCase(fetchCarTypes.fulfilled, (state, action) => {
        state.carTypeList = action.payload;
      })

      .addCase(fetchCompanyList.fulfilled, (state, action) => {
        state.companyList = action.payload; // ???
      })
      .addCase(fetchAllowedCompanyList.fulfilled, (state, action) => {
        state.allowedCompanyList = action.payload;
      });
  }
});

//console.log('DEBUG   export const listsSlice  ', listsSlice); 
//undef console.log('DEBUG   export const listsSlice.reducer.state  ', listsSlice.reducer.state); 
//console.log('DEBUG   export const listsSlice.reducer  ', listsSlice.reducer); 
//console.log('DEBUG   export const listsSlice.lists  ', listsSlice.lists); //undef
//console.log('DEBUG   export const listsSlice.state  ', listsSlice.state); //undef


export default listsSlice.reducer;
