import { FormControl, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

function EnhancedTextField(props) {
  const classes = useStyles();
  const { name, value, onChange, label, ...rest } = props;

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <TextField
        name={name}
        inputProps={{
          name: name,
          id: `outlined-${name}`
        }}
        value={value || ""}
        onChange={onChange}
        label={label}
        variant="outlined"
        {...rest}
      />
    </FormControl>
  );
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%"
  }
}));
export default EnhancedTextField;
