import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getFetchURL } from "src/helpers/actionHelpers";
import { getSessionCheckedCompany, getSessionCheckedRooms, getSessionRooms } from "src/helpers/sessionHelper";

export const [IDLE, SUCCESS, LOADING, ERROR] = ["idle", "success", "loading", "error"];

const initialState = {
  calendar: [],
  searchResults: [],
  dataById: {}, // pole vsech udalosti, na ktere se kliklo, serazene podle id. Jsou tam ruzne typy vozidel; /reservation/get/${id} ma (jeden) spravny parametr
  status: IDLE,
  notPaidReservation: [],
  reservationsByCompanyId: {}
};

export const createAllDayEventAsync = createAsyncThunk(
  "reservation/createAllDayEventAsync",
  async (apiData, { dispatch }) => {
    const options = {
      method: "PUT",
      data: apiData,
      headers: { "Content-Type": "application/json" },
      url: getFetchURL("/reservation-calendar/place")
    };

    const response = await axios(options).then((_) => {
      dispatch(fetchEventsAsync());
    });

    return response.data;
  }
);

export const editHourEventAsync = createAsyncThunk("reservation/editHourEvent", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/reservation/edit")
  };

  console.log("DEBUG editHourEventAsync, apiData: ", apiData); // nic pri kliknuti na existujici rezervaci; po OK jo

  await axios(options).then((response) => {
    dispatch(fetchEventsAsync());
  });
});

export const addHourEventAsync = createAsyncThunk("reservation/addHourEvent", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/reservation/add")
  };
//DEBUG-CHYBA se neprojevi
//DEBUG-CHYBA! se projevi

 console.log('DEBUG reservationSlice addHourEventAsync apiData: ', apiData ); // driv ok ale ted nic. 2022 4 8 PM nevypisuje, CHR, FF vypisuje !!!

  const response = await axios(options).then((response) => {
    dispatch(fetchEventsAsync());
  });

  return response.data;
});

export const customerIsNotComeAsync = createAsyncThunk("reservation/customerIsNotCome", async (id, { dispatch }) => {
  const options = {
    method: "POST",
    data: { id: id },
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/reservation/not-come")
  };

  const response = await axios(options);

  return response.data;
});

export const deleteEventByAsync = createAsyncThunk("calendar/deleteEventBy", async (id, { dispatch }) => {
  const options = {
    method: "delete",
    data: { id: id },
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/reservation/delete")
  };

  const response = await axios(options).then((response) => {
    dispatch(fetchEventsAsync());
  });

  return response.data;
});

export const fetchEventByAsync = createAsyncThunk("reservation/fetchEventByAsync", async (id, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/reservation/get/${id}`));

	console.log('DEBUG reservationSlice.js fetchEventByAsync response.data: ', response.data); //nic
// prestoze v dev.log je zaznam !!!   (pri kliknuti na obsazeny termin)
  return response.data;
});

export const fetchEventsAsync = createAsyncThunk("reservation/fetchEventsAsync", async (_, { dispatch }) => {
  const options = {
    method: "post",
//err    data: { roomIds: getSessionCheckedRooms().map((c) => c[0]) },
    //data: { roomIds: "0" => "" },
    //data: { roomIds: "0" , "" },
    //data: { roomIds: "0" , "", },
    //data: { roomIds: "0"  },
    //data: { roomIds: array("0")  },

    //data: { "companyId": 1}, // !!! DEBUG request.INFO: Matched route "reservation_get_by_company_id"
     //data: { companyId: 21}, //  reservation-calendar/current-company se vola 
      data: { companyId: getSessionCheckedCompany() }, //ok
    // data: { companyId: getSessionCompanyId() }, //1},  reservation-calendar/current-company se nevola
    //neni chyba ani request.INFO: Matched route "reservation_get_by_company_id" data: { companyId: getSessionCompanyId() }, 
    //err data: { getSessionCompanyId() }, 
    //data: 1, Return value of App\Controller\BaseController::getRequestData() must be an instance of stdClass, int returned
    //data: "1", // Return value of App\Controller\BaseController::getRequestData() must be an instance of stdClass, int returned
    //data: getSessionCompanyId(), // bez chyby ale chybi request.INFO: Matched route "reservation_get_by_company_id" 
    //2022 4 29 data: JSON.stringify({ companyId: 4}), 


    //data: JSON.stringify({ companyId: 3, id: 4}), 
    //err data: { companyId: 21,}, 
    //err data: { {companyId: 21}}, // !!! DEBUG
    //err data: { companyId: 21}, // !!! DEBUG
    //err data: { companyId: "1",1}, // !!! DEBUG
    //string data: { companyId: "1"}, // !!! DEBUG
    //err data: { companyId: "id" => "1"}, 
//2022 1 20 getSessionCheckedCompany() },//2022 1 11 data: { roomIds: Array("0")  }, // !!! doplnit

    //data: { roomIds: {"0"}  },
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/reservation-calendar/current-company")//url: getFetchURL("/reservation-calendar/current-companyRENAMED")// url: getFetchURL("/reservation-calendar/room")
  };


  let response = {};

//  if (Object.keys(getSessionRooms()).length !== 0) {
    response = await axios(options).catch(  error=> this.setState({error, isLoading: false}));
  //}

	//console.log('DEBUG reservationSlice.js fetchEventsAsync response.data: ', response.data); // ok 
	//console.log('DEBUG reservationSlice.js fetchEventsAsync getSessionCheckedCompany: ', getSessionCheckedCompany()); //  ok
	
	//console.log('DEBUG reservationSlice.js fetchEventsAsync pred getSessionCompanyId'); // ok
	//console.log('DEBUG reservationSlice.js fetchEventsAsync getSessionCompanyId: ', getSessionCompanyId()); // nic protoze neni importovany !!! TODO
	//console.log('DEBUG reservationSlice.js fetchEventsAsync po getSessionCompanyId'); // nic

  return response.data;
});

//2022 4 8 
             // ERRfetchReservationsByCompanyId  chyba se projevi
export const fetchReservationsByCompanyId = createAsyncThunk("reservation/fetchReservationsByCompanyId", async (id, { dispatch }) => {
//export const fetchReservationsByCompanyId = createAsyncThunk("reservation/fetchReservationsByCompanyId", async (_, { dispatch }) => {
  //const response = await axios.get(getFetchURL(`/reservation-calendar/get-by-company/${getSessionCompanyId()}`));
 // CHYBA! se projevi
  console.log('DEBUG reservationSlice fetchReservationsByCompanyId response, response.data: ', response, response.data ); //nic-
		 // reservation2-calendar/reservationbycompanyid    chyba se neprojevi !
const response = await axios.get(getFetchURL(`/reservation-calendar/reservationbycompanyid/${getSessionCompanyId()}`)); // 
//const response = await axios.get(getFetchURL(`/reservation-calendar/current-company/${getSessionCompanyId()}`));

 // console.log('DEBUG reservationSlice fetchReservationsByCompanyId response, response.data: ', response, response.data );
  return response.data;
});


export const findReservationEvent = createAsyncThunk("reservation/findReservationEvent", async (text, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/reservation-calendar/find/${getSessionCheckedCompany()}/${text}`));
DEBUG-CHYBA
  return response.data;
});

export const fetchMasseursEvents = createAsyncThunk("reservation/fetchMasseursEvents", async (_, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/agenda/masseur/${getSessionCheckedCompany()}`));

  return response.data;
});

export const fetchCleanerEvents = createAsyncThunk("reservation/fetchCleanerEvents", async (_, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/agenda/cleaner/${getSessionCheckedCompany()}`));

  return response.data;
});

export const cancelPayment = createAsyncThunk("reservation/cancelPayment", async (apiData, { dispatch }) => {
  const options = {
    method: "POST",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/payment/cancel")
  };

  const response = await axios(options).then((response) => {
    dispatch(fetchEventsAsync());

    return response;
  });
  return response.data;
});

export const reservationSlice = createSlice({
  name: "reservation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(customerIsNotComeAsync.fulfilled, (state, action) => {
        const id = action.meta.arg;
        state.dataById[id] = action.payload;
      })
      .addCase(fetchEventByAsync.pending, (state, action) => {
        state.status = LOADING;
      })
      .addCase(fetchEventByAsync.fulfilled, (state, action) => {
        const id = action.meta.arg;
        state.dataById[id] = action.payload;
        state.status = SUCCESS;
      })
      .addCase(fetchEventByAsync.rejected, (state, action) => {
        state.status = ERROR;
      })
      .addCase(fetchEventsAsync.fulfilled, (state, action) => {
        state.calendar = action.payload;
      })
      .addCase(fetchReservationsByCompanyId.fulfilled, (state, action) => {
        state.reservationsByCompanyId = action.payload;
        state.reservationsByCompanyId = 'DEBUG action.payload';
        //state.calendar = action.payload;
      })
      .addCase(findReservationEvent.fulfilled, (state, action) => {
        state.searchResults = action.payload;
      })
      .addCase(fetchMasseursEvents.fulfilled, (state, action) => {
        state.calendar = action.payload;
	// agenda
      })
      .addCase(fetchCleanerEvents.fulfilled, (state, action) => {
        state.calendar = action.payload;
	// agenda
      })
      .addCase(cancelPayment.fulfilled, (state, action) => {
        const id = action.meta.arg.reservationId;
        state.dataById[id] = action.payload;
      });
  }
});

export default reservationSlice.reducer;
