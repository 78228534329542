import FullCalendar from "@fullcalendar/react"; // FullCalendar have to be imported first
//import FullCalendar from "@fullcalendar/scheduler"; // FullCalendar have to be imported first
//import FullCalendar from "@fullcalendar-scheduler"; // FullCalendar have to be imported first
//import FullCalendar from "@fullcalendarscheduler"; // FullCalendar have to be imported first
import "@fortawesome/fontawesome-free/css/all.css";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import csLocale from "@fullcalendar/core/locales/cs";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
// err on sdb import timeGridPlugin  from "@fullcalendar/timegrid";
//err import { timeGridWeek} from "@fullcalendar/timegrid";
import dayGridMonth from "@fullcalendar/daygrid"
import dayGridWeek  from "@fullcalendar/daygrid"
//import { TimeColsView, buildTimeColsModel, buildSlatMetas } from '@fullcalendar/timegrid'
import '@fullcalendar/resource-common';
//import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import timelinePlugin from "@fullcalendar/timeline";
import { BASE_OPTION_REFINERS } from '@fullcalendar/core'
//import { TimeColsView, buildTimeColsModel, buildSlatMetas } from '@fullcalendar/timegrid'
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Agenda from "src/components/calendar/Agenda";
import AllDayModal from "src/components/calendar/AllDayModal";
import DailyNoteDialog from "src/components/calendar/DailyNoteDialog";
import AddHourModal from "src/components/calendar/hourModal/AddHourModal";
import HourModal from "src/components/calendar/hourModal/HourModal";
import SearchForm from "src/components/calendar/SearchForm";
import { fetchDailyNoteBy } from "src/features/dailyNoteSlice";
import { fetchEventByAsync, fetchEventsAsync, fetchReservationsByCompanyId } from "src/features/reservationSlice";
import { fetchR2 } from "src/features/reservation2Slice"; // test protoze ... nefunguje

//import { fetchCarListByCompanyId } from "src/features/carSlice";
import { fetchCars } from "src/features/carSlice";//"src/features/listsSlice";
import alertHelper from "src/helpers/alertHelper";
import { getSessionCheckedRooms, getSessionRooms } from "src/helpers/sessionHelper";
import "src/styles/fullcalendar-vars.css";
import GrantedAuthHelper from "src/helpers/GrantedAuthHelper";
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';


const ConsoleLog = ({ children }) => {
  console.log(children);
  return false;
};

const Calendar = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const calendar = useSelector((state) => state.reservation.calendar);
//  const res = useSelector((state) => state.reservation);
  //const calendar3 = useSelector((state) => state.calendar);
  //const reservationsByCompanyId = useSelector((state) => state.reservation.reservationsByCompanyId);

  //console.log("DEBUG Calendar.jsx calendar, ", calendar); //ok
  //console.log("DEBUG Calendar.jsx calendar3, ", calendar3); //undef.
//  console.log("DEBUG Calendar.jsx res, ", res); //
//  console.log("DEBUG Calendar.jsx state.reservation.calendar, ", state.reservation.calendar); //err


// nedela nic  console.log("DEBUG Calendar.jsx fetchCarListByCompanyId, ", fetchCarListByCompanyId); //
  //console.log("DEBUG Calendar.jsx state, ", state.car.carList); //err
  //console.log("DEBUG Calendar.jsx state, ", state); //err



   

  const [date, setDate] = useState(false);
  const [selected, setSelected] = useState(null);
  const [calendarEvents, setCalendarEvents] = useState(calendar);
//  const [resEvents, setResEvents] = useState(reservationsByCompanyId);

  useEffect(() => {
    setCalendarEvents(calendar);
  }, [calendar]);

  useEffect(() => {
    //if (Object.keys(getSessionRooms()).length !== 0) dispatch(fetchEventsAsync());
    //if (Object.keys(getSessionRooms()).length !== 0) 
	dispatch(fetchEventsAsync());
  }, [dispatch]);

/*
  useEffect(() => {
    //setResEvents(reservationsByCompanyId);
    setResEvents('reservationsByCompanyId');
  }, [reservationsByCompanyId]);*/


//reservationsByCompanyId

  //2022 4 5
//const cal = useSelector((state) => state.calendar);
//2022 4 8 const cal = useSelector((state) => state.reservation.calendar);
//const cal = useSelector((state) => state.reservation.reservationsByCompanyId);//2022 4 8 

//  useEffect(() => {
  //  dispatch(fetchReservationsByCompanyId());
    //dispatch(fetchCarListByCompanyId());
	//ok console.log("DEBUG Calendar.jsx useEffect  dispatch(fetchReservationsByCompanyId()) ");
	//ok console.log("DEBUG Calendar.jsx useEffect  dispatch(fetchReservationsByCompanyId()) dispatch: ", dispatch);
//  }, [dispatch]);

//const reservationsByCompanyId = useSelector((state) => state.reservation.reservationsByCompanyId);
// reservationsByCompanyId

  useEffect(() => { //je potreba
 //   dispatch(fetchReservationsByCompanyId());
    //dispatch(fetchR2());
    dispatch(fetchCars());
    //dispatch(fetchCarListByCompanyId());
//ok    console.log("DEBUG Calendar.jsx useEffect  dispatch(fetchCars()) ");
    //okconsole.log("DEBUG Calendar.jsx useEffect  dispatch(fetchReservationsByCompanyId2()) ");
 //   console.log("DEBUG Calendar.jsx useEffect  dispatch(fetchReservationsByCompanyId2()) ");
   // console.log("DEBUG Calendar.jsx useEffect  dispatch(fetchReservationsByCompanyId2()) ", dispatch(fetchReservationsByCompanyId2()));
  }, [dispatch]);

//  console.log("DEBUG Calendar.jsx useEffect  dispatch(fetchReservationsByCompanyId2()) ", dispatch(fetchReservationsByCompanyId2(1))); Cannot access 'response' before initialization

   const carList = useSelector((state) => state.car.carList);
  //const carList = useSelector((state) => state.lists.carList);
  //err console.log("DEBUG Calendar.jsx state.lists.carList, ", state.lists.carList);
   //console.log("DEBUG Calendar.jsx carList, ", carList);
//  const reservationsByCompanyId = useSelector((state) => state.reservation.reservationsByCompanyId);
  //err const reservationsByCompanyId2 = useSelector((state) => state.reservation2.reservationsByCompanyId2);
//  const reservation2 = useSelector((state) => state.reservation2);
//  const reservation3 = useSelector((state) => state.reservation3);
//  console.log("DEBUG Calendar.jsx reservation2, ", reservation2);
//  console.log("DEBUG Calendar.jsx reservation3, ", reservation3);
//  const calendar4 = useSelector((state) => state.reservation.calendar);

//err console.log("DEBUG Calendar.jsx state.reservation.calendar, ", state.reservation.calendar); 
 //console.log("DEBUG Calendar.jsx calendar, ", calendar); //ok
 //ok console.log("DEBUG Calendar.jsx calendar4: , ", calendar4); 
 //console.log("DEBUG Calendar.jsx calendarEvents, ", calendarEvents); //ok
 //console.log("DEBUG Calendar.jsx cal, ", cal); 
// console.log("DEBUG Calendar.jsx reservationsByCompanyId, ", reservationsByCompanyId); 
 //console.log("DEBUG Calendar.jsx reservationsByCompanyId2, ", reservationsByCompanyId2); 
// console.log("DEBUG Calendar.jsx calendar.reservationsByCompanyId, ", calendar.reservationsByCompanyId); 
 //console.log("DEBUG Calendar.jsx state.reservation.reservationsByCompanyId, ", state.reservation.reservationsByCompanyId); 



  const [openAllDayModal, setOpenAllDayModal] = useState(false);
  const [openAddHourModal, setAddOpenHourModal] = useState(false);
  const [ClickedRowCarID, setClickedRowCarID] = useState(-1);
 
  function handleDateClick(clickInfo) {
    //console.log("DEBUG Calendar.jsx handleDateClick, getSessionCheckedRooms().length: ", getSessionCheckedRooms().length);
    //console.log("DEBUG Calendar.jsx handleDateClick, clickInfo.resource.id: ", clickInfo.resource.id);
    if (!GrantedAuthHelper.hasAuthority('calendar.edit')) {
      return false;
    }

    if (clickInfo.allDay /*&& getSessionCheckedRooms().length === 1*/) {
      setOpenAllDayModal(true);
      setDate(clickInfo.dateStr);
    } else if (!clickInfo.allDay /*&& getSessionCheckedRooms().length === 1*/) {
      setAddOpenHourModal(true);
      setDate(clickInfo.dateStr);
	//console.log("DEBUG Calendar.jsx setAddOpenHourModal, ", setAddOpenHourModal); 
	//setClickedRowCarID(true);
	setClickedRowCarID(clickInfo.resource.id);
	console.log("clickInfo: ", clickInfo);
    } else {
      alertHelper.info({ message: "Operaci nelze provést.", duration: 3000 });
    }
  }

  const [openHourModal, setOpenHourModal] = useState(false);
  const [openDailyNote, setOpenDailyNote] = useState(false);

  function handleEventClick(clickInfo) {
    //console.log("DEBUG Calendar.jsx handleEventClick");
    if (!clickInfo.allDay && !clickInfo.event.allDay) {
      dispatch(fetchEventByAsync(clickInfo.event.id));
      setSelected(clickInfo.event.id);
      setOpenHourModal(true);
      setDate(clickInfo.dateStr);
    } else if (clickInfo.event.allDay) {
      dispatch(fetchDailyNoteBy(clickInfo.event.id));
      setSelected(clickInfo.event.id);
      setOpenDailyNote(true);
    }
  }

  const headerToolbarConf = {
    left: "title",
    center: "resourceTimelineWeek resourceTimelineMonth",
    //center: "dayGridMonth dayGridWeek",
    right: "search addDailyNote today prev,next"
  };
  const [headerToolbar, setHeaderToolbar] = useState(headerToolbarConf);

  const eventViewConstants = { CALENDAR_VIEW: 0, AGENDA_VIEW: 1 };
  const [eventView, setEventView] = useState(eventViewConstants.CALENDAR_VIEW);

  //const moje;
  //const moje={};
  //var moje;
	const ConsoleLog = ({ children }) => {
	  console.log(children);
	  return false;
	};

	const arrDodavky = [
	  { id: "1", title: "TEST! Dodávka č. 1" },
	  { id: "2", title: "TEST! Dodávka č. 2" },
	  { id: "3", title: "TEST! prives. vozik č. 1" },
	];

	const TESTcalendarEvents= [
		{
		id: '1',
		resourceId: '2',
	      start: "2022-04-25T10:00:00",
	      end: "2022-04-25T22:00:00",
	      //eventDisplay: "block",
	      //eventDisplay: "auto",
	      eventDisplay: "background",
	      //display: "background",
	      //display: "block",
		// 
		//display: auto

		title: 'TEST - neni z db ! titulek',
		//eventBackgroundColor: "yellow"
		//eventBackgroundColor: "#11EEFF"
		color: 'black',//'grey', //'yellow',
		//color: 'black',//'grey', //'yellow',
		//color: 'grey', //'yellow',
		//color: 'yellow',
		//eventBackgroundColor: '#41a2A0',
		//eventTextColor: 'black'
		eventTextColor: 'black'

		}
	];

//	console.log("DEBUG Calendar.jsx arrDodavky, ", arrDodavky);
	//const carNamesList = carList[0]["nazev"];
	//ok console.log("DEBUG Calendar.jsx carList[0][\"nazev\"], ", carList[0]["nazev"]);
	/*{roomList.map((r) => (
                    <option key={r.id} value={r.id}>
                      {r.name}
                    </option>
                  ))}

receptionistList.map((r) => (
                    <option key={r.id} value={r.id}>
                      {r.fullName}
                    </option>
                  ))

carList.map((m) => (
                    <option key={m.id} value={m.id}>
                      {m.nazev}
                    </option>
                  ))		  
		  */	
//	console.log("DEBUG Calendar.jsx carList.map((m) => m.nazev), ", carList.map((m) => m.nazev));
	//console.log("DEBUG Calendar.jsx carList.map((m) => m.id, m.nazev), ", carList.map((m) => m.id, m.nazev)); ReferenceError: m is not defined

//	console.log("DEBUG Calendar.jsx carList.map((m) => (m.id, m.nazev)), ", carList.map((m) => (m.id, m.nazev)));

//	console.log("DEBUG Calendar.jsx carList.map((m) => (m.nazev)), ", carList.map((m) => (m.nazev)));
//	console.log("DEBUG Calendar.jsx carList.map((m) => (m.id)), ", carList.map((m) => (m.id)));

	//const carNamesList = carList.map((m) => (<ListItem key={m.id} item={m.nazev} />));
	//err const carNamesList = carList.map((m) => ( m.id ));

//	let TMPdata = {foo: 3, bar: 5, baz: 6}; console.log("DEBUG Calendar.jsx TMPdata: ", TMPdata);
	
	const carNamesList = [];//ok [{id: "1", title: "ti1"}]; console.log("DEBUG Calendar.jsx carNamesList: ", carNamesList);

	var Car = function(id, title){
	    this.id = id; 
	    this.title = title;
	  }

	for (const property in carList) {
	 //	 console.log(`${property}: ${carList[property].id}, ${carList[property].nazev}`);
		let obj = new Car( carList[property].id, carList[property].nazev);
		carNamesList.push(obj);
		//carNamesList.push([{"id": carList[property].id, "title": carList[property].nazev}]); // funguje ale moc poli
	}

	for (const property in carNamesList) {
	 // console.log(`${property}: ${carNamesList[property]}, ${carNamesList[property]}.id , ${carNamesList[property].id}, ${carNamesList[property].title}`);
	}		

//	console.log("DEBUG Calendar.jsx carNamesList: ", carNamesList);

	//const carNamesList = {id: "1", title: "ti1"};
	//const carNamesList = [id: "1", title: "ti1"];
	//const carNamesList = carList.map((m, id) => ( id = m.id, m.nazev ));  // TypeError: Cannot use 'in' operator to search for 'id' in TEST dodávka 1

	//const carNamesList = carList.map((m, id) => ( id = m.id, "title" = m.nazev ));
	//const carNamesList = carList.map((m, id) => ( id = m.id, {title} = m.nazev ));
	//const carNamesList = carList.map((m, id) => ( id = m.id, title = m.nazev ));
	//const carNamesList = carList.map((id, m) => ( id = m.id ));
	//const carNamesList = carList.map((m) => ( {m.id} ));// item={m.nazev} ));
	//const carNamesList = carList.map((m) => ( {m.id} item={m.nazev} ));


	//const carNamesList = carList.map((m) => id={m.id}, m.nazev);
	//const carNamesList = carList.map((m) => id=m.id, m.nazev);
	//const carNamesList = carList.map((m) => (id=m.id, m.nazev));
	//const carNamesList = carList.map((m) => (key=m.id, m.nazev));
	//const carNamesList = carList.map((m) => (m.id));
	//const carNamesList = carList.map((m) => (m.id, m.nazev));
	//const carNamesList = carList.map((m) => (m.nazev));

	//console.log("DEBUG Calendar.jsx carNamesList, ", carNamesList);	
	

  return (
	  //{ console.log(duration) }
	  //<ConsoleLog>{duration}</ConsoleLog>

    <div className={classes.root}>
      <SearchForm
        headerToolbarConf={headerToolbarConf}
        headerToolbar={headerToolbar}
        setHeaderToolbar={setHeaderToolbar}
        setEventView={setEventView}
        eventViewConstants={eventViewConstants}
      />
      <Agenda
        className={eventView === eventViewConstants.AGENDA_VIEW ? "" : classes.dNone}
        handleEventClick={handleEventClick}
      />
      <span className={eventView === eventViewConstants.CALENDAR_VIEW ? "" : classes.dNone}>
        <FullCalendar
          //err plugins={[ timeGridWeek ]}
          //err plugins={[ timeGridDay ]}
          //plugins={[ timeGridPlugin ]}
          
          plugins={[ resourceTimelinePlugin, /*resourceTimeGridPlugin, dayGridWeek, dayGridMonth, */interactionPlugin, bootstrapPlugin ]}
          //initialView="dayGridWeek"
          //initialView="moje"
//          initialView="timeGridWeek"
          initialView="resourceTimelineWeek"
         // initialView="resourceTimeGridDay"
          //err BASE_OPTION_REFINERS.schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
          //schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
          schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
  //        views= {
	    //moje=
	    //dayGrid= 
	    //dayGridWeek=
//            { type: 'resourceTimeGridDay', 
            //{ type: 'dayGrid', 
		//dayCount: 31, 
		//dayCount: 3, 
		//dayCount: "3", 
//		duration: { days: 3 }
//	    }
         // }
    //resources=[

/*
  resources= {[
    url: '/car/company/get-by-company',
    //"url"= '/car/company/get-by-company',
    //url= '/car/company/get-by-company',
    method= 'POST',
    extraParams= '1']
  }*/

          resources={carNamesList
			/*, 
			error: function() {
                		$('#script-warning').show();
            		}*/
	}
	//err resources.getResourceById(1).eventBackgroundColor = "eventBackgroundColor"

		//resources={carList.map((m) => (
				      //{m.idd} Unexpected token, expected "," (219:12)
				      //m.idd,
				      //{m.idd},
				      //{m,idd},
				      //{m},
				    //  m.nazev
				      //{m.nazev}
				    
				 // ))}
		// bez {} JSX value should be either an expression or a quoted JSX text.


          //resources={carList['nazev']}
          //resources={carList.nazev}
          //resources={carList[0].nazev}
          //err resources={carList[0]}
          //nic resources={carList[0][0]}
          //nic resources={carList[0]['nazev']}
          //err resources={carList[0]["nazev"]}
          //resources={arrDodavky}
	  
          events={calendarEvents}
          //ok  events={TESTcalendarEvents}
          /*events={
//ReferenceError: assignment to undeclared variable id	[
    {
	//err pri [ id: '1',
	//err pri  {  id= '1',
	id: '1',
	resourceId: '1',
      start: "2022-03-29T10:00:00",
      end: "2022-03-29T16:00:00",
      display: "inverse-background",
	title: 'Meeting'
    }
//]
		}*/
// DEBUG	eventColor= '#378006'





          dateClick={handleDateClick}
          eventClick={handleEventClick}
          // unknown option slotWidth="8"
		aspectRatio = "2"
		slotMinWidth = "14"
		resourceAreaWidth = "15%"

      //{ id='a', title='Room A' },
      //{ id='a', title='Room A' }
    //  { 'id'='a', 'title'='Room A' }
      //{ title='a', title='Room A' }
      //{ '0'='a', title='Room A' }

          //ok { '0','a', 'Room A' }
          //err{{ '0','a', 'Room A' }}
          //{{ '0'},'a', 'Room A' }}
      //err{ 'id':'0','id':'a', 'id':'Room A' }
      //err{ 'id'='0','id':'a', 'id':'Room A' }
//[{"id":"a","title":"Auditorium A"},{"id":"b","title":"Auditorium B","eventColor":"green"}]

//      { id: 'b', title: 'Room B'},
 //     { id: 'c', title: 'Room C' },
//      { id: 'd', title: 'Room D' }
    //]
	  headerToolbar={headerToolbar}
          locale={csLocale}
          //title={'title-nefunguje'}
	  //{ console.log(duration) }
	  //<ConsoleLog>{duration}</ConsoleLog>

          dayCount={"3"}
          //dayCount={3}
          //duration={3}
          //duration={3 days}
          //duration={days: 3}
          //duration={days 3}
          //duration={days }

      /*    plugins={[dayGridWeek, dayGridMonth, interactionPlugin, bootstrapPlugin]}
          customButtons={{
            addDailyNote: {
              text: "Přidat denní pozn.",
              click: () => setOpenDailyNote(true)
            },
            search: {
              text: "Hledání",
              bootstrapFontAwesome: "search",
              click: () => setHeaderToolbar(false)
            }
          }}
          headerToolbar={headerToolbar}
          themeSystem="bootstrap"
          initialView="dayGridWeek"
          dayCount="3"

         // views= {{ type: 'dayGrid', 
	//	dayCount: 31, 
		//duration: { days: 31}
	  //}}
          
          //slotMinTime="08:00"
          //slotMaxTime="23:00"
          expandRows={true}
          locale={csLocale}
          events={calendarEvents}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          nowIndicator={true}*/
        />
      </span>
      <AllDayModal date={date} open={openAllDayModal} setOpen={setOpenAllDayModal} />
      <HourModal open={openHourModal} setOpen={setOpenHourModal} selected={selected} setSelected={setSelected} />
      <AddHourModal open={openAddHourModal} setOpen={setAddOpenHourModal} date={date} ClickedRowCarID={ClickedRowCarID}  />
      <DailyNoteDialog
        openDailyNote={openDailyNote}
        setOpenDailyNote={setOpenDailyNote}
        selected={selected}
        setSelected={setSelected}
      />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    fontFamily: "Roboto, Helvetica, Arial, sans-serif !important"
  },
  dNone: {
    display: "none"
  },
  serachInput: { marginBottom: theme.spacing(2) }
}));

let calendarEl = document.getElementById('calendar');
//var calendar = new Calendar(calendarEl, {
var CalendarErr = new FullCalendar(calendarEl, {
  weekends: false,
  initialView: 'timeGrid',
  dayCount: 4
});


export default Calendar;
/*
let calendar2 = new Calendar(calendarEl, {
  plugins: [ dayGridWeek ],
  initialView: 'dayGridWeek',
  resources: [
    // your resource list
  ]
});*/
