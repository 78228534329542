import { Dialog, DialogActions, DialogContent, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editCar, insertCar } from "src/features/carSlice";// import { editUser, insertUser } from "src/features/usersSlice";
import EnhancedConfirmButton from "src/components/enhanced/EnhancedConfirmButton";
import EnhancedSelect from "src/components/enhanced/EnhancedSelect";
import EnhancedTextField from "src/components/enhanced/EnhancedTextField";
import alertHelper from "src/helpers/alertHelper";
import { getSessionCheckedCompany } from "src/helpers/sessionHelper";
import EnhancedDialogTitle from "src/components/enhanced/EnhancedDialogTitle";
import { ColorPicker } from "material-ui-color";
import { ColorBox, ColorInput, ColorPalette, createColor } from 'material-ui-color';
import { fetchCarTypes, fetchCompanyList } from "src/features/listsSlice";
//import { fetchCompanyList } from "src/features/companySlice";

const paletaBarev = {
  red: '#F00', //DEBUG ok, zapisuje se do db red: '#ff0000',  ok, zapisuje se do db
  //blue: '#00f',
  blue: 'blue',
  green: '#0f0',
  yellow: 'yellow',
  cyan: 'cyan',
  lime: 'lime',
  gray: 'gray',
  orange: 'orange',
  purple: 'purple',
  black: 'black',
  white: 'white',
  pink: 'pink',
  darkblue: 'darkblue',
};

function CarDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchCarTypes());
    dispatch(fetchCompanyList());
//   console.log("DEBUG  useEfect ");
//	setColor('red');
//    console.log(" color: ", color);
    //setColor(value);
    //console.log(" color: ", color);
	
  }, [dispatch]);

  const carTypeList = useSelector((state) => state.lists.carTypeList);// const aclRoleList = useSelector((state) => state.lists.aclRoleList);
   console.log('DEBUG carTypeList: ', carTypeList );


  const companyList = useSelector((state) => state.lists.companyList);
//ok   console.log('DEBUG companyList: ', companyList );

  const { car, openNew, setOpenNew, setOpenEdit, openEdit } = props;// const { user, openNew, setOpenNew, setOpenEdit, openEdit } = props;
  const [formState, setFormState] = useState({});

  const sBarva = car.barva;
 // console.log("DEBUG  sBarva: ", sBarva);

 // const [color, setColor] = useState(createColor("black", "#000"));
  const [color, setColor] = useState(createColor(//chyba pri nezmeneny barve  "#F00"//formState.barva is def.
	sBarva // color undef
  //car.barva, "plain" //chyba pri nezmeneny barve car.barva //undef
  //"transparent"
//chyba pri nezmeneny barve 	sBarva
	//"red"
	//"black"
  //paletaBarev[car.barva] // formState.barva undef
  // /* ok name red*/ /*"#000" black*/ /* name none "FF0000" */      /*car.barva  /*#F00 ale color je undef*/      *sBarva*/ 
  )); 
//Argument 6 passed to App\DTO\Request\CarRequest::__construct() must be of the type string, object given, called in /var/www/api/src/Controller/CarController.php on line 275
//  console.log("DEBUG  color: ", color);

  useEffect(() => {
 //   dispatch(fetchCarTypes());
 //   dispatch(fetchCompanyList());
 //  console.log("DEBUG  useEfect, color:  ", color);
	//setColor('red'); //ok
	setColor(car.barva); //
  //  console.log(" color: ", color, "car.barva: ", car.barva); // porad undefined !!!
    //setColor(value);
    //console.log(" color: ", color);
	
  }, [dispatch]);  
 // console.log("DEBUG color: ", color);

  const handleChangeBarva = (value) => {
//    console.log("onChange=", value);
//	console.log("DEBUG  typeof(value): ", typeof(value));
//	console.log("DEBUG  paletaBarev[value]: ",  paletaBarev[value]);
	
    // setColor(paletaBarev[value]);
//    console.log("onChange color: ", color);
    setColor(value);
 //   console.log("onChange color: ", color);
  };

	// setColor('red'); Error: Too many re-renders. React limits the number of renders to prevent an infinite loop.

//	console.log("DEBUG  car.barva: ", car.barva);
//	console.log("DEBUG  color: ", color);
//	var eq = car.barva == "#F00";
//	var eq = car.barva == "red";
	
//	console.log("DEBUG  eq: ", eq, ", typeof(car.barva): ", typeof(car.barva));
	//err console.log("DEBUG  Object.toJSON(car.barva): ", Object.toJSON(car.barva));
	

  useEffect(() => {
    if (openEdit)
      setFormState((formState) => ({
        ...formState,
	nazev: car?.nazev,
	spz: car?.spz,
	barva: /*car?.color, /* color.raw ,*/ color, // "TESTBARVA",
	//barva: car?.color,
	//barva: car?.barva,
	//bez chyby barva: "red",
	// 'red' je definovana ale nevybrana na zacatku; do db se uklada spravna barva
	typVozidlaId: car?.typ_vozidla?.id,//cartype?.id,// typVozidlaId: car?.typ_vozidla_id,
	companyId: car?.company?.id
	//company: car?.company
	//barva: car?.barva
	//err barva: {palette}
	//err barva: palette
        //firstNameEE: user?.firstNameEE,
        //lastName: user?.lastName,
        //email: user?.email,
        //phone: user?.phone,
        //aclRoleId: user?.role?.id,
        //active: user?.active
      }));

	// nastavuje se stara barva setColor(car?.barva);
	//err car?.barva = color;

  	console.log('DEBUG : car: ', car );
//  	console.log('DEBUG : color: ', color );
  }, [car, openEdit]);
  //}, [user, openEdit]);

  //console.log('DEBUG : car?.nazev', car?.nazev );
  //undef console.log('DEBUG : car?.typ_vozidla', car?.typ_vozidla );
  console.log('DEBUG : car?.cartype?.id', car?.cartype?.id, ", formState.typVozidlaId: ", formState.typVozidlaId );
  //undef console.log('DEBUG : car?.typVozidlaId', car?.typVozidlaId );
  //err console.log('DEBUG : car?', car? );
  console.log('DEBUG : car: ', car );
//  console.log('DEBUG : formState: ', formState );
//  console.log('DEBUG : formState.barva: ', formState.barva );
//  console.log('DEBUG : color: ', color );


  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  };

  const handleClose = () => {
    if (openEdit) {
      setOpenEdit(false);
      setFormState({});
	//setColor(car.barva); // undef
	//console.log('DEBUG : color: ', color );
	//console.log('DEBUG : car.barva: ', car.barva );
	//setColor('red'); // undef 
	//console.log('DEBUG : color: ', color );
	
    } else {
      setOpenNew(false);
      setFormState({});
    }
  };

  const handleOk = () => {
    // phone validation
 /*   const phoneFormat = /^[0-9\b]+$/;
    if (!phoneFormat.test(formState.phone)) {
      alertHelper.error({ message: "Telefoní číslo musí být číslo." });
      return false;
    }*/
//	console.log("DEBUG handleOk pred if (formState.nazev == \"\") {, formState.nazev: ", formState.nazev);
	if (formState.nazev == "" || typeof(formState.nazev) == 'undefined') {
	      alertHelper.error({ message: "Název musí být definován." });
	      return false;

	}
	console.log("DEBUG handleOk , formState: ", formState, ", color: ", color);

	if (formState.typVozidlaId == "" || typeof(formState.typVozidlaId) == 'undefined') {
	      alertHelper.error({ message: "Typ vozidla musí být definován." });
	      return false;

	}

	if (formState.companyId == "" || typeof(formState.companyId) == 'undefined') {
	      alertHelper.error({ message: "Společnost musí být definována." });
	      return false;

	}

	if (formState.spz == "" || typeof(formState.spz) == 'undefined') {
	      alertHelper.error({ message: "SPZ musí být definována." });
	      return false;

	}

	if (color == "" || typeof(color) == 'undefined') {
	      alertHelper.error({ message: "Barva musí být definována." });
	      return false;

	}


 /*   // email validator
    const emailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailFormat.test(String(formState.email).toLowerCase())) {
      alertHelper.error({ message: "Email není ve správném tvaru" });
      return false;
    }*/

    const apiData = {
      nazev: formState.nazev,
      spz: formState.spz,
      barva: formState.barva,
      typVozidlaId: formState.typVozidlaId,
      companyId: formState.companyId
/*      email: formState.email,
      phone: formState.phone,
      aclRoleId: formState.aclRoleId,
      password: formState.password,
      companyId: getSessionCheckedCompany()*/
    };

//paletaBarev	console.log("DEBUG handleok, palette: ", palette);
	//ok console.log("DEBUG handleok, formState: ", formState);

    if (openEdit) {
      apiData.id = car.id;// apiData.id = user.id;
  //    apiData.active = formState.active;
	apiData.barva = color;
	//apiData.barva = car.barva;

	if (typeof(color) == 'undefined')
	{
		//setColor(car.barva);
		console.log("DEBUG typeof(color) == 'undefined', color: ", color);
		apiData.barva = car.barva;
	}
	else
		apiData.barva = color;

	console.log("DEBUG if (openEdit) , apiData: ", apiData);
	console.log("DEBUG if (openEdit) , car: ", car);
	console.log("DEBUG if (openEdit) , color: ", color);
	console.log("DEBUG if (openEdit) , car.barva: ", car.barva); // bez editace: ok, s editaci barvy - obsahuje spatnou (starou) barvu

      dispatch(editCar(apiData));// dispatch(editUser(apiData));
	console.log("DEBUG if (openEdit) po dispatch(editCar(apiData)); , apiData: ", apiData);
	console.log("DEBUG if (openEdit) po dispatch(editCar(apiData)); , formState.barva: ", formState.barva); // #000
	console.log("DEBUG if (openEdit) po dispatch(editCar(apiData)); , color: ", color); // 
    } else {
     // apiData.companyId = getSessionCheckedCompany();
	apiData.barva = color;
      dispatch(insertCar(apiData));// dispatch(insertUser(apiData));
    }
    handleClose();
  };



  return (
    <div className={classes.root}>
      <Dialog
        open={openNew || openEdit}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="md"
        aria-labelledby="form-dialog-title">
        <EnhancedDialogTitle handleClose={handleClose} nameLabel="Editace vozidla" />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <EnhancedTextField name="nazev" value={formState.nazev} label="Název" onChange={handleChange} />
            </Grid>
            <Grid item xs={6}>
              <EnhancedTextField name="spz" value={formState.spz} label="SPZ" onChange={handleChange} />
            </Grid>
            {/*<Grid item xs={6}>
              <EnhancedTextField name="typ" value={formState.email} label="Typ" onChange={handleChange} />
            </Grid>*/}
            {/*<Grid item xs={6}>
              <EnhancedTextField name="phone" value={formState.phone} label="Telefon" onChange={handleChange} />
            </Grid>*/}
            <Grid item xs={6}> {/*name="aclRoleId"*/} {/*value={formState.aclRoleId}*/} {/*label="Role"}*/} {/*list={aclRoleList}*/}
              <EnhancedSelect
                name="typVozidlaId"
		
                value={formState.typVozidlaId}
		label="Typ vozidla"
                list={carTypeList}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
	      Barva<br/><br/>
	      {/*ColorPicker: <ColorPicker defaultValue=" <--- vyberte barvu"/>-----------<br/><br/>
	      ColorBox: <ColorBox defaultValue="vyberte barvu" palette={palette}/>----------------<br/><br/><br/>
	      {/*ColorInput: <ColorInput defaultValue="red"/>------------<br/><br/><br/>*/}
	      {/*<ColorPalette palette={paletaBarev} value={formState.barva} onChange={handleChange}/>*/}
	   {/*<ColorPalette palette={paletaBarev} value={color} onChange={handleChangeBarva}/>*/}
	      <ColorPalette palette={paletaBarev} value={formState.barva} onSelect={handleChangeBarva}/>
            </Grid>	    
            <Grid item xs={6}>
              <EnhancedSelect
                name="companyId"
		
                value={formState.companyId}
		label="Společnost"
                list={companyList}
                onChange={handleChange}
              />
            </Grid>

            {/*<Grid item xs={6}>
              <EnhancedTextField
                name="password"
                value={formState.password}
                label="Heslo"
                onChange={handleChange}
                type="password"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <EnhancedSelect
                name="active"
                value={formState.active}
                label="Aktivní"
                list={[
                  { id: "true", name: "Aktivní" },
                  { id: "false", name: "Neaktivní" }
                ]}
                onChange={handleChange}
              />
            </Grid>*/}
          </Grid>
        </DialogContent>
        <DialogActions>
          <EnhancedConfirmButton handleClose={handleClose} handleOk={handleOk} />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  }
}));

export default CarDialog;
