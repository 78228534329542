import "@fortawesome/fontawesome-free/css/all.css";
import { FormControl, Grid, InputAdornment, InputLabel, OutlinedInput } from "@material-ui/core";
import { ArrowBack, Search } from "@mui/icons-material";//@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { findReservationEvent } from "src/features/reservationSlice";
import EnhancedIconButton from "src/components/enhanced/EnhancedIconButton";
import { useSelector } from "react-redux";

const SearchForm = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { headerToolbarConf, headerToolbar, setHeaderToolbar, setEventView, eventViewConstants } = props;
  const calendar = useSelector((state) => state.reservation.calendar);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    searchValue && dispatch(findReservationEvent(searchValue));
  }, [calendar, dispatch, searchValue]);

  const handleSearch = () => {
    dispatch(findReservationEvent(searchValue));
    setEventView(eventViewConstants.AGENDA_VIEW);
  };

  const handleBackBtn = () => {
    setHeaderToolbar(headerToolbarConf);
    setEventView(eventViewConstants.CALENDAR_VIEW);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") handleSearch();
  };

  return (
    <Grid
      container
      spacing={1}
      alignItems="flex-end"
      className={headerToolbar === false ? classes.serachInput : classes.dNone}>
      <Grid item>
        <EnhancedIconButton ariaLabel="back icon" tooltip="Zpět" Icon={ArrowBack} onClick={handleBackBtn} />
      </Grid>
      <Grid item xs={6}>
        <FormControl onKeyDown={(e) => handleKeyDown(e)} fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-amount">Search</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <EnhancedIconButton ariaLabel="back icon" tooltip="Zpět" Icon={Search} onClick={handleSearch} />
              </InputAdornment>
            }
            labelWidth={60}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    fontFamily: "Roboto, Helvetica, Arial, sans-serif !important"
  },
  dNone: {
    display: "none"
  },
  serachInput: { marginBottom: theme.spacing(2) }
}));

export default SearchForm;
