import {
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPassLogHistory } from "src/features/passLogSlice";
import EnhancedDialogTitle from "../enhanced/EnhancedDialogTitle";

function PassLogHistoryDialog(props) {
  const classes = useStyles();
  const { open, setOpenHistory, passlog } = props;
  const dispatch = useDispatch();
  const passLogHistoryList = useSelector((state) => state.passLog.passLogHistoryList);

  const handleClose = () => {
    setOpenHistory(false);
  };

  useEffect(() => {
    if (typeof passlog.id !== "undefined") {
      dispatch(fetchPassLogHistory(passlog.id));
    }
  }, [passlog, dispatch]);

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md" aria-labelledby="form-dialog-title">
        <EnhancedDialogTitle handleClose={handleClose} nameLabel="Historie permanentky" />
        <DialogContent>
          <TableContainer>
            {passLogHistoryList.length === 0 ? null : (
              <Table aria-label="passlog table">
                <TableHead>
                  <TableRow>
                    <TableCell align="right">Title</TableCell>
                    <TableCell align="right">Datum</TableCell>
                    <TableCell align="right">Stav</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {passLogHistoryList.map((c, i) => (
                    <TableRow key={i}>
                      <TableCell align="right">{c.title}</TableCell>
                      <TableCell align="right">{c.dateStr}</TableCell>
                      <TableCell align="right">{c.status}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  }
}));

export default PassLogHistoryDialog;
