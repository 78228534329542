import { Dialog, DialogActions, DialogContent, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import EnhancedConfirmButton from "src/components/enhanced/EnhancedConfirmButton";
import EnhancedTextField from "src/components/enhanced/EnhancedTextField";
import { addMassagePrice } from "src/features/pricingSlice";
import alertHelper from "src/helpers/alertHelper";
import { getSessionCheckedCompany } from "src/helpers/sessionHelper";
import EnhancedDialogTitle from "src/components/enhanced/EnhancedDialogTitle";

function PriceAddDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { open, setOpenNew, massageType } = props;
  const [formState, setFormState] = useState({});

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  };

  const handleClose = () => {
    setOpenNew(false);
    setFormState({});
  };

  const handleSave = () => {
    if (!formState.price) {
      alertHelper.error({ message: "Musíte zadat cenu !" });
      return false;
    }

    const apiData = {
      companyId: getSessionCheckedCompany(),
      massageTypeId: massageType.id,
      price: formState.price,
    };

    dispatch(addMassagePrice(apiData));

    handleClose();
  };

// md sm
// fullWidth={true}

  return (
      <div className={classes.root}>
        <Dialog open={open} onClose={handleClose} fullWidth={false} maxWidth="sm" aria-labelledby="form-dialog-title">
          <EnhancedDialogTitle handleClose={handleClose} nameLabel="Vložit novou cenu" />
          <DialogContent>
              <Grid item xs={6}>
                <EnhancedTextField name="price" value={formState.price} label="Cena" onChange={handleChange} />
              </Grid>
          </DialogContent>
          <DialogActions>
            <EnhancedConfirmButton handleOk={handleSave} okLabel="Přidat cenu" />
          </DialogActions>
        </Dialog>
      </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  }
}));

export default PriceAddDialog;
