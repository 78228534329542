import { Dialog, DialogActions, DialogContent, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import EnhancedConfirmButton from "src/components/enhanced/EnhancedConfirmButton";
import EnhancedTextField from "src/components/enhanced/EnhancedTextField";
import EnhancedDialogTitle from "src/components/enhanced/EnhancedDialogTitle";
import {editCompanySum} from "../../features/companySumSlice";

function NewCompanySumDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {open, setOpenEdit, companySum} = props;
  const [formState, setFormState] = useState({});

  useEffect(() => {
    setFormState((formState) => ({
      ...formState,
      companyName: companySum?.company?.name,
      validityTextDate: companySum?.validityTextDate,
      sumUpSum: companySum?.sumUpSum,
      slevomatSum: companySum?.slevomatSum,
    }));
  }, [companySum]);

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  };

  const handleClose = () => {
    setOpenEdit(false);
    setFormState({});
  };

  const handleOk = () => {
    const apiData = {
      id: companySum.id,
      sumUpSum: formState.sumUpSum,
      slevomatSum: formState.slevomatSum
    };

    dispatch(editCompanySum(apiData));
    handleClose();
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md" aria-labelledby="form-dialog-title">
        <EnhancedDialogTitle handleClose={handleClose} nameLabel="Úprava sumarizační hodnoty za organizaci" />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <EnhancedTextField
                  name="companyName"
                  value={formState.companyName}
                  disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <EnhancedTextField
                  name="validityTextDate"
                  value={formState.validityTextDate}
                  label="Platnost od - do"
                  disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <EnhancedTextField
                  name="sumUpSum"
                  value={formState.sumUpSum}
                  label="SumUp hodnota"
                  onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <EnhancedTextField
                  name="slevomatSum"
                  value={formState.slevomatSum}
                  label="Počet uplatněných poukazů"
                  onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <EnhancedConfirmButton handleOk={handleOk} okLabel="Uložit" />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  }
}));

export default NewCompanySumDialog;
