import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar } from "@material-ui/core";
//import  Alert  from "@mui/material/Alert"; import MuiAlert from "@material-ui/lab/Alert"; !!! TODO
//import  { MuiAlert }  from "@mui/material/Alert"; //import MuiAlert from "@material-ui/lab/Alert"; !!! TODO
//err import Alert from '@mui/material/Alert';
import  MuiAlert   from "@mui/material/Alert";

import React from "react";
import EnhancedConfirmButton from "src/components/enhanced/EnhancedConfirmButton";
import alertHelper from "src/helpers/alertHelper";
import EnhancedCloseButton from "./enhanced/EnhancedCloseButton";

class Alert extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      alertMessage: "",
      confirmMessage: "",
      duration: 0,
      showAlert: false,
      showConfirm: false,
      severity: "",
      confirmCallback: () => {}
    };
  }

  componentDidMount() {
    alertHelper.registerAlert(this);
  }

  alert(message, duration, severity) {
    this.setState({
      alertMessage: message,
      duration,
      showAlert: true,
      severity
    });
  }

  confirm(message, confirmCallback, className) {
    this.setState({
      confirmMessage: message,
      showConfirm: true,
      confirmCallback,
      className: className
    });
  }

  handleAlertClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showAlert: false });
  }

  handleConfirmCancel() {
    this.setState({ showConfirm: false });
  }

  handleConfirmOk() {
    this.state.confirmCallback();
    this.setState({ showConfirm: false, confirmCallback: () => {} });
  }

  render() {
    //const CustomAlert = (props) => <Alert elevation={6} variant="filled" {...props} />;
    const CustomAlert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

    const { showAlert, duration, severity, alertMessage, showConfirm, confirmMessage, className } = this.state;

    return (
      <>
        <Snackbar open={showAlert} autoHideDuration={duration} onClose={this.handleAlertClose.bind(this)}>
          <CustomAlert onClose={this.handleAlertClose.bind(this)} severity={severity}>
            {alertMessage}
          </CustomAlert>
        </Snackbar>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          aria-labelledby="confirmation-dialog-title"
          open={showConfirm}>
          <DialogTitle id="confirmation-dialog-title">Dotaz</DialogTitle>
          <DialogContent dividers>
            <DialogContentText className={className}>{confirmMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <EnhancedCloseButton handleClose={this.handleConfirmCancel.bind(this)} />
            <EnhancedConfirmButton handleOk={this.handleConfirmOk.bind(this)} okLabel="Ano" />
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default Alert;
