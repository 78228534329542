import alertHelper from "src/helpers/alertHelper";

export function getFetchURL(path) {
  if (process.env.NODE_ENV === "production") {
    return "/api/public/index.php" + path;
  } else {
    return "" + path;
  }
}

export function getExportFetchURL(path) {
  if (process.env.NODE_ENV === "production") {
    return "/api/public/index.php" + path;
  } else {
    return "http://localhost:8080" + path;
  }
}

export function handleNetworkError({ errorReason }, { history }) {
  if (errorReason?.response?.status === 401) {
    history.push("/sign-in");
    return true;
  }

  let errorMessage = errorReason?.response?.data?.error ? errorReason.response.data.error : errorReason.message;
  if (errorReason?.response?.data?.messages) {
    errorMessage += " ( " + errorReason.response.data.messages + " )";
  }
  alertHelper.error({ message: errorMessage, duration: 5000 });
}
