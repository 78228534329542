//export function DEBUGgetSessionCalendarSettings() {
//  console.log("DEBUG sessionHelper.js ", sessionStorage);
//  return JSON.parse(sessionStorage.getItem("calendarSettings"));
//}


export function getSessionCalendarSettings() {
  return JSON.parse(sessionStorage.getItem("calendarSettings")) || {};
}

export function setSessionCalendarSettings(settings) {
  sessionStorage.setItem("calendarSettings", JSON.stringify(settings));
}

export function setSessionCompanyName(name) {
  sessionStorage.setItem("companyName", name);
}

export function getSessionCompanyId() {
  let { companyId } = getSessionCalendarSettings();
  return companyId;
}

export function getSessionCompanyName() {
  return sessionStorage.getItem("companyName") ?? "Půjčovna dodávek";
}

export function getSessionRooms() {
  let { companyId, ...rooms } = getSessionCalendarSettings();
  return rooms;
}

export function getSessionCheckedRooms() {
  let rooms = getSessionRooms();
  let checkedRoom = Object.entries(rooms).filter((r) => r[1]);
  return checkedRoom;
}

export function getSessionCheckedCompany() {
  return getSessionCompanyId();
}

export function setSessionInitAttr() {
  sessionStorage.setItem("initailLoadAttribute", 1);
}

export function getSessionInitAttr() {
  return sessionStorage.getItem("initailLoadAttribute");
}

export function cleanSession() {
  sessionStorage.removeItem("calendarSettings");
  sessionStorage.removeItem("companyName");
  sessionStorage.removeItem("initailLoadAttribute");
}
