import { Paper, TableCell } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EnhancedTable from "src/components/enhanced/EnhancedTable";
import { fetchMassageTypeList } from "src/features/pricingSlice";
import EnhancedIconButton from "../enhanced/EnhancedIconButton";
import { List, Add} from "@mui/icons-material";//@material-ui/icons";
import PriceHistoryDialog from "../pricing/PriceHistoryDialog";
import PriceAddDialog from "./PriceAddDialog";

function PricingTable() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const massageTypeList = useSelector((state) => state.pricing.massageTypeList);

  const [selectedMassageType, setSelectedMassageType] = useState({});
  const [openHistory, setOpenHistory] = useState(false);
  const [openNew, setOpenNew] = useState(false);

  useEffect(() => {
    dispatch(fetchMassageTypeList());
  }, [dispatch]);

  const showPriceHistory = (massageType) => {
      setSelectedMassageType(massageType);
      setOpenHistory(true);
  };

    const showNewPrice = (massageType) => {
        setSelectedMassageType(massageType);
        setOpenNew(true);
    };

  const headerList = [
    { align: "left", label: "" },
    { align: "right", label: "ID" },
    { align: "right", label: "Název" },
    { align: "right", label: "Zkratka" },
    { align: "right", label: "Platební metoda" },
    { align: "right", label: "Vozidlo" }
  ];

  const renderRowCells = (massageType) => (
    <>
      <TableCell className={classes.iconCell}>
          <EnhancedIconButton
              authority="pricing.view"
              ariaLabel="history row"
              tooltip="Ceny"
              size="small"
              Icon={List}
              onClick={() => showPriceHistory(massageType)}
          />
          <EnhancedIconButton
              authority="pricing.add"
              ariaLabel="add row"
              tooltip="Přidat"
              size="small"
              Icon={Add}
              onClick={() => showNewPrice(massageType)}
          />
      </TableCell>
      <TableCell align="right">{massageType.id}</TableCell>
      <TableCell align="right">{massageType.name}</TableCell>
      <TableCell align="right">{massageType.short}</TableCell>
      <TableCell align="right">{massageType.paymentType.name}</TableCell>
      <TableCell align="right">{massageType.car.nazev}</TableCell>
    </>
  );

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTable headerList={headerList} dataList={massageTypeList} renderRowCells={renderRowCells} />
        <PriceHistoryDialog open={openHistory} setOpenHistory={setOpenHistory} massageType={selectedMassageType} />
        <PriceAddDialog open={openNew} setOpenNew={setOpenNew} massageType={selectedMassageType} />
      </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  iconCell: {
    whiteSpace: "nowrap"
  }
}));

export default PricingTable;
