import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import EnhancedSelect from "src/components/enhanced/EnhancedSelect";
import {
  fetchAclRoles,
  fetchAllowedCompanyList,
  fetchCompanyList,
  fetchMasseurs,
  fetchReceptionists//,
  //fetchRooms
} from "src/features/listsSlice";
//import { fetchCompanyList }  from "src/features/companySlice";
import { fetchPassLog } from "src/features/passLogSlice";
import { fetchAllColors } from "src/features/colorSlice";
import { fetchCleanerEvents, fetchEventsAsync, fetchMasseursEvents } from "src/features/reservationSlice";
import { fetchUserListByCompanyId, fetchUserProfileTypes } from "src/features/usersSlice";
import { fetchVoucherListByCompanyId } from "src/features/voucherSlice";
import { fetchCompanySums } from "src/features/companySumSlice";
import GrantedAuthHelper from "src/helpers/GrantedAuthHelper";
import {
  getSessionCalendarSettings,
  //getSessionRooms,
  setSessionCalendarSettings,
  setSessionCompanyName
} from "src/helpers/sessionHelper";
import EnhancedConfirmButton from "./enhanced/EnhancedConfirmButton";
import {fetchMassageTypeList} from "../features/pricingSlice";

function Settings(props) {
  const classes = useStyles();
  let location = useLocation();

  const { openSettings, setOpenSettings } = props;
  const dispatch = useDispatch();

  const allowedCompanyList = useSelector((state) => state.lists.allowedCompanyList);
//  const roomList = useSelector((state) => state.lists.roomList);
//  const isRoomListFetching = useSelector((state) => state.lists.isRoomListFetching);

  useEffect(() => {
    openSettings && dispatch(fetchAllowedCompanyList());
  }, [dispatch, openSettings]);

  const [formState, setFormState] = useState(getSessionCalendarSettings());
  const [companyName, setCompanyName] = useState("");

//  useEffect(() => {
//    if (formState.companyId && openSettings) dispatch(fetchRooms(formState.companyId));
//  }, [formState.companyId, dispatch, openSettings]);

//  const [isRoomFetching, setIsRoomFetching] = useState(false);
//  useEffect(() => {
//    setIsRoomFetching(isRoomListFetching);
//  }, [roomList, isRoomListFetching]);

// console.log('DEBUG Settings.js roomList', roomList); 
// console.log('DEBUG Settings.js isRoomListFetching', isRoomListFetching); 

  function handleClose() {
    //if (isCancelDisabled()) return;
    //setIsRoomFetching(true);
    setFormState(getSessionCalendarSettings());
    setOpenSettings(false);
  }

  function handleOk() {
    setSessionCalendarSettings(formState);
    setSessionCompanyName(companyName);
    switch (location.pathname) {
      case "/calendar":
        /*if (Object.keys(getSessionRooms()).length !== 0)*/ dispatch(fetchEventsAsync());
        break;
      case "/user-management":
        dispatch(fetchUserListByCompanyId());
        dispatch(fetchAclRoles());
        dispatch(fetchUserProfileTypes());
        break;
      case "/company-management":
        dispatch(fetchCompanyList());
        break;
      case "/voucher-management":
        dispatch(fetchVoucherListByCompanyId());
        break;
      case "/color-management":
        console.log('DEBUG Settings.js case /color-management');
        dispatch(fetchAllColors());
        break;
      case "/pass-log-management":
        dispatch(fetchPassLog());
        break;
      case "/agenda":
        dispatch(fetchMasseursEvents());
        break;
      case "/cleaner-agenda":
        dispatch(fetchCleanerEvents());
        break;
      case "/sum-management":
        dispatch(fetchCompanySums());
        break;
      case "/report-management":
        if (GrantedAuthHelper.hasAuthority("calendar.edit")) {
          dispatch(fetchMasseurs());
          dispatch(fetchReceptionists());
        }
        break;
      case "adv-report-management":
        break;
      case "pricing-management":
        dispatch(fetchMassageTypeList());
      default:
        break;
    }

    handleClose();
  }

  const handleCompanyChange = (e) => {
    e.persist();
  //  setIsRoomFetching(true);
    setCompanyName(allowedCompanyList.find((cL) => cL.companyId === +e.target.value)?.companyName);
    setFormState({ [e.target.name]: e.target.value });
  };

  const handleRoomsChange = (e) => {
    e.persist();
    setFormState((formState) => ({
      ...formState,
      [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value
    }));
  };

  const isOkDisabled = () => {
    let formValues = Object.values(formState);
 //   console.log('DEBUG isOkDisabled formState: ', formState);
 //   console.log('DEBUG isOkDisabled formValues: ', formValues);
//    console.log('DEBUG isOkDisabled formValues.indexOf(true) === -1: ', formValues.indexOf(true) === -1);
    return formValues.includes("") || !formValues.length;//formValues.includes(-1);//typeof formValues[index] === 'undefined';//formValues.indexOf(true) === -1;
  };

  const isCancelDisabled = () => {
//  console.log('DEBUG isCancelDisabled');
    return false;//Object.keys(getSessionRooms()).length === 0;
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={openSettings}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        fullWidth={true}
        maxWidth="md">
        <DialogTitle id="form-dialog-title">Výběr organizace</DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <EnhancedSelect
                name="companyId"
                value={formState.companyId}
                label="Organizace"
                list={allowedCompanyList}
                itemId="companyId"
                itemName="companyName"
                onChange={handleCompanyChange}
              />
            </Grid>
            <Grid item xs={12}>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <EnhancedConfirmButton handleOk={handleOk} disabledOk={isOkDisabled()} />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  }
}));

export default Settings;
