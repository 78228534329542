import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {getExportFetchURL, getFetchURL} from "src/helpers/actionHelpers";
import { getSessionCompanyId } from "src/helpers/sessionHelper";

const initialState = {
  voucherList: [],
  generatedVoucherNumber: []
};

export const fetchVoucherListByCompanyId = createAsyncThunk("passLog/fetchPassLog", async (_, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/voucher/company/get/${getSessionCompanyId()}`));
  return response.data;
});

export const insertVoucher = createAsyncThunk("voucher/insertVoucher", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/voucher/add")
  };

  const response = await axios(options).then((_) => {
    dispatch(fetchVoucherListByCompanyId());
  });

  return response.data;
});

export const deleteVoucher = createAsyncThunk("voucher/deleteVoucher", async (id, { dispatch }) => {
  const options = {
    method: "delete",
    data: { id: id },
    url: getFetchURL("/voucher/delete")
  };

  const response = await axios(options).then((_) => {
    dispatch(fetchVoucherListByCompanyId());
  });

  return response.data;
});

export const editVoucher = createAsyncThunk("vocher/editVoucher", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/voucher/edit")
  };

  const response = await axios(options).then((_) => {
    dispatch(fetchVoucherListByCompanyId());
  });

  return response.data;
});

export function exportVoucherPdf(voucherId) {
  window.open(getExportFetchURL(`/pdf-file/voucher/${voucherId}`));
}

export const exportMultiVoucherPdf = createAsyncThunk("vocher/exportMultiVoucherPdf", async (voucherIds, { dispatch }) => {
  const options = {
    method: "POST",
    data: {'ids': voucherIds},
    responseType: "blob",
    headers: { "Content-Type": "application/json" },
    url: getFetchURL(`/pdf-file/voucher`)
  };

  const response = await axios(options).then((r) => {
    const file = new Blob([r.data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    const pdfWindow = window.open();
    pdfWindow.location.href = fileURL;
  });

  return response.data;
});

export const voucherSlice = createSlice({
  name: "voucher",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchVoucherListByCompanyId.fulfilled, (state, action) => {
        state.voucherList = action.payload;
      });
  }
});

export default voucherSlice.reducer;
