import { DialogTitle, Typography } from "@material-ui/core";
import { Close } from "@mui/icons-material";//@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import EnhancedIconButton from "src/components/enhanced/EnhancedIconButton";

function EnhancedDialogTitle(props) {
  const classes = useStyles();
  const { handleClose, nameLabel } = props;

  return (
    <>
      <DialogTitle disableTypography aria-labelledby="form-dialog-title">
        <Typography variant="h6">{nameLabel}</Typography>
        <EnhancedIconButton
          className={classes.closeButton}
          ariaLabel="close"
          tooltip="Close"
          Icon={Close}
          onClick={handleClose}
        />
      </DialogTitle>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));

export default EnhancedDialogTitle;
