import {
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography
} from "@material-ui/core";
import { FiberManualRecord } from "@mui/icons-material"//@material-ui/icons";
//import { FiberManualRecord } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import { useSelector } from "react-redux";

const Agenda = (props) => {
  const classes = useStyles();
  const searchResults = useSelector((state) => state.reservation.searchResults);

  const { className, handleEventClick } = props;

  const handleClick = (event) => {
    handleEventClick({ event: event });
  };

  if (searchResults.length === 0)
    return (
      <Container className={`${className}`} maxWidth="sm">
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Typography variant="h4" gutterBottom>
                Nebyly nalezeny žádné výsledky
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    );

  return (
    <List className={`${classes.root} ${className}`}>
      {searchResults.map((event) => (
        <React.Fragment key={event.id}>
          <ListItem button onClick={() => handleClick(event)}>
            <ListItemIcon>
              <FiberManualRecord style={{ color: event.backgroundColor }} />
            </ListItemIcon>
            <ListItemText primary={event.title} secondary={`${event.dateTimeText}`} />
          </ListItem>
          <Divider />
        </React.Fragment>
      ))}
    </List>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  }
}));

export default Agenda;
