import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getFetchURL } from "src/helpers/actionHelpers";
import { getSessionCompanyId } from "src/helpers/sessionHelper";

const initialState = {
  userList: [],
  userSpecialProfile: [],
  userProfileTypeList: [],
  companiesUserList: []
};

export const fetchCompaniesByUser = createAsyncThunk("lists/fetchCompaniesByUser", async (userId, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/company-user/user/get/${userId}`));
  return response.data;
});

export const fetchUserProfileTypes = createAsyncThunk("lists/userProfileTypes", async (_, { dispatch }) => {
  const response = await axios.get(getFetchURL("/user-profile-type/get"));
  return response.data;
});

export const requestUserSpecialProfileBy = createAsyncThunk(
  "auth/requestUserSpecialProfileBy",
  async (userId, { dispatch }) => {
    const response = await axios.get(getFetchURL(`/user-profile/get/${userId}`));

    return response.data;
  }
);

export const fetchUserListByCompanyId = createAsyncThunk("users/fetchUserListByCompanyId", async (_, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/user/company/get-get-limited/${getSessionCompanyId()}`));
  return response.data;
});

export const insertUser = createAsyncThunk("users/insertUser", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/user/insert")
  };

  const response = await axios(options).then((_) => {
    dispatch(fetchUserListByCompanyId());
  });

  return response.data;
});

export const editUser = createAsyncThunk("users/editUser", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/user/edit")
  };

  const response = await axios(options).then((_) => {
    dispatch(fetchUserListByCompanyId());
  });

  return response.data;
});

export const editCompanyUser = createAsyncThunk("users/editCompanyUser", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/company-user/user/put")
  };

  const response = await axios(options).then((_) => {
    dispatch(fetchUserListByCompanyId());
  });

  return response.data;
});

export const editUserSpecial = createAsyncThunk("users/editUserSpecial", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/user-profile/put")
  };

  const response = await axios(options).then((_) => {});

  return response.data;
});

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserListByCompanyId.fulfilled, (state, action) => {
        state.userList = action.payload;
      })
      .addCase(fetchUserProfileTypes.fulfilled, (state, action) => {
        state.userProfileTypeList = action.payload;
      })
      .addCase(requestUserSpecialProfileBy.fulfilled, (state, action) => {
        state.userSpecialProfile = action.payload;
      })
      .addCase(fetchCompaniesByUser.fulfilled, (state, action) => {
        state.companiesUserList = action.payload;
      });
  }
});

export default usersSlice.reducer;
