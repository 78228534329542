import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import EnhancedConfirmButton from "src/components/enhanced/EnhancedConfirmButton";
import EnhancedTableToolbar from "../enhanced/EnhancedTableToolbar";
import {exportCompanyReceptionistsCash, exportSummarizationValues} from "../../features/advancedReportSlice";
import EnhancedDateField from "src/components/enhanced/EnhancedDateField";
import EnhancedSelect from "../enhanced/EnhancedSelect";
import {fetchAllowedCompanyList} from "../../features/listsSlice";

function AdvancedReportForm() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const allowedCompanyList = useSelector((state) => state.lists.allowedCompanyList);

  useEffect(() => {
    dispatch(fetchAllowedCompanyList());
  }, [dispatch]);

  const [formState, setFormState] = useState({});
  let scenarioList = [
    { id: "card-slevomat", name: "Report tržeb kartou a slevomatů" },
    { id: "receptionists-company", name: "Výkaz hotovostních tržeb za pobočku a recepční" },
  ];

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  };

  const handleGenerate = () => {
    const apiData = {
      dateSince: formState.dateSince,
      dateTo: formState.dateTo
    };

    if (formState.scenario === "card-slevomat") {
      dispatch(exportSummarizationValues(apiData));
    } else if (formState.scenario === "receptionists-company") {
      apiData.companyId = formState.companyId;
      dispatch(exportCompanyReceptionistsCash(apiData));
    }



  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar label="Sumarizační reporty" />
        <Grid spacing={12}>
          <Grid item xs={12} className={classes.formControl}>
            <EnhancedDateField
                name="dateSince"
                value={formState.dateSince}
                onChange={handleChange}
                label="Datum od"
            />
          </Grid>
          <Grid item xs={12} className={classes.formControl}>
            <EnhancedDateField
                name="dateTo"
                value={formState.dateTo}
                onChange={handleChange}
                label="Datum do"
            />
          </Grid>
          <Grid item xs={12} className={classes.formControl}>
            <EnhancedSelect
                name="scenario"
                value={formState.scenario}
                label="Scénář"
                list={scenarioList}
                onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} className={classes.formControl}>
            {formState.scenario === "receptionists-company" && allowedCompanyList.length > 0 ? (
                <EnhancedSelect
                    name="companyId"
                    value={formState.companyId}
                    label="Organizace"
                    list={allowedCompanyList}
                    itemId="companyId"
                    itemName="companyName"
                    onChange={handleChange}
                />
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <EnhancedConfirmButton handleOk={handleGenerate} okLabel="Vygenerovat report" />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: theme.spacing(4),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  formControl: {
    width: "100%",
    padding: '5px'
  }
}));

export default AdvancedReportForm;
