import { Paper, TableCell } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Edit } from "@mui/icons-material";//@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//import { fetchCompanyList } from "src/features/companySlice";
import { fetchCompanyList } from "src/features/listsSlice";

import CompanyDialog from "src/components/company/CompanyDialog";
import EnhancedIconButton from "src/components/enhanced/EnhancedIconButton";
import EnhancedTable from "src/components/enhanced/EnhancedTable";
import EnhancedTableToolbar from "src/components/enhanced/EnhancedTableToolbar";

function CompanyTable() {
  const classes = useStyles();
  const dispatch = useDispatch();

//  const companyList = useSelector((state) => state.lists.companyList);

  const [openEdit, setOpenEdit] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState({});

  useEffect(() => {
    dispatch(fetchCompanyList());
  }, [dispatch]);


  const companyList = useSelector((state) => state.lists.companyList);

  const headerList = [
    { align: "left", label: "" },
    { align: "right", label: "ID" },
    { align: "right", label: "Název" },
    { align: "right", label: "Adresa" },
    { align: "right", label: "Telefon" },
    { align: "right", label: "Web" },
    { align: "right", label: "Email" },
    { align: "right", label: "Spisová značka" },
    { align: "right", label: "Zkratka" }
  ];

  function handleEdit (company) {
    setSelectedCompany(company);
    setOpenEdit(true);
  };

/* protoze se data neobnovujou
  const handleEdit = (company) => {
    setSelectedCompany(company);
    setOpenEdit(true);
  };
*/

//console.log("DEBUG company", company);

  const renderRowCells = (company) => (
    <>
      <TableCell>
        <EnhancedIconButton
          authority="company.edit"
          ariaLabel="edit row"
          tooltip="Upravit"
          size="small"
          Icon={Edit}
          onClick={() => handleEdit(company)}
        />
      </TableCell>
      {(Object.keys(companyList[0]) || []).map((key) => (
        <TableCell key={key} align="right">
          {company[key]}
        </TableCell>
      ))}

{/*
      <TableCell align="right"> {company["id"]} </TableCell>
      <TableCell align="right"> {company["name"]} </TableCell>
      <TableCell align="right"> {company["address"]} </TableCell>
      <TableCell align="right"> {company["phone"]} </TableCell>
      <TableCell align="right"> {company["webSite"]} </TableCell>
      <TableCell align="right"> {company["email"]} </TableCell>
      <TableCell align="right"> {company["fileNumber"]} </TableCell>
      <TableCell align="right"> {company["short"]} </TableCell> */}
    </>
  );

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar authority="company.edit" label="Společnosti"  setOpenNew={setOpenNew} addible />
        <EnhancedTable headerList={headerList} dataList={companyList} renderRowCells={renderRowCells} />
      </Paper>
      <CompanyDialog company={selectedCompany} openEdit={openEdit} setOpenEdit={setOpenEdit} openNew={openNew} setOpenNew={setOpenNew} />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  }
}));

export default CompanyTable;
