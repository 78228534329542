import { CircularProgress } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/styles";
import Moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HMCustomerSection from "src/components/calendar/hourModal/HMCustomerSection";
import HMPaymentSection from "src/components/calendar/hourModal/HMPaymentSection";
import HMStartEndSection from "src/components/calendar/hourModal/HMStartEndSection";
import EnhancedButton from "src/components/enhanced/EnhancedButton";
import EnhancedDialogTitle from "src/components/enhanced/EnhancedDialogTitle";
import EnhancedTextField from "src/components/enhanced/EnhancedTextField";
import { applyPassLogAsync, applySlevomatVoucherAsync, applyVoucherAsync } from "src/features/applySlice";
import { /*fetchMasseurs*/ fetchReceptionists } from "src/features/listsSlice";
import { fetchCars } from "src/features/carSlice";

import { exportBillPdf, payBill } from "src/features/paymentSlice";
import {
  customerIsNotComeAsync,
  deleteEventByAsync,
  editHourEventAsync,
  cancelPayment,
  IDLE,
  LOADING,
  SUCCESS
} from "src/features/reservationSlice";
import alertHelper from "src/helpers/alertHelper";
import GrantedAuthHelper from "src/helpers/GrantedAuthHelper";
import PaymentTypeResolver from "src/helpers/paymentTypeResolver";
import { getSessionCheckedCompany } from "src/helpers/sessionHelper";
import EnhancedDateField from "src/components/enhanced/EnhancedDateField";

function HourModal(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const status = useSelector((state) => state.reservation.status);
  const dataById = useSelector((state) => state.reservation.dataById);
  const carList = useSelector((state) => state.car.carList);// const masseurList = useSelector((state) => state.lists.masseurList);
  const receptionistList = useSelector((state) => state.lists.receptionistList);

  const { open, setOpen, selected, setSelected } = props;
  //const { open, setOpen, selected, setSelected, carId, setCarId } = props;

  const [formState, setFormState] = useState({});
  const [startAtWork, setStart] = useState();
  const [endAtWork, setEnd] = useState();
  const [celkovaDoba, setCelkovaDoba] = useState();

  const [carId, setCarId] = useState("");

//  console.log("DEBUG dataById: ", dataById, ", selected: ", );
  //ok console.log("DEBUG celkovaDoba: ", celkovaDoba );
  console.log("DEBUG carId: ", carId );

  useEffect(() => {
    if (dataById[selected]) {
      setFormState((formState) => ({
        ...formState,
        //room: dataById[selected]?.room,
        note: dataById[selected]?.note,
        title: dataById[selected]?.title,
        carId: dataById[selected]?.car?.id, //masseurId: dataById[selected]?.masseur?.id,
        receptionistId: dataById[selected]?.receptionist?.id,
        isCome: dataById[selected]?.isCome,
        slevomatDate: dataById[selected]?.slevomatDate
      }));

	//console.log("DEBUG formState.carId: ", formState.carId);
	//console.log("DEBUG formState: ", formState);
	setCarId(formState.carId);
	//ok console.log("DEBUG carId: ", carId);

      let notPaidOwnReservations = dataById[selected]?.notPaid;
      if (notPaidOwnReservations.count > 0) {
        alertHelper.error({
          message: `Počet neuzavřených rezervací: ${notPaidOwnReservations.count} ( ${notPaidOwnReservations.message} )`
        });
      }
    }
  }, [dataById, selected]);

  //setCarId(formState.carId);

  useEffect(() => {
    if (open && getSessionCheckedCompany()) {
      //dispatch(fetchMasseurs()); zadna zmena v https://172.18.0.4:3000/calendar
      dispatch(fetchReceptionists());
    }
  }, [open, dispatch]);

  useEffect(() => {
      setCelkovaDoba(0);
      //dispatch(setCelkovaDoba(0));
  //}, []);
  }, [dispatch]);

  //ok console.log("DEBUG celkovaDoba: ", celkovaDoba );
  

  function isPassLog() {
    return PaymentTypeResolver.isPassLog(formState.massageTypeShort);
  }

  function isVoucher() {
    return PaymentTypeResolver.isVoucher(formState.massageTypeShort);
  }

  function isSlevomat() {
    return PaymentTypeResolver.isSlevomat(formState.paymentTypeId);
  }

  function isCash() {
    return PaymentTypeResolver.isCash(formState.paymentTypeId);
  }

  function isCreditCard() {
    return PaymentTypeResolver.isCreditCard(formState.paymentTypeId);
  }

  function isDisabledButtons() {
    let thirtyMinutesAfter = Moment(endAtWork);
    thirtyMinutesAfter.set({
      minute: thirtyMinutesAfter.minute() + 30
    });

    return thirtyMinutesAfter < Moment() && formState.voucherApplied && !GrantedAuthHelper.hasAuthority("all");
  }

  function handleChange(event) {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  }

  function handleClose() {
    setSelected(null);
    setOpen(false);
  }

  function handleDelete() { // label="Smazat"
    dispatch(deleteEventByAsync(selected));
    handleClose();
  }

  function handleCancelPayment() { // label="Magic"
    let apiData = {
      reservationId: dataById[selected].id
    };

    alertHelper.confirm({
      message: "Opravdu si přejete zrušit platbu?",
      callback: () => dispatch(cancelPayment(apiData))
    });
  }

  function saveReservation(start, end) {
    const apiData = {
      id: dataById[selected].id,
      title: formState.title,
      start: start,
      end: end,
      note: formState.note,
      carId: formState.carId, //masseurId: formState.masseurId,
      receptionistId: formState.receptionistId,
      customerId: formState.customer?.id,
      //roomId: formState.room?.id,
      paymentId: formState.paymentId
    };

	console.log("formState.carId: ", formState.carId);
	console.log("formState.paymentId: ", formState.paymentId);

    dispatch(editHourEventAsync(apiData));
  }

  function handleOk() { // label="Potvrdit"
    if (startAtWork === undefined || endAtWork === undefined) {
      alertHelper.error({ message: "Datum rezervace se nepodařilo nastavit. Zkontrolujte zda je validní." });
      return false;
    }
/*
    if (!formState.paymentTypeId) {
      alertHelper.error({ message: "Musíte zadat Způsob platby", duration: 3000 });
      return false;
    }*/


    handleClose();
    saveReservation(startAtWork, endAtWork);
  }

  function handlePay() { // label="Zaplatit"
    const apiData = {
      companyId: getSessionCheckedCompany()
    };

    apiData.paymentTypeId = formState.paymentTypeId;
    apiData.voucherNumber = formState.voucherNumber;
    apiData.massageTypeId = formState.jednotkovaCenaId;//typeOfMassageId;
    apiData.billUUID = formState.billUUID;
    apiData.invoiceAddress = formState.invoiceAddress;

    let exportBill = isCash() || isCreditCard();
    alertHelper.confirm({
      message: getConfirmPayMessage(),
      callback: () => payAndSave(apiData, exportBill),
      className: isPayInTheFuture() ? classes.confirmWarning: null
    });
  }

  function isPayInTheFuture() {
    let startTime = new Moment(startAtWork);
    startTime.set({
      minute: startTime.minute() - 30
    });

    return startTime > Moment();
  }

  function getConfirmPayMessage() {
    let confirmMessage = 'Opravdu si přejete zaplatit rezervaci?';
    if (isPayInTheFuture()) {
      confirmMessage = 'POZOR: Opravdu si přejete zaplatit rezervaci, která ještě neproběhla?';
    }

    return confirmMessage;
  }

  function payAndSave(apiData, exportBill) {
    const reservationData = {
      id: dataById[selected].id,
      title: formState.title,
      start: dataById[selected].start,
      end: dataById[selected].end,
      note: formState.note,
      //masseurId: formState.masseurId,
      receptionistId: formState.receptionistId,
      customerId: formState.customer?.id,
      carId: formState.carId// roomId: formState.room?.id
    };

    console.log("DEBUG apiData", apiData);

    dispatch(payBill({ apiData, exportBill, reservationData }));
  }

  function handleApply() { // Uplatnit
    const apiData = {
      voucherNumber: formState.voucherNumber
    };

    if (isVoucher()) {
      let reservationDateObj = new Moment(startAtWork);
      apiData.reservationDate = reservationDateObj.format("YYYY-MM-DD");

      dispatch(applyVoucherAsync(apiData));
    }

    if (isPassLog()) {
      apiData.reservationId = dataById[selected].id;

      dispatch(applyPassLogAsync(apiData));
    }

    if (isSlevomat()) {
      if (formState.slevomatDate === null) {
        alertHelper.error({ message: "Musíte zadat datum uplatnění!" });
        return false;
      }

      apiData.reservationId = dataById[selected].id;
      apiData.applyDate = formState.slevomatDate;

      dispatch(applySlevomatVoucherAsync(apiData));
    }

    console.log("DEBUG apiData", apiData);
    // save data to reservation
    saveReservation(dataById[selected].start, dataById[selected].end);
  }

  function handleNotTakePlace() { // label="Neobsazeno"
    alertHelper.confirm({
      message: "Opravdu masáž v tento termín neproběhla?",
      callback: () => dispatch(customerIsNotComeAsync(dataById[selected].id))
    });
  }

  function handleExportBill() { // label="Účtenka"
    if (formState.billUUID) {
      dispatch(exportBillPdf(formState.billUUID));
    }
  }

  function isOkDisabled() {
    return isDisabledButtons();
  }

  function isPayDisabled() {
    return !formState.paymentTypeId || !formState.jednotkovaCenaId;//typeOfMassageId;
  }

  function isApplyDisabled() {
    return (!isPassLog() && !isVoucher() && !(formState.paymentId && isSlevomat())) || formState.voucherApplied;
  }

  function isDeleteDisabled() {
    return !selected || !!formState.paymentId || isDisabledButtons();
  }

  function isCancelPaymentDisabled() {
    if (isPassLog() || isVoucher() || isSlevomat()) {
      return !formState.voucherApplied;
    }

    return !formState.paymentId;
  }

  function isNotTakePlaceDisabled() {
    return (
      ((isPassLog() || isVoucher()) && formState.voucherApplied) ||
      (!isPassLog() && !isVoucher() && !!formState.paymentId) ||
      isDisabledButtons()
    );
  }

  function isExportBillDisabled() {
    return !formState.paymentId || (formState.paymentId && !isCash() && !isCreditCard());
  }

  function isDisabledSlevomatDate() {
    return isApplyDisabled() || !isSlevomat();
  }

  function isDisabledDateTime() {
    return isApplyDisabled() && formState.paymentId && !GrantedAuthHelper.hasAuthority("all");
  }

  function isCancelPaymentVisible() {
    return GrantedAuthHelper.hasAuthority("all");
  }

  function getDialogContent() {
    if (status === LOADING) {
      return (
        <div style={{ height: "600px" }}>
          <CircularProgress className={classes.progress} />
        </div>
      );
    }

    if (status === IDLE) {
      return null;
    }
/*
          <Grid item xs={4}>
            <EnhancedTextField
              disabled={true}
              name="room"
              value={formState.room?.name}
              label="Room"
              onChange={handleChange}
            />
          </Grid>
*/
    if (status === SUCCESS)
      return (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <EnhancedTextField name="title" value={formState.title} label="Název" onChange={handleChange} />
          </Grid>
          <HMCustomerSection
            selected={selected}
            formState={formState}
            setFormState={setFormState}
            handleChange={handleChange}
          />

          <Grid item xs={4}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-rec-native-simple">Recepční</InputLabel>
              <Select
                native
                value={formState.receptionistId || ""}
                label="Recepční"
                onChange={handleChange}
                inputProps={{
                  name: "receptionistId",
                  id: "outlined-receptionist"
                }}>
                <option aria-label="None" value="" />
                {receptionistList.map((r) => (
                  <option key={r.id} value={r.id}>
                    {r.fullName}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-mas-native-simple">Vozidlo</InputLabel>
              <Select
                native
                value={formState.carId || ""/*formState.masseurId || ""*/}
                label="Vozidlo"
                onChange={handleChange}
                inputProps={{
                  name: "carId",
                  id: "outlined-car"
                }}>
                <option aria-label="None" value="" />
                {/*{masseurList.map((m) => (*/}
                {carList.map((m) => (
                  <option key={m.id} value={m.id}>
                    {m.nazev}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <HMStartEndSection
            formState={formState}
            setFormState={setFormState}
            handleChange={handleChange}
            setStart={setStart}
            setEnd={setEnd}
            selected={selected}
            open={open}
            disabled={isDisabledDateTime()}
          />
          <Grid item xs={12}>
            <EnhancedTextField name="note" value={formState.note} label="Poznámka" onChange={handleChange} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <HMPaymentSection
            open={open}
            selected={selected}
            formState={formState}
            setFormState={setFormState}
            handleChange={handleChange}
            carId={carId}
          />

      <Grid item xs={4}>
        <EnhancedTextField
          name="etfCelkovaDoba"
          value={(formState.endAtWork - formState.startAtWork) / 3600000 + ' hod.'}
          label="Celková Doba"
          disabled={true}
	  
        />
      </Grid>
      <Grid item xs={4}>
        <EnhancedTextField
          name="CelkovaCena"
          value={celkovaDoba }
          label="Celková Cena"
          onChange={handleChange}
          disabled={true}
        />
      </Grid>



          <Grid item xs={12}>
            <EnhancedDateField
              disabled={isDisabledSlevomatDate()}
              name="slevomatDate"
              label="Slevomat - datum uplatnění"
              value={formState.slevomatDate}
              handleChange={handleChange}
            />
          </Grid>
        </Grid>

      );
  }
//label="Vozidlo" {/*Masér"*/}
// name: "carId"{/*"masseurId"*/}
// id: "outlined-car" {/*"outlined-masseur"*/}
// handleChangeCelkovaDoba
// onChange={handleChange}
/*value={celkovaDoba + ' Kč'}*/

// onChange={(event) => {setCelkovaDoba(event.target.value); console.log("DEBUG celkovaDoba: ", celkovaDoba );}}

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <EnhancedDialogTitle handleClose={handleClose} nameLabel="Rezervace" />
        <DialogContent>{getDialogContent()}</DialogContent>
        <DialogActions>
          <EnhancedButton
            onClick={handleDelete}
            disabled={isDeleteDisabled()}
            className={classes.delete}
            label="Smazat"
            authority="calendar.delete"
          />
          {isCancelPaymentVisible() ? (
              <EnhancedButton
                  onClick={handleCancelPayment}
                  disabled={isCancelPaymentDisabled()}
                  className={classes.cancel}
                  label="Magic"
                  authority="payment.cancel-payment"
              />
          ) : null}
          <EnhancedButton
            onClick={handleNotTakePlace}
            disabled={isNotTakePlaceDisabled()}
            className={classes.notTakePlace}
            label="Neobsazeno"
            authority="calendar.edit"
          />
          <EnhancedButton
            onClick={handlePay}
            disabled={isPayDisabled() || !!formState.paymentId}
            className={classes.pay}
            label="Zaplatit"
            authority="calendar.edit"
          />
          <EnhancedButton
            onClick={handleExportBill}
            disabled={isExportBillDisabled()}
            className={classes.exportBill}
            label="Účtenka"
            authority="calendar.edit"
          />
          <EnhancedButton
            onClick={handleApply}
            disabled={isApplyDisabled()}
            className={classes.apply}
            label="Uplatnit"
            authority="calendar.edit"
          />
          <EnhancedButton
            onClick={handleOk}
            disabled={isOkDisabled()}
            className={classes.ok}
            label="Potvrdit"
            authority="calendar.edit"
          />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  progress: {
    margin: theme.spacing(2)
  },
  root: {
    flexGrow: 1
  },
  formControl: {
    width: "100%"
  },
  ok: {
    color: "white",
    background: "green",
    "&:hover": {
      color: "white",
      background: "#006400"
    }
  },
  pay: {
    color: "white",
    background: "blue",
    "&:hover": {
      color: "white",
      background: "darkblue"
    }
  },
  apply: {
    color: "white",
    background: "#ff6600",
    "&:hover": {
      color: "white",
      background: "#cc5200"
    }
  },
  delete: {
    color: "white",
    background: "red",
    "&:hover": {
      color: "white",
      background: "#B22222"
    }
  },
  notTakePlace: {
    color: "white",
    background: "#cc00cc",
    "&:hover": {
      color: "white",
      background: "#660066"
    }
  },
  exportBill: {
    color: "white",
    background: "#33cccc",
    "&:hover": {
      color: "white",
      background: "#29a3a3"
    }
  },
  cancel: {
    color: "white",
    background: "#ff9900",
    "&:hover": {
      color: "white",
      background: "#cc7a00"
    }
  },

  confirmWarning: {
    color: "#ff3300"
  },
}));

export default HourModal;
