import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getFetchURL } from "src/helpers/actionHelpers";
import { getSessionCheckedCompany, getSessionCheckedRooms, getSessionRooms } from "src/helpers/sessionHelper";

export const [IDLE, SUCCESS, LOADING, ERROR] = ["idle", "success", "loading", "error"];

const initialState = {
//  calendar: [],
//  searchResults: [],
//  dataById: {},
//  status: IDLE,
//  notPaidReservation: [],
  //reservationsByCompanyId2: {}
  reservationsByCompanyId2: []
};

//ok console.log('DEBUG reservation2Slice ');


export const fetchR2 = createAsyncThunk("reservation2/fetchR2", async (id, { dispatch }) => {
//export const fetchReservationsByCompanyId = createAsyncThunk("reservation/fetchReservationsByCompanyId", async (_, { dispatch }) => {
  //const response = await axios.get(getFetchURL(`/reservation-calendar/get-by-company/${getSessionCompanyId()}`));
  
  console.log('DEBUG reservation2Slice fetchReservationsByCompanyId2 response, response.data: ', response, response.data ); //
  // carERR/company/get-by-company     se neprojevi; v listsSlice se projevi !
const response = await axios.get(getFetchURL(`/car/company/get-by-company/${getSessionCompanyId()}`)).catch((error) => {
        console.log(error);}); // 
//const response = await axios.get(getFetchURL(`/car/company/get-by-company/reservationbycompanyid2/${getSessionCompanyId()}`)); // 
//const response = await axios.get(getFetchURL(`/reservation-calendar/reservationbycompanyid2/${getSessionCompanyId()}`)); // 
//const response = await axios.get(getFetchURL(`/reservation-calendar/current-company/${getSessionCompanyId()}`));

 // console.log('DEBUG reservationSlice fetchReservationsByCompanyId response, response.data: ', response, response.data );
  return response.data;
});

export const reservation2Slice = createSlice({
  name: "reservation2",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      /*.addCase(customerIsNotComeAsync.fulfilled, (state, action) => {
        const id = action.meta.arg;
        state.dataById[id] = action.payload;
      })
      .addCase(fetchEventByAsync.pending, (state, action) => {
        state.status = LOADING;
      })
      .addCase(fetchEventByAsync.fulfilled, (state, action) => {
        const id = action.meta.arg;
        state.dataById[id] = action.payload;
        state.status = SUCCESS;
      })
      .addCase(fetchEventByAsync.rejected, (state, action) => {
        state.status = ERROR;
      })
      .addCase(fetchEventsAsync.fulfilled, (state, action) => {
        state.calendar = action.payload;
      })*/ // chybnej nazev se tady neprojevi; v listsSlice se projevi !
      .addCase(ERRfetchReservationsByCompanyId2.pending, (state, action) => {
        state.status = LOADING;
      })
      .addCase(fetchReservationsByCompanyId2.fulfilled, (state, action) => {
        //state.reservationsByCompanyId2 = action.payload;
        console.log("DEBUG .addCase(fetchReservationsByCompanyId2.fulfilled,", action);
        state.reservationsByCompanyId2 = 'DEBUG action.payload';
        //state.calendar = action.payload;
      })
      .addCase(fetchReservationsByCompanyId2.rejected, (state, action) => {
        console.log(action.error.message);
        state.reservationsByCompanyId2 = "error";
      })
      /*.addCase(findReservationEvent.fulfilled, (state, action) => {
        state.searchResults = action.payload;
      })
      .addCase(fetchMasseursEvents.fulfilled, (state, action) => {
        state.calendar = action.payload;
	// agenda
      })
      .addCase(fetchCleanerEvents.fulfilled, (state, action) => {
        state.calendar = action.payload;
	// agenda
      })
      .addCase(cancelPayment.fulfilled, (state, action) => {
        const id = action.meta.arg.reservationId;
        state.dataById[id] = action.payload;
      })*/;
  }
});

export default reservation2Slice.reducer;
