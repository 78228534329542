import Button from "@material-ui/core/Button";
import React from "react";
import GrantedAuthHelper from "src/helpers/GrantedAuthHelper";

function EnhancedButton(props) {
  const { label, onClick, disabled, className, authority } = props;
  const calcDisabled = disabled || (authority && !GrantedAuthHelper.hasAuthority(authority));
  
  return (
    <>
      <Button
        onClick={onClick}
        disabled={calcDisabled}
        color="secondary"
        variant="contained"
        className={className}>
        {label}
      </Button>
    </>
  );
}

export default EnhancedButton;
