import { createMuiTheme } from "@material-ui/core";

import palette from "src/theme/palette";
import typography from "src/theme/typography";
import overrides from "src/theme/overrides";

const theme = createMuiTheme({
  palette,
  typography,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
});

export default theme;
