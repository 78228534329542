import {Grid, TableCell} from "@material-ui/core";
import EnhancedIconButton from "../enhanced/EnhancedIconButton";
import {ArrowBack, Delete, Edit} from "@mui/icons-material";//@material-ui/icons";
import EnhancedTable from "../enhanced/EnhancedTable";
import React from "react";
import alertHelper from "../../helpers/alertHelper";
import {deleteCustomer} from "../../features/modalEditSlice";
import {useDispatch} from "react-redux";

const CustomerTable = (props) => {
  const dispatch = useDispatch();

  const {
    filteredCustomersList,
    handleBackBtn,
    className,
    setOpen,
    setSelectedCustomer
  } = props;

  const handleEdit = (customer) => {
    setSelectedCustomer(customer);
    setOpen(true);
  };

  const handleDelete = (customer) => {
    alertHelper.confirm({
      message: "Opravdu si přejete smazat zákazníka " + customer.firstName + " " + customer.lastName + "?",
      callback: () => dispatch(deleteCustomer(customer?.id))
    });
  };

  function isDisabledDelete(customer)
  {
    return customer['count'] > 0;
  }

  const headerList = [
    { align: "left", label: "" },
    { align: "left", label: "Jméno" },
    { align: "left", label: "Telefon" },
    { align: "left", label: "Rezervace" }
  ];

  const renderRowCells = (customer) => (
    <>
      <TableCell>
        <EnhancedIconButton
          authority="customer.edit"
          ariaLabel="edit row"
          tooltip="Upravit"
          size="small"
          Icon={Edit}
          onClick={() => handleEdit(customer)}
        />
      <EnhancedIconButton
        authority="customer.delete"
        ariaLabel="edit row"
        tooltip="Smazat"
        size="small"
        Icon={Delete}
        disabled={isDisabledDelete(customer)}
        onClick={() => handleDelete(customer)}
      />
      </TableCell>
      <TableCell align="left">{customer["fullName"]}</TableCell>
      <TableCell align="left">{customer["phone"]}</TableCell>
      <TableCell align="left">{customer["count"]}</TableCell>
    </>
  );

  return (
    <>
      <Grid item className={className}>
        <EnhancedIconButton ariaLabel="back icon" tooltip="Zpět" Icon={ArrowBack} onClick={handleBackBtn} />
        <EnhancedTable headerList={headerList} dataList={filteredCustomersList} renderRowCells={renderRowCells} />
      </Grid>
    </>
  );
};

export default CustomerTable;
