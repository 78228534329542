import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getFetchURL } from "src/helpers/actionHelpers";

const initialState = {
  appliedTicket: {}
};

export const applyVoucherAsync = createAsyncThunk("apply/applyVoucher", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/voucher/apply")
  };

  const response = await axios(options);

  return response.data;
});

export const applyPassLogAsync = createAsyncThunk("apply/applyPassLog", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/pass-log/apply")
  };

  const response = await axios(options);

  return response.data;
});

export const applySlevomatVoucherAsync = createAsyncThunk(
  "apply/applySlevomatVoucher",
  async (apiData, { dispatch }) => {
    const options = {
      method: "PUT",
      data: apiData,
      headers: { "Content-Type": "application/json" },
      url: getFetchURL("/slevomat/apply")
    };

    const response = await axios(options);

    return response.data;
  }
);

export const applySlice = createSlice({
  name: "apply",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(applyVoucherAsync.fulfilled, (state, action) => {
        state.voucher = action.payload;
      })
      .addCase(applyPassLogAsync.fulfilled, (state, action) => {
        state.voucher = action.payload;
      })
      .addCase(applySlevomatVoucherAsync.fulfilled, (state, action) => {
        state.voucher = action.payload;
      });
  }
});

export default applySlice.reducer;
