import { Button, colors, List, ListItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { forwardRef } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import GrantedAuthHelper from "src/helpers/GrantedAuthHelper";

const SidebarNav = (props) => {
  const { pages } = props;
  const classes = useStyles();

  const filteredPages = pages.filter((p) => GrantedAuthHelper.hasAuthority(p.authority));

  return (
    <List className={classes.root}>
      {filteredPages.map((page, i) => (
        <ListItem className={classes.item} disableGutters key={page.title + i}>
          <Button
            activeClassName={classes.active}
            className={classes.button}
            component={CustomRouterLink}
            onClick={() => props.onClose()}
            to={page.href}>
            <div className={classes.icon}>{page.icon}</div>
            {page.title}
          </Button>
        </ListItem>
      ))}
    </List>
  );
};

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <RouterLink {...props} />
  </div>
));

SidebarNav.propTypes = {
  pages: PropTypes.array.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: { marginBottom: theme.spacing(2) },
  item: {
    display: "flex",
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    "& $icon": {
      color: theme.palette.primary.main
    }
  }
}));

export default SidebarNav;
