import { Checkbox, Paper, TableCell } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Delete, Edit, PictureAsPdf } from "@mui/icons-material";//@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    deleteVoucher,
    fetchVoucherListByCompanyId,
    exportVoucherPdf,
    exportMultiVoucherPdf
} from "src/features/voucherSlice";
import EnhancedIconButton from "src/components/enhanced/EnhancedIconButton";
import EnhancedTable from "src/components/enhanced/EnhancedTable";
import EnhancedVoucherTableToolbar from "src/components/enhanced/EnhancedVoucherTableToolbar";
import NewVoucherDialog from "src/components/voucher/NewVoucherDialog";
import VoucherEditDialog from "src/components/voucher/VoucherEditDialog";
import alertHelper from "src/helpers/alertHelper";

function VoucherTable() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const voucherList = useSelector((state) => state.voucher.voucherList);

  const [open, setOpen] = useState(false);
  const [selectedVoucher, setSelectedVoucher] = useState({});
  const [openNew, setOpenNew] = useState(false);
  const [formState, setFormState] = useState({});

  useEffect(() => {
    dispatch(fetchVoucherListByCompanyId());
  }, [dispatch]);

// 92 prvku  console.log('DEBUG Voucher.js voucherList: ', voucherList );
  //ok console.log('DEBUG Voucher.js voucherList[0]: ', voucherList[0] );

  const headerList = [
    { align: "left", label: "" },
    { align: "right", label: "ID" },
    { align: "right", label: "Jméno" },
    { align: "right", label: "Číslo poukazu" },
    { align: "right", label: "Datum platnosti" },
    { align: "right", label: "Datum uplatnění" },
    { align: "right", label: "Datum rezervace" },
    { align: "right", label: "Vytvořil/a" },
    { align: "right", label: "Vytvořeno dne" },
    { align: "right", label: "Číslo účtenky" }
  ];

  const handleEdit = (voucher) => {
    setSelectedVoucher(voucher);
    setOpen(true);
  };

  const handleDelete = (voucher) => {
    alertHelper.confirm({
      message: "Opravdu si přejete smazat dárkový poukaz?",
      callback: () => dispatch(deleteVoucher(voucher.id))
    });
  };

    const handleChange = (e) => {
        e.persist();
        setFormState((formState) => ({
            ...formState,
            [e.target.name]: e.target.type === "checkbox" ? e.target.checked : e.target.value
        }));
    };

  function handleExportVoucher(voucher) {
      if (voucher.id) {
          dispatch(exportVoucherPdf(voucher.id));
      }
  }

  function handleMultiPdfGenerate() {
      let voucherIds = [];
      Object.keys(formState).forEach(function(key) {
          if (formState[key]) {
              voucherIds.push(key);
          }
      });

      if (voucherIds.length === 0) {
          alertHelper.error({ message: "Musíte označit nějaké poukazy!"});
          return false;
      }

      dispatch(exportMultiVoucherPdf(voucherIds));
  }

  const renderRowCells = (voucher) => (
    <>
      <TableCell className={classes.iconCell}>
        <Checkbox
            checked={formState[voucher.id] || false}
            onChange={handleChange}
            name={String(voucher.id)}
        />
        <EnhancedIconButton
          authority="voucher.edit"
          ariaLabel="pdf row"
          tooltip="Vygenerovat poukaz"
          size="small"
          Icon={PictureAsPdf}
          onClick={() => handleExportVoucher(voucher)}
        />
        <EnhancedIconButton
          authority="voucher.edit"
          ariaLabel="edit row"
          tooltip="Upravit"
          size="small"
          Icon={Edit}
          onClick={() => handleEdit(voucher)}
        />
        <EnhancedIconButton
          authority="voucher.delete"
          ariaLabel="remove row"
          tooltip="Smazat"
          size="small"
          Icon={Delete}
          onClick={() => handleDelete(voucher)}
        />
      </TableCell>
      <TableCell align="right">{voucher["id"]}</TableCell>
      <TableCell align="right">{voucher["title"]}</TableCell>
      <TableCell align="right">{voucher["number"]}</TableCell>
      <TableCell align="right">{voucher["dateExpireText"]}</TableCell>
      <TableCell align="right">{voucher["afterExpire"] ? "Neplatný" : voucher["dateUsed"]}</TableCell>
      <TableCell align="right">{voucher["reservationDate"]}</TableCell>
      <TableCell align="right">{voucher["createdBy"]}</TableCell>
      <TableCell align="right">{voucher["created"]}</TableCell>
      <TableCell align="right">{voucher["billUUID"]}</TableCell>
    </>
  );

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedVoucherTableToolbar
            label="Dárkové poukazy"
            setOpenNew={setOpenNew}
            handleMultiPdfGenerate={handleMultiPdfGenerate}
        />
        <EnhancedTable headerList={headerList} dataList={voucherList} renderRowCells={renderRowCells} />
      </Paper>
      <VoucherEditDialog voucher={selectedVoucher} open={open} setOpen={setOpen} />
      <NewVoucherDialog open={openNew} setOpenNew={setOpenNew} />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  iconCell: {
    whiteSpace: "nowrap"
  }
}));

export default VoucherTable;
