import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { editPassLog } from "src/features/passLogSlice";
import { exportBillPdf } from "src/features/paymentSlice";
import EnhancedConfirmButton from "src/components/enhanced/EnhancedConfirmButton";
import EnhancedTextField from "src/components/enhanced/EnhancedTextField";
import EnhancedDialogTitle from "src/components/enhanced/EnhancedDialogTitle";
import GrantedAuthHelper from "src/helpers/GrantedAuthHelper";
import EnhancedDateField from "src/components/enhanced/EnhancedDateField";

function PassLogEditDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {open, setOpen, passlog} = props;
  const [formState, setFormState] = useState({});

  useEffect(() => {
    setFormState((formState) => ({
      ...formState,
      title: passlog?.title,
      number: passlog?.number,
      dateExpire: passlog?.dateExpire,
      billUUID: passlog?.billUUID,
      note: passlog?.note,
      showExportBillButton: passlog?.showExportBillButton
    }));
  }, [passlog]);

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = () => {
    const apiData = {
      id: passlog.id,
      title: formState.title,
      voucherNumber: formState.number,
      dateExpire: formState.dateExpire,
      note: formState.note
    };

    dispatch(editPassLog(apiData));
    handleClose();
  };

  function handleExportBill() {
    if (formState.billUUID) {
      dispatch(exportBillPdf(formState.billUUID));
    }
  }

  function isTitleDisabled() {
    return !GrantedAuthHelper.hasAuthority("passlog.all");
  }

  function isExportBillDisabled() {
    return !formState.showExportBillButton;
  }

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md" aria-labelledby="form-dialog-title">
        <EnhancedDialogTitle handleClose={handleClose} nameLabel="Úprava permanentky" />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <EnhancedTextField
                name="title"
                value={formState.title}
                label="Jméno"
                onChange={handleChange}
                disabled={isTitleDisabled()}
              />
            </Grid>
            <Grid item xs={6}>
              <EnhancedTextField
                name="number"
                value={formState.number}
                label="Číslo poukazu"
                onChange={handleChange}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <EnhancedDateField
                  name="dateExpire"
                  label="Platnost do"
                  value={formState.dateExpire}
                  handleChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <EnhancedTextField
                  name="note"
                  value={formState.note}
                  label="Poznámka"
                  onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <EnhancedConfirmButton handleOk={handleOk} okLabel="Uložit" />
          <Button
              onClick={handleExportBill}
              color="primary"
              variant="contained"
              className={classes.exportBill}
              disabled={isExportBillDisabled()}
          >
            Účtenka
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  exportBill: {
    color: "white",
    background: "#33cccc",
    "&:hover": {
      color: "white",
      background: "#29a3a3"
    }
  }
}));

export default PassLogEditDialog;
