// podle src/features/usersSlice.js

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getFetchURL } from "src/helpers/actionHelpers";
import { getSessionCompanyId } from "src/helpers/sessionHelper";

const initialState = {
  carList: []/*,
  userSpecialProfile: [],
  userProfileTypeList: [],
  companiesUserList: []*/
};

//console.log('DEBUG 1');
/*
export const fetchCompaniesByUser = createAsyncThunk("lists/fetchCompaniesByUser", async (userId, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/company-user/user/get/${userId}`));
  return response.data;
});

export const fetchUserProfileTypes = createAsyncThunk("lists/userProfileTypes", async (_, { dispatch }) => {
  const response = await axios.get(getFetchURL("/user-profile-type/get"));
  return response.data;
});
*/
export const requestUserSpecialProfileBy = createAsyncThunk(
  "auth/requestUserSpecialProfileBy",
  async (userId, { dispatch }) => {
    const response = await axios.get(getFetchURL(`/user-profile/get/${userId}`));

    return response.data;
  }
);

/*
export const fetchUserListByCompanyId = createAsyncThunk("users/fetchUserListByCompanyId", async (_, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/user/company/get-get-limited/${getSessionCompanyId()}`));
  return response.data;
}); 2022 3 3 */


//console.log('DEBUG 2');

export const fetchCarListByCompanyId = createAsyncThunk("car/fetchCarListByCompanyId", async (_, { dispatch }) => {
  //err ?const response = await axios.get(getFetchURL(`/car/company/get-by-company/${getSessionCompanyId()}`)).catch(  error=> this.setState({error, isLoading: false}));;
	//console.log('DEBUG 3');//vola se
  const response = await axios.get(getFetchURL(`/car/company/get-by-company/${getSessionCompanyId()}`));
   console.log('DEBUG carSlice.js export const fetchCarListByCompanyId = createAsyncThunk ; const response =  ', response); 

//	console.log('DEBUG 3.1');//vola se 
  return response.data;
});

//console.log('DEBUG 4');

/* z listsSlice
export const fetchCars = createAsyncThunk("lists/fetchCarsDEBUG", async (id, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/car/company/get-by-company/${getSessionCompanyId()}`));
  // /carERR/company/get-by-company
  // se projevi

//ok  console.log('DEBUG listsSlice fetchCars response, response.data: ', response, response.data );
  return response.data;
});
*/


export const insertCar = createAsyncThunk("car/insertCar", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/car/insert")
  };

  const response = await axios(options).then((_) => {
    dispatch(fetchCarListByCompanyId());
  });

  return response.data;
});

export const editCar = createAsyncThunk("car/editCar", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/car/edit")
  };

  console.log('DEBUG editCar: ' );

  const response = await axios(options).then((_) => {
    dispatch(fetchCarListByCompanyId());
  });

  // nic ale funguje to console.log('DEBUG editCar response.data: ', response.data);

  return response.data;
});

export const editCompanyCar = createAsyncThunk("car/editCompanyCar", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/company-user/user/put")
  };

  const response = await axios(options).then((_) => {
    dispatch(fetchCarListByCompanyId());
  });

  return response.data;
});
/*
export const editCarSpecial = createAsyncThunk("users/editCarSpecial", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/user-profile/put")
  };

  const response = await axios(options).then((_) => {});

  return response.data;
});*/

export const fetchCars = createAsyncThunk("car/fetchCars", async (id, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/car/company/get-by-company/${getSessionCompanyId()}`));
  // /carERR/company/get-by-company
  // se projevi

//ok   console.log('DEBUG  fetchCars response, response.data: ', response, response.data );
  return response.data;
});

export const carSlice = createSlice({
  name: "car",
  //name: "automobily",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCarListByCompanyId.fulfilled, (state, action) => { 
//.addCase(fetchUserListByCompanyId.fulfilled, (state, action) => {
        state.carList = action.payload;
      })/*
      .addCase(fetchUserProfileTypes.fulfilled, (state, action) => {
        state.userProfileTypeList = action.payload;
      })
      .addCase(requestUserSpecialProfileBy.fulfilled, (state, action) => {
        state.userSpecialProfile = action.payload;
      })
      .addCase(fetchCompaniesByUser.fulfilled, (state, action) => {
        state.companiesUserList = action.payload;
      })*/
      .addCase(fetchCars.fulfilled, (state, action) => {
	// DEBUG TEST ReferenceError: fetchCars2 is not defined
        state.carList = action.payload;
      })
;
  }
});

/*
  console.log('DEBUG carSlice.js  export const carSlice  ', carSlice); 
  console.log('DEBUG carSlice.js  export const carSlice.state  ', carSlice.state); 
  console.log('DEBUG  export const carSlice.car  ', carSlice.car); //undef
  console.log('DEBUG  export const carSlice.carList  ', carSlice.carList); //undef
  console.log('DEBUG  export const carSlice.carList3  ', carSlice.carList3); //undef
*/
export default carSlice.reducer;
