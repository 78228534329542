import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {getExportFetchURL, getFetchURL} from "src/helpers/actionHelpers";
import { getSessionCompanyId } from "src/helpers/sessionHelper";

const initialState = {
  colorList: [],
//  generatedVoucherNumber: []
};
/*
export const fetchVoucherListByCompanyId = createAsyncThunk("passLog/fetchPassLog", async (_, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/voucher/company/get/${getSessionCompanyId()}`));
   console.log('DEBUG colorSlice.js fetchVoucherListByCompanyId response:  ', response);
  return response.data;
});*/


export const fetchAllColors = createAsyncThunk("color/fetchAllColors", async (_, { dispatch }) => {
//  const response = await axios.get(getFetchURL(`/company/get`));
//   debugger;

  // /color/get  viz api/config/routes.yaml
  const response = await axios.get(getFetchURL(`/color/get`)).catch(  error=> this.setState({error, isLoading: false}));;
//   console.log('DEBUG colorSlice.js fetchAllColors response:  ', response);
 //  console.log('DEBUG colorSlice.js fetchAllColors response.data:  ', response.data);
   //console.log('DEBUG colorSlice.js fetchAllColors response.data[0]:  ', response.data[0]);
  // debugger;
  return response.data;
});


//export const fetchColors = { b: "b" };

export const insertColor = createAsyncThunk("color/insertColor", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/color/add")
  };

  const response = await axios(options).then((_) => {
    dispatch(fetchVoucherListByCompanyId());
  });

  return response.data;
});
/*
export const deleteVoucher = createAsyncThunk("voucher/deleteVoucher", async (id, { dispatch }) => {
  const options = {
    method: "delete",
    data: { id: id },
    url: getFetchURL("/voucher/delete")
  };

  const response = await axios(options).then((_) => {
    dispatch(fetchVoucherListByCompanyId());
  });

  return response.data;
});

export const editVoucher = createAsyncThunk("vocher/editVoucher", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/voucher/edit")
  };

  const response = await axios(options).then((_) => {
    dispatch(fetchVoucherListByCompanyId());
  });

  return response.data;
});

export function exportVoucherPdf(voucherId) {
  window.open(getExportFetchURL(`/pdf-file/voucher/${voucherId}`));
}
//fetchAllColors
export const exportMultiVoucherPdf = createAsyncThunk("vocher/exportMultiVoucherPdf", async (voucherIds, { dispatch }) => {
  const options = {
    method: "POST",
    data: {'ids': voucherIds},
    responseType: "blob",
    headers: { "Content-Type": "application/json" },
    url: getFetchURL(`/pdf-file/voucher`)
  };

  const response = await axios(options).then((r) => {
    const file = new Blob([r.data], { type: "application/pdf" });
    const fileURL = URL.createObjectURL(file);
    const pdfWindow = window.open();
    pdfWindow.location.href = fileURL;
  });

  return response.data;
});*/

export const colorSlice = createSlice({
  name: "color",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //.addCase(fetchColors , (state, action) => {//fetchVoucherListByCompanyId.fulfilled, (state, action) => {
      .addCase(fetchAllColors.fulfilled , (state, action) => {//fetchVoucherListByCompanyId.fulfilled, (state, action) => {
        state.colorList = action.payload;
      });
  }
});

export default colorSlice.reducer;
