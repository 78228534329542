import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editCompanyUser, fetchCompaniesByUser } from "src/features/usersSlice";
import EnhancedConfirmButton from "src/components/enhanced/EnhancedConfirmButton";
import EnhancedDialogTitle from "src/components/enhanced/EnhancedDialogTitle";
import { fetchCompanyList } from "src/features/listsSlice";

function UserCompanyDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const companiesUserList = useSelector((state) => state.users.companiesUserList);
  const companyList = useSelector((state) => state.lists.companyList);

  const { open, setOpen, user } = props;
  const [formState, setFormState] = useState({});

  useEffect(() => {
    if (open && user) {
      dispatch(fetchCompaniesByUser(user.id));
      dispatch(fetchCompanyList());
    }
  }, [open, user, dispatch]);

  useEffect(() => {
    if (open && user) {
      let tempFormState = {};
      companiesUserList.forEach((c) => {
        tempFormState = { ...tempFormState, [c.companyId]: true };
      });
      setFormState(tempFormState);
    }
  }, [open, user, companiesUserList]);

  const [isByUserFetching, setIsByUserFetching] = useState(true);
  useEffect(() => {
    setIsByUserFetching(companyList.length === 0);
  }, [companiesUserList, companyList]);

  function handleChange(e) {
    e.persist();
    setFormState((formState) => ({
      ...formState,
      [e.target.name]: e.target.checked
    }));
  }

  function handleClose() {
    setOpen(false);
    setFormState({});
    setIsByUserFetching(true);
  }

  function handleOk() {
    const apiData = {};
    apiData.userId = user.id;

    const obj = formState;
    const result = Object.keys(obj).reduce((o, key) => {
      obj[key] === true && (o[key] = obj[key]);
      return o;
    }, {});

    apiData.companyIds = Object.keys(result);

    dispatch(editCompanyUser(apiData));
    handleClose();
  }

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md" aria-labelledby="form-dialog-title">
        <EnhancedDialogTitle handleClose={handleClose} nameLabel="Uživatel v organizacích" />
        <DialogContent>
          <Grid item xs={6}>
            <FormGroup>
              {isByUserFetching ? (
                <CircularProgress />
              ) : (
                companyList.map((r) => (
                  <FormControlLabel
                    onChange={handleChange}
                    key={r.id}
                    control={<Checkbox checked={formState[r.id] || false} name={String(r.id)} />}
                    label={r.name}
                  />
                ))
              )}
            </FormGroup>
          </Grid>
        </DialogContent>
        <DialogActions>
          <EnhancedConfirmButton handleClose={handleClose} handleOk={handleOk} okLabel="Uložit" />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  }
}));

export default UserCompanyDialog;
