import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HMCustomerSection from "src/components/calendar/hourModal/HMCustomerSection";
import HMStartEndSection from "src/components/calendar/hourModal/HMStartEndSection";
import EnhancedDialogTitle from "src/components/enhanced/EnhancedDialogTitle";
import EnhancedTextField from "src/components/enhanced/EnhancedTextField";
import { /*fetchMasseurs, */fetchReceptionists, /*fetchRooms,*/ /*fetchCars*/ } from "src/features/listsSlice";
import { fetchCars } from "src/features/carSlice";
import { addHourEventAsync } from "src/features/reservationSlice";
import alertHelper from "src/helpers/alertHelper";
import { getSessionCheckedCompany, getSessionCheckedRooms } from "src/helpers/sessionHelper";

function AddHourModal(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  //const masseurList = useSelector((state) => state.lists.masseurList);
  const receptionistList = useSelector((state) => state.lists.receptionistList);
  const carList = useSelector((state) => state.car.carList);
  // maserna neni potreba const roomList = useSelector((state) => state.lists.roomList);


  const { open, setOpen, date, ClickedRowCarID } = props;
  //const { open, setOpen, date } = props;
  //const { open, setOpen, date, ClickedRowCarID, setClickedRowCarID } = props;
  const [formState, setFormState] = useState({});
  const [startAtWork, setStart] = useState();
  const [endAtWork, setEnd] = useState();

  useEffect(() => {
    if (open /*&& getSessionCheckedRooms().length === 1*/) {
      setFormState((formState) => ({
        ...formState,
        //room: getSessionCheckedRooms().map((c) => c[0])[0]
      }));
    }
  }, [setFormState, open]);

 // useEffect(() => {
//    if (getSessionCheckedCompany() && open) dispatch(fetchRooms(getSessionCheckedCompany()));
//  }, [open, dispatch]);

  useEffect(() => {
    if (open) {
      //dispatch(fetchMasseurs()); zadna zmena v https://172.18.0.4:3000/calendar
      dispatch(fetchReceptionists());
      dispatch(fetchCars());
    }
  }, [open, dispatch]);

  //ok console.log('DEBUG AddhourModal.jsx formState.carId: ', formState.carId ); 
//  console.log('DEBUG AddhourModal.jsx fetchMasseurs(): ', fetchMasseurs() ); 
  // console.log('DEBUG AddhourModal.jsx carList: ', carList ); //ok
 //ok console.log('DEBUG AddhourModal  ClickedRowCarID: ', ClickedRowCarID ); 

  function handleChange(event) {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  }

  function handleClose() {
    formState.title = "";
    formState.note = "";
    formState.carId = null;//formState.masseurId = null;
    formState.receptionistId = null;
    formState.customerId = null;
    //formState.room = null;
    setOpen(false);
  }

//alertHelper.error({ message: ClickedRowCarID , duration: 3000 });
const sessionCheckedCompany = getSessionCheckedCompany();

  function handleOk() {
    const apiData = {};

    if (!formState.title) {
      alertHelper.error({ message: "Musíte zadat název (Tip: stačí pomlčka)", duration: 3000 });
      return false;
    }

    if (!formState.receptionistId) {
      alertHelper.error({ message: "Musíte zadat recepční", duration: 3000 });
      return false;
    }

/*
//    if (!formState.masseurId) {
      alertHelper.error({ message: "Musíte zadat maséra", duration: 3000 });
      return false;
    }*/

	//
	console.log('DEBUG AddhourModal handleOK startAtWork, endAtWork: ', startAtWork, endAtWork);
	

    apiData.title = formState.title;
    apiData.start = startAtWork;
    apiData.end = endAtWork;
    apiData.note = formState.note;
    apiData.carId = ClickedRowCarID;//formState.carId;//   apiData.masseurId = formState.masseurId;
    //ok console.log('DEBUG AddhourModal handleOK apiData.carId: ', apiData.carId );
    console.log('DEBUG AddhourModal handleOK apiData: ', apiData);
    apiData.receptionistId = formState.receptionistId;
    apiData.customerId = formState.customer?.id;
//    apiData.roomId = formState.room;
    apiData.companyId = sessionCheckedCompany;//getSessionCheckedCompany();

    handleClose();
    dispatch(addHourEventAsync(apiData));
  }

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <EnhancedDialogTitle handleClose={handleClose} nameLabel="Přidat událost" />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <EnhancedTextField
                name="title"
                value={formState.title || ''}
                label="Název"
                onChange={handleChange}
              />
            </Grid>
            <HMCustomerSection
              formState={formState}
              setFormState={setFormState}
              handleChange={handleChange}
            />{/*
            <Grid item xs={4}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-rec-native-simple">Masérna</InputLabel>
                <Select
                  disabled={true}
                  native
                  value={formState.room || ""}
                  label="Masérna"
                  onChange={handleChange}
                  inputProps={{
                    name: "room",
                    id: "outlined-receptionist"
                  }}>
                  <option aria-label="None" value="" />
                  {roomList.map((r) => (
                    <option key={r.id} value={r.id}>
                      {r.name}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>*/}
            <Grid item xs={4}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-rec-native-simple">Recepční</InputLabel>
                <Select
                  native
                  value={formState.receptionistId || ""}
                  label="Recepční"
                  onChange={handleChange}
                  inputProps={{
                    name: "receptionistId",
                    id: "outlined-receptionist"
                  }}>
                  <option aria-label="None" value="" />
                  {receptionistList.map((r) => (
                    <option key={r.id} value={r.id}>
                      {r.fullName}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="outlined-mas-native-simple">Vozidlo: {formState.carId}</InputLabel>
                <Select
                  native
                  value={formState.carId || ClickedRowCarID}
                  label="Automobil"
                  onChange={handleChange}
                  inputProps={{
                    name: "carId",
                    id: "outlined-car"
                  }}>
                  <option aria-label="None" value="" />
                  {carList.map((m) => (
                    <option key={m.id} value={m.id}>
                      {m.nazev}
                    </option>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <HMStartEndSection
              formState={formState}
              setFormState={setFormState}
              handleChange={handleChange}
              setStart={setStart}
              setEnd={setEnd}
              open={open}
              date={date}
            />
            <Grid item xs={12}>
              <EnhancedTextField name="note" value={formState.note} label="Poznámka" onChange={handleChange} />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleOk} color="primary" variant="contained" className={classes.ok}>
            Potvrdit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
/*
                    id: "outlined-car"
                    id: "outlined-masseur"}

*/

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  formControl: {
    width: "100%"
  },
  ok: {
    color: "white",
    background: "green",
    "&:hover": {
      color: "white",
      background: "#006400"
    }
  },
  pay: {
    color: "white",
    background: "blue",
    "&:hover": {
      color: "white",
      background: "darkblue"
    }
  },
  apply: {
    color: "white",
    background: "#ff6600",
    "&:hover": {
      color: "white",
      background: "#cc5200"
    }
  },
  cancel: {
    color: "black",
    background: "#D3D3D3",
    "&:hover": {
      color: "black",
      background: "#BEBEBE"
    }
  },
  delete: {
    color: "white",
    background: "red",
    "&:hover": {
      color: "white",
      background: "#B22222"
    }
  }
}));

export default AddHourModal;
