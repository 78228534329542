import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import App from "src/app/App";
import store from "src/app/configureStore";
import * as serviceWorker from "src/serviceWorker";

render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
