import { FormControl, InputLabel, Select } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

function EnhancedSelect(props) {
  const classes = useStyles();
  const { name, value, onChange, label, list, itemId = "id", itemName = "name", ...rest } = props;

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel htmlFor={`outlined-${name}`}>{label}</InputLabel>
      <Select
        onChange={onChange}
        native
        value={value || ""}
        label={label}
        inputProps={{
          name: name,
          id: "outlined-role"
        }}
        {...rest}>
        <option aria-label="None" value="" />
        {list.map((l) => (
          <option key={l[itemId]} value={l[itemId]}>
            {l[itemName]}
          </option>
        ))}
      </Select>
    </FormControl>
  );
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%"
  }
}));
export default EnhancedSelect;
