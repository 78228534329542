import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import React from "react";

function EnhancedCloseButton(props) {
  const classes = useStyles();
  const { handleClose, disabledCancel = false } = props;

  return (
    <>
      <Button
        onClick={handleClose}
        color="secondary"
        variant="contained"
        disabled={disabledCancel}
        className={classes.cancel}>
        Zavřít
      </Button>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  cancel: {
    color: "black",
    background: "#D3D3D3",
    "&:hover": {
      color: "black",
      background: "#BEBEBE"
    }
  }
}));

export default EnhancedCloseButton;
