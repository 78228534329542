import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import EnhancedConfirmButton from "src/components/enhanced/EnhancedConfirmButton";
import EnhancedTextField from "src/components/enhanced/EnhancedTextField";
import EnhancedDialogTitle from "src/components/enhanced/EnhancedDialogTitle";
import {editCustomer} from "src/features/modalEditSlice";
import {useDispatch} from "react-redux";

function CustomerEditDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { open, setOpen, customer } = props;
  const [formState, setFormState] = useState({});
  useEffect(() => {
    setFormState((formState) => ({
      ...formState,
      firstName: customer?.firstName,
      lastName: customer?.lastName,
      phone: customer?.phone
    }));
  }, [customer]);

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = () => {
    const apiData = {};
    apiData.id = customer.id;
    apiData.firstName = formState.firstName;
    apiData.lastName = formState.lastName;
    apiData.phone = formState.phone;

    dispatch(editCustomer(apiData));
    handleClose();
  };

  return (
      <div className={classes.root}>
        <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md" aria-labelledby="form-dialog-title">
          <EnhancedDialogTitle handleClose={handleClose} nameLabel="Úprava zákazníka" />
          <DialogContent>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <EnhancedTextField
                    name="firstName"
                    value={formState.firstName}
                    label="Jméno"
                    onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                    name="lastName"
                    value={formState.lastName}
                    label="Příjmení"
                    onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <EnhancedTextField
                    name="phone"
                    value={formState.phone}
                    label="Telefon"
                    onChange={handleChange}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <EnhancedConfirmButton handleOk={handleOk} okLabel="Uložit" />
          </DialogActions>
        </Dialog>
      </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  }
}));

export default CustomerEditDialog;
