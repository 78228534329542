import { FormControl, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";

function EnhancedDateField(props) {
  const classes = useStyles();
  const { name, label, disabled, value, handleChange, ...rest } = props;

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <TextField
        name={name}
        InputLabelProps={{
          shrink: true,
          name: name,
          id: `outlined-${name}`
        }}
        value={value || ""}
        onChange={handleChange}
        label={label}
        variant="outlined"
        id="date"
        type="date"
        disabled={disabled}
        {...rest}
      />
    </FormControl>
  );
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%"
  }
}));
export default EnhancedDateField;
