import "@fortawesome/fontawesome-free/css/all.css";
import { FormControl, Grid, InputAdornment, InputLabel, OutlinedInput } from "@material-ui/core";
import {  Search } from "@mui/icons-material";//@material-ui/icons";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchFilteredCustomers } from "src/features/modalEditSlice";
import EnhancedIconButton from "src/components/enhanced/EnhancedIconButton";

const CustomerSearchForm = (props) => {
  const dispatch = useDispatch();

  const { setCustomerView, customerViewConstants, className } = props;

  const [searchValue, setSearchValue] = useState("");

  const handleSearch = () => {
    dispatch(fetchFilteredCustomers(searchValue));
    setCustomerView(customerViewConstants.TABLE_VIEW);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") handleSearch();
  };

  return (
    <Grid item className={className}>
      <Grid item xs={6}>
        <FormControl onKeyDown={(e) => handleKeyDown(e)} fullWidth variant="outlined">
          <InputLabel htmlFor="outlined-adornment-amount">Search</InputLabel>
          <OutlinedInput
            id="outlined-adornment-amount"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            startAdornment={
              <InputAdornment position="start">
                <EnhancedIconButton ariaLabel="back icon" tooltip="Zpět" Icon={Search} onClick={handleSearch} />
              </InputAdornment>
            }
            labelWidth={60}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default CustomerSearchForm;
