import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { editVoucher } from "src/features/voucherSlice";
import { exportBillPdf } from "src/features/paymentSlice";
import EnhancedConfirmButton from "src/components/enhanced/EnhancedConfirmButton";
import EnhancedTextField from "src/components/enhanced/EnhancedTextField";
import EnhancedDialogTitle from "../enhanced/EnhancedDialogTitle";
import EnhancedDateField from "src/components/enhanced/EnhancedDateField";

function VoucherEditDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { open, setOpen, voucher } = props;
  const [formState, setFormState] = useState({});
  useEffect(() => {
    setFormState((formState) => ({
      ...formState,
      title: voucher?.title,
      number: voucher?.number,
      dateExpire: voucher?.dateExpire,
      billUUID: voucher?.billUUID,
      showExportBillButton: voucher?.showExportBillButton
    }));
  }, [voucher]);

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOk = () => {
    const apiData = {};
    apiData.id = voucher.id;
    apiData.title = formState.title;
    apiData.voucherNumber = formState.number;
    apiData.dateExpire = formState.dateExpire;
    dispatch(editVoucher(apiData));
    handleClose();
  };

  function handleExportBill() {
    if (formState.billUUID) {
      dispatch(exportBillPdf(formState.billUUID));
    }
  }

  function isExportBillDisabled() {
    return !formState.showExportBillButton;
  }

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md" aria-labelledby="form-dialog-title">
        <EnhancedDialogTitle handleClose={handleClose} nameLabel="Úprava dárkového poukazu" />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <EnhancedTextField name="title" value={formState.title} label="Jméno" onChange={handleChange} />
            </Grid>
            <Grid item xs={6}>
              <EnhancedTextField
                name="number"
                value={formState.number}
                label="Číslo poukazu"
                onChange={handleChange}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <EnhancedDateField
                  name="dateExpire"
                  value={formState.dateExpire}
                  label="Platnost do"
                  handleChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <EnhancedConfirmButton handleClose={handleClose} handleOk={handleOk} okLabel="Uložit" />
          <Button
              onClick={handleExportBill}
              color="primary"
              variant="contained"
              className={classes.exportBill}
              disabled={isExportBillDisabled()}
          >
            Účtenka
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  exportBill: {
    color: "white",
    background: "#33cccc",
    "&:hover": {
      color: "white",
      background: "#29a3a3"
    }
  }
}));

export default VoucherEditDialog;
