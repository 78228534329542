import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EnhancedConfirmButton from "src/components/enhanced/EnhancedConfirmButton";
import EnhancedTextField from "src/components/enhanced/EnhancedTextField";
import EnhancedDialogTitle from "../enhanced/EnhancedDialogTitle";
import alertHelper from "src/helpers/alertHelper";
import { editCompany, insertCompany } from "src/features/companySlice";

function CompanyDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

//  useEffect(() => {
  //  dispatch(fetchCarTypes());
  //  dispatch(fetchCompanyList());
//  }, [dispatch]);  

  const { company, openEdit, setOpenEdit, openNew, setOpenNew} = props; 

  const [formState, setFormState] = useState({});
  useEffect(() => {
  if (openEdit)
      setFormState((formState) => ({
        ...formState,
        name: company?.name,
        address: company?.address,
        phone: company?.phone,
        webSite: company?.webSite,
        email: company?.email,
        fileNumber: company?.fileNumber,
        short: company?.short
      }));
  }, [company, openEdit]);

  const handleChange = (event) => { 
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  }

  const handleClose = () => { 
	if (openEdit) { //Edit) {
  	  setOpenEdit(false);
          //setFormState({});      
	}
	else
	{
  	  setOpenNew(false);
          //setFormState({});
	}
	setFormState({});
  }

  const handleOk = () => {//handleClose();
	if (formState.name == "" || typeof(formState.name) == 'undefined') {
	      alertHelper.error({ message: "Název musí být definován." });
	      return false;
	}

    const apiData = {
      name: formState.name,
      address: formState.address,
      phone: formState.phone,
      webSite: formState.webSite,
      email: formState.email,
      fileNumber: formState.fileNumber,
      short: formState.short
    };

    if (openEdit) {
      apiData.id = company.id;

      console.log("DEBUG if (openEdit) , apiData: ", apiData);

      dispatch(editCompany(apiData));
    } else {
     // apiData.companyId = getSessionCheckedCompany();
	//apiData.barva = color;
 //     console.log("DEBUG if !(openEdit) , apiData: ", apiData);
      dispatch(insertCompany(apiData));// dispatch(insertCompany(apiData));
    }

    console.log("DEBUG handleOk apiData: ", apiData);
    handleClose();
  }

//                 disabled={false}
  return (
    <div className={classes.root}>
      <Dialog open={openEdit || openNew} onClose={handleClose} fullWidth={true} maxWidth="md" aria-labelledby="form-dialog-title">
        <EnhancedDialogTitle handleClose={handleClose} nameLabel="Organizace" />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <EnhancedTextField
                name="name"
                value={formState.name}
                label="Název"
                onChange={handleChange}

              />
            </Grid>
            <Grid item xs={6}>
              <EnhancedTextField
                name="address"
                value={formState.address}
                label="Adresa"
                onChange={handleChange}
                disabled={false}
              />
            </Grid>
            <Grid item xs={6}>
              <EnhancedTextField
                name="phone"
                value={formState.phone}
                label="Telefon"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <EnhancedTextField
                name="webSite"
                value={formState.webSite}
                label="WWW"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <EnhancedTextField
                name="email"
                value={formState.email}
                label="Email"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <EnhancedTextField
                name="fileNumber"
                value={formState.fileNumber}
                label="Spisová značka"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <EnhancedTextField
                name="short"
                value={formState.short}
                label="Zkratka"
                onChange={handleChange}
              />
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <EnhancedConfirmButton handleClose={handleClose} handleOk={handleOk} />
        </DialogActions>
      </Dialog>
    </div>
  );
}
//orig.: <EnhancedConfirmButton handleOk={handleOk} hideOk />

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  }
}));

export default CompanyDialog;
