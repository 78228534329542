import { Toolbar, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Add } from "@mui/icons-material";//@material-ui/icons";
import React from "react";
import EnhancedIconButton from "src/components/enhanced/EnhancedIconButton";

function EnhancedTableToolbar(props) {
  const classes = useToolbarStyles();
  const { setOpenNew, label, addible, authority } = props;

  return (
    <Toolbar className={classes.root}>
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        {label}
      </Typography>
      <div className={addible ? "" : classes.dNone}>
        <EnhancedIconButton
          authority={authority}
          ariaLabel="add item"
          tooltip="Přidat"
          Icon={Add}
          onClick={() => setOpenNew(true)}
        />
      </div>
    </Toolbar>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  title: {
    flex: "1 1 100%"
  },
  dNone: {
    display: "none"
  }
}));

export default EnhancedTableToolbar;
