import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import React from "react";

function EnhancedConfirmButton(props) {
  const classes = useStyles();
  const { handleOk, okLabel = "Potvrdit", hideOk, disabledOk = false } = props;

  return (
    <>
      <Button
        onClick={handleOk}
        color="primary"
        variant="contained"
        disabled={disabledOk}
        className={`${classes.ok} ${hideOk ? classes.dNone : ""}`}>
        {okLabel}
      </Button>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  ok: {
    color: "white",
    background: "green",
    "&:hover": {
      color: "white",
      background: "#006400"
    }
  },
  dNone: {
    display: "none"
  }
}));

export default EnhancedConfirmButton;
