import FullCalendar from "@fullcalendar/react"; // FullCalendar have to be imported first
import csLocale from "@fullcalendar/core/locales/cs";
import listPlugin from "@fullcalendar/list";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCleanerEvents } from "src/features/reservationSlice";
import EnhancedTableToolbar from "../enhanced/EnhancedTableToolbar";
import {getSessionCompanyId} from "../../helpers/sessionHelper";

function CleanerAgendaList() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const calendar = useSelector((state) => state.reservation.calendar);

  const [calendarEvents, setCalendarEvents] = useState(calendar);
  useEffect(() => {
    setCalendarEvents(calendar);
  }, [calendar]);

  useEffect(() => {
      getSessionCompanyId() && dispatch(fetchCleanerEvents());
  }, [dispatch]);

  return (
    <div className={classes.root}>
        <EnhancedTableToolbar label="Kalendář" />
      <FullCalendar
        plugins={[listPlugin]}
        headerToolbar={{
          left: "title",
          center: "",
          right: "today prev,next"
        }}
        initialView="listWeek"
        events={calendarEvents}
        expandRows={true}
        locale={csLocale}
      />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    fontFamily: "Roboto, Helvetica, Arial, sans-serif !important"
  }
}));

export default CleanerAgendaList;
