import React from "react";
import { Route } from "react-router-dom";

function RouteCustom(props) {
  const { component: Component, layout: Layout, ...rest } = props;
  return (
    <Layout>
      <Route component={Component} {...rest} />
    </Layout>
  );
}

export default RouteCustom;
