import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import {getExportFetchURL, getFetchURL} from "src/helpers/actionHelpers";
import { getSessionCompanyId } from "src/helpers/sessionHelper";

const initialState = {
  passLogList: [],
  passLogHistoryList: []
};

export const fetchPassLogHistory = createAsyncThunk("passLog/fetchPassLogHistory", async (passLogId, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/reservation/pass-log-history/${passLogId}`));
  return response.data;
});

export const fetchPassLog = createAsyncThunk("passLog/fetchPassLog", async (_, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/pass-log/company/get/${getSessionCompanyId()}`));
  return response.data;
});

export const insertPassLog = createAsyncThunk("passLog/insertPassLog", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/pass-log/add")
  };

  const response = await axios(options).then((_) => {
    dispatch(fetchPassLog());
  });

  return response.data;
});

export const deletePassLog = createAsyncThunk("passLog/deletePassLog", async (id, { dispatch }) => {
  const options = {
    method: "delete",
    data: { id: id },
    url: getFetchURL("/pass-log/delete")
  };

  const response = await axios(options).then((_) => {
    dispatch(fetchPassLog());
  });

  return response.data;
});

export const editPassLog = createAsyncThunk("passLog/editPassLog", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/pass-log/edit")
  };

  const response = await axios(options).then((_) => {
    dispatch(fetchPassLog());
  });

  return response.data;
});

export function exportPassLogPdf(passLogId) {
  window.open(getExportFetchURL(`/pdf-file/pass-log/${passLogId}`));
}

export const passLogSlice = createSlice({
  name: "passLog",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPassLog.fulfilled, (state, action) => {
        state.passLogList = action.payload;
      })
      .addCase(fetchPassLogHistory.fulfilled, (state, action) => {
        state.passLogHistoryList = action.payload;
      });
  }
});

export default passLogSlice.reducer;
