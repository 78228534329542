import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { fetchEventsAsync } from "src/features/reservationSlice";
import { getFetchURL } from "src/helpers/actionHelpers";

const initialState = { dataById: {} };

export const fetchDailyNoteBy = createAsyncThunk("dailyNote/fetchDailyNoteBy", async (id, { dispatch }) => {
  const response = await axios.get(getFetchURL(`/daily-note/get/${id}`));

  return response.data;
});

export const addDailyNote = createAsyncThunk("dailyNote/addDailyNote", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/daily-note/add")
  };

  const response = await axios(options).then((_) => {
    dispatch(fetchEventsAsync());
  });

  return response.data;
});

export const editDailyNote = createAsyncThunk("dailyNote/editDailyNote", async (apiData, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/daily-note/edit")
  };

  const response = await axios(options).then((_) => {
    dispatch(fetchEventsAsync());
  });

  return response.data;
});

export const deleteDailyNote = createAsyncThunk("dailyNote/deleteDailyNote", async (id, { dispatch }) => {
  const options = {
    method: "DELETE",
    data: { id: id },
    url: getFetchURL("/daily-note/delete")
  };

  const response = await axios(options).then((_) => {
    dispatch(fetchEventsAsync());
  });

  return response.data;
});

export const dailyNoteSlice = createSlice({
  name: "dailyNote",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchDailyNoteBy.fulfilled, (state, action) => {
      const id = action.meta.arg;
      state.dataById[id] = action.payload;
    });
  }
});

export default dailyNoteSlice.reducer;
