import { Paper, TableCell } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Edit, Home, Settings } from "@mui/icons-material";//@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EnhancedIconButton from "src/components/enhanced/EnhancedIconButton";
import EnhancedTable from "src/components/enhanced/EnhancedTable";
import EnhancedTableToolbar from "src/components/enhanced/EnhancedTableToolbar";
//import CarCompanyDialog from "src/components/car/CarCompanyDialog";
import CarDialog from "src/components/car/CarDialog";
//import CarSpecialEditDialog from "src/components/car/CarSpecialEditDialog";
//import { fetchUserListByCompanyId, fetchUserProfileTypes } from "src/features/usersSlice";
import {  /*fetchUserProfileTypes,*/ fetchCarListByCompanyId, fetchCars } from "src/features/carSlice";
//import {  /*fetchUserProfileTypes,*/ /*fetchCarListByCompanyId,*/ fetchCars, fetchVoz } from "src/features/carsSlice";
//import {  fetchVozidla } from "src/features/vozidlaSlice";
//import { fetchAclRoles, fetchCars } from "src/features/listsSlice";

import { createMuiTheme } from "@material-ui/core"; // deprecated !!! TODO
import { ThemeProvider } from "@material-ui/styles";
//import { ThemeProvider, createTheme } from "@material-ui/styles";
//import { createTheme } from '@material-ui/styles'; err
//import { createTheme } from '@material-ui/core/styles';
//import { createTheme } from '@material-ui/core';
import Button from "@material-ui/core/Button";

import { ColorPicker } from "material-ui-color";
//import { ColorPicker } from 'material-ui-color';

import paletaBarev from "src/components/car/CarDialog";




const themeCarColor = createMuiTheme({
//const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#ff4400',
	red: '#f00',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      light: '#0066ff',
      main: '#0044ff',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
});

//export default function PaletteCarColor() {
function PaletteCarColor() {
  return (
    <ThemeProvider theme={themeCarColor}>
      <Button variant="contained" color="primary">TEST Barva</Button>
    </ThemeProvider>
  );
}


function CarTable() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [openSpecialEdit, setOpenSpecialEdit] = useState(false);
  const [openCompanyCar, setOpenCompanyCar] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedCar, setSelectedCar] = useState({});

  useEffect(() => {
//    dispatch(fetchCarListByCompanyId());
	dispatch(fetchCars());//fetchUserListByCompanyId());
//	themeCarColor.palette.primary.main = '#0f0';
//    dispatch(fetchVoz());
 //   dispatch(fetchVozidla());
//    dispatch(fetchUserProfileTypes());

	
  }, [dispatch]);

  
 // const car = useSelector((state) => state.car);
//  const cars = useSelector((state) => state.cars);
//err  const vozidla = useSelector((state) => state.vozidla.vozidlaList);
//  const statee = useSelector((state) => state);
// const carList = useSelector((state) => state.lists.carList);
//err const carList = useSelector((state) => state.cars.carList);
// console.log('DEBUG car: ', car);
//  console.log('DEBUG cars: ', cars);
  //console.log('DEBUG vozidla: ', vozidla);
//  console.log('DEBUG statee: ', statee);
//err   console.log('DEBUG Car.jsx state: ', state);
   //function console.log('DEBUG Car.jsx dispatch: ', dispatch);

  const carList = useSelector((state) => state.car.carList);
//  const carList = useSelector((state) => state.car.carList);
// const carList = useSelector((state) => state.lists.carList);
  console.log('DEBUG Car.jsx carList: ', carList); // po zmene barvy spravna barva ale neaktualizuje se

  const headerList = [
    { align: "left", label: "" },
    { align: "right", label: "Název" },
    { align: "right", label: "SPZ" },
    { align: "right", label: "Typ" },
    { align: "right", label: "Barva" }
  ];

 /* const colorList = [
   // { "#000", "#f00", "#0f0" }
 { 1:"#000"}, {2:"#f00"}, {3:"#0f0" }
 //{ i:"#000"}, {i:"#f00"}, {i:"#0f0" }
 //{ "#000"}, {"#f00"}, {"#0f0" }
  ]
;

const colorList2 = ["#000", "#f00", "#0f0"];*/

  //const carList2 = [{id : 1, name: "n", nazev: "na"}];

  function handleEdit(car) {
    setSelectedCar(car);
    setOpenEdit(true);
  }

  function handleSpecialEdit(car) {
    setSelectedCar(car);
    setOpenSpecialEdit(true);
  }

  function handleCompanyCar(car) {
    setSelectedCar(car);
    setOpenCompanyCar(true);
  }


  const renderRowCells = (car) => (
    <>
      <TableCell>
        <EnhancedIconButton
          authority="car.edit"
          ariaLabel="edit row"
          size="small"
          tooltip="Upravit"
          Icon={Edit}
          onClick={() => handleEdit(car)}
        />
{/*        <EnhancedIconButton
          authority="car.edit"
          ariaLabel="profile row"
          size="small"
          tooltip="Nastavení"
          Icon={Settings}
          onClick={() => handleSpecialEdit(car)}
        />
        <EnhancedIconButton
          authority="car.all"
          ariaLabel="organization row"
          size="small"
          tooltip="Nastavení organizace"
          Icon={Home}
          onClick={() => handleCompanyCar(car)}
        />*/}
      </TableCell>
      <TableCell align="right"> {car["nazev"]} </TableCell>
      <TableCell align="right">{car["spz"]}</TableCell>
      <TableCell align="right">{car["typ_vozidla"]["nazev"]}</TableCell>
      {/*<TableCell align="right">{<Button variant="contained" color="secondary">  Barvaaaa 	</Button>    }</TableCell>*/}

      {/*<TableCell align="right">{PaletteCarColor()}</TableCell>*/}
      {/*<TableCell align="right">{<ColorPicker defaultValue={car["barva"]}/>}</TableCell>*/}
      <TableCell align="right">{<ColorPicker value={car["barva"]}/>}</TableCell>
	{/* defaultValue="#fe0"     defaultValue={colorList[2][3]}    colorList2[1]*/}

	{/* Background="red" app:backgroundTint="red" color="red" */}
	{/*<TableCell align="right">DEBUG:  {car["barva"]}</TableCell>*/}
    </>
  );

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar authority="car.edit" label="Vozidla" setOpenNew={setOpenNew} addible />
        <EnhancedTable headerList={headerList} dataList={carList} renderRowCells={renderRowCells} />
      </Paper>
      
      <CarDialog
        car={selectedCar}
        openNew={openNew}
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        setOpenNew={setOpenNew}
      />
      
    </div>
  );
}
// <CarSpecialEditDialog car={selectedCar} open={openSpecialEdit} setOpen={setOpenSpecialEdit} />
// <CarCompanyDialog car={selectedCar} open={openCompanyCar} setOpen={setOpenCompanyCar} />

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  }
}));

export default CarTable;
