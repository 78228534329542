import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { checkFilteredPayments, fetchFilteredPayments } from "src/features/paymentSlice";
import { fetchPaymentTypes, fetchMassagePrices } from "src/features/paymentSlice";
import EnhancedSelect from "src/components/enhanced/EnhancedSelect";
import EnhancedTextField from "src/components/enhanced/EnhancedTextField";
import alertHelper from "src/helpers/alertHelper";
import { Check } from "@mui/icons-material";//@material-ui/icons";
import EnhancedButton from "src/components/enhanced/EnhancedButton";
import PaymentTypeResolver from "src/helpers/paymentTypeResolver";

function HMPaymentSection(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const dataById = useSelector((state) => state.reservation.dataById);
  const paymentObj = useSelector((state) => state.payment.paymentObj);
  const validation = useSelector((state) => state.payment.validation);
  const voucher = useSelector((state) => state.applyTicket.voucher);
  const paymentTypeList = useSelector((state) => state.payment.paymentTypeList);
  const filteredPaymentsList = useSelector((state) => state.payment.filteredPaymentsList);
  const typePriceOfMassageList = useSelector((state) => state.payment.typePriceOfMassageList); // tabulka massage_type

//  const [carId, setCarId] = useState("12");

  //console.log("DEBUG carId: ", carId);
  console.log("DEBUG typePriceOfMassageList: ", typePriceOfMassageList);

  const { selected, setFormState, open, formState, carId } = props;
  //undef. console.log("DEBUG carId: ", carId);
   //undef. console.log("DEBUG {carId}: ", {carId});

  //console.log("DEBUG selected: ", selected);
  //er setCarId('1');

  // pay a reservations without passlog/voucher
  useEffect(() => {
    paymentObj &&
      setFormState((formState) => ({
        ...formState,
        paymentId: paymentObj?.id,
        paymentTypeId: paymentObj?.paymentType?.id,
        paymentTypeLabel: paymentObj?.paymentType?.label,
        jednotkovaCenaLabel: paymentObj?.massageType?.name,// typeOfMassageLabel: paymentObj?.massageType?.name,
        voucherNumber: paymentObj?.voucherNumber,
        jednotkovaCenaId: paymentObj?.massageType?.id,// typeOfMassageId: paymentObj?.massageType?.id,
        massageTypeShort: paymentObj?.massageType?.short,
        billUUID: paymentObj?.billUUID,
        invoiceAddress: paymentObj?.invoiceAddress,
        voucherApplied: paymentObj?.applied
      }));
      //setCarId('11');

  }, [paymentObj, setFormState]);

  useEffect(() => {
    setFormState((formState) => ({
      ...formState,
      voucherApplied: (voucher?.reservation?.id && voucher?.passLog?.id) || voucher?.dateUsed
    }));
  }, [setFormState, voucher]);

  useEffect(() => {
    if (open && !formState.paymentId) {
      dispatch(fetchPaymentTypes());
      formState.paymentTypeId && dispatch(fetchMassagePrices(formState.paymentTypeId));
    }
  }, [open, formState.jednotkovaCenaId/*typeOfMassageId*/, formState.paymentTypeId, formState.paymentId, dispatch]);

  useEffect(() => {
    if (filteredPaymentsList?.length === 1) {
      const payment = filteredPaymentsList[0];
      if (payment?.applied) {
        alertHelper.error({
          message: "Tento dárkový poukaz / permanentka nelze použít."
        });
      } else if (payment?.afterExpire) {
        alertHelper.error({
          message: "Tento dárkový poukaz / permanentka již není platná."
        });
      } else {
        setFormState((formState) => ({
          ...formState,
          paymentId: payment?.id,
          paymentTypeId: payment?.paymentType?.id,
          paymentTypeLabel: payment?.paymentType?.label,
          jednotkovaCenaLabel: payment?.massageType?.name,// typeOfMassageLabel: payment?.massageType?.name,
          billUUID: payment?.billUUID,
          jednotkovaCenaId: payment?.massageType?.id,// typeOfMassageId: payment?.massageType?.id,
          massageTypeShort: payment?.massageType?.short,
          voucherNumber: payment?.voucherNumber,
          title: payment?.massageType?.short + " - " + formState.title,
          invoiceAddress: payment?.invoiceAddress,
          voucherApplied: payment?.applied
        }));
      }
    }
  }, [filteredPaymentsList, setFormState]);

  useEffect(() => {
    //sel. ok console.log("DEBUG carId, selected: ", carId, selected);
    console.log("DEBUG carId : ", carId);
    console.log("DEBUG [carId]: ", [carId]);
    let nCarId = carId;
    let nCarId2 = [carId];
    let nCarId3 = {carId};
    console.log("DEBUG nCarId, nCarId2, nCarId3: ", nCarId, nCarId2, nCarId3);
  }, [carId]);

  useEffect(() => {
    let payment = dataById?.[selected]?.payment;

    setFormState((formState) => ({
      ...formState,
      paymentId: payment?.id,
      paymentTypeId: payment?.paymentType?.id,
      paymentTypeLabel: payment?.paymentType?.label,
      jednotkovaCenaLabel: payment?.massageType?.name,// typeOfMassageLabel: payment?.massageType?.name,
      voucherNumber: payment?.voucherNumber,
      jednotkovaCenaId: payment?.massageType?.id,// typeOfMassageId: payment?.massageType?.id,
      massageTypeShort: payment?.massageType?.short,
      billUUID: payment?.billUUID,
      invoiceAddress: payment?.invoiceAddress,
      voucherApplied: payment?.applied,
    }));

/*nejde    console.log("DEBUG carId, selected: ", carId, selected);
    console.log("DEBUG [carId]: ", [carId]);
    let nCarId = carId;
    let nCarId2 = [carId];
    let nCarId3 = {carId};
    console.log("DEBUG nCarId, nCarId2, nCarId3: ", nCarId, nCarId2, nCarId3);*/

  }, [dataById, setFormState, selected, carId]);

    console.log("DEBUG carId: ", carId);//, selected);
    console.log("DEBUG [carId]: ", [carId]);
    let nCarId = carId;
    let nCarId2 = [carId];
    let nCarId3 = {carId};
    console.log("DEBUG nCarId, nCarId2, nCarId3: ", nCarId, nCarId2, nCarId3);

  function handleChange(event) {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));

    if (event.target.name === "jednotkovaCenaId") { // "typeOfMassageId") {
      const massageTypeObj = typePriceOfMassageList.find((p) => p.id === +event.target.value);
      if (massageTypeObj) {
        // check for undefined massageTypeObj
        setFormState((formState) => ({
          ...formState,
          title: `${massageTypeObj.short} - ${formState.title}`
		,jednotkovaCena: `${massageTypeObj.name}`
        }));
        console.log("DEBUG massageTypeObj.short: ", massageTypeObj.short, ", formState.title: ", formState.title, ", massageTypeObj: ", massageTypeObj);

	console.log("DEBUG formState.jednotkovaCenaLabel: ", formState.jednotkovaCenaLabel);
	console.log("DEBUG formState.jednotkovaCena: ", formState.jednotkovaCena);
	console.log("DEBUG formState: ", formState);
      }
    }

    if (event.target.name === "paymentTypeId") {
      if (isCashOrCreditCard(event.target.value)) {
        setFormState((formState) => ({
          ...formState,
          voucherNumber: null
        }));
      }
    }

    if (event.target.name === "voucherNumber" && event.target.value.length > 6) {
      dispatch(checkFilteredPayments(event.target.value));
    }
  }

  function isCashOrCreditCard(value)
  {
    return PaymentTypeResolver.isCash(value) || PaymentTypeResolver.isCreditCard(value)
  }

  function handleFind() {
    if (formState.voucherNumber) {
      dispatch(fetchFilteredPayments(formState.voucherNumber));
    }
  }

  let foundValidVoucherNumber = validation?.isVoucherNumberValid;

  function isVNFieldValid() {
    if (formState?.voucherNumber && formState?.voucherNumber.length > 0 && !foundValidVoucherNumber) {
      return true;
    }

    return false;
  }

  function isDisabledVoucherNumber()
  {
    return !!formState.paymentId || isCashOrCreditCard(formState.paymentTypeId);
  }

/*
      <Grid item xs={6}>
        <EnhancedTextField
          name="voucherNumber"
          value={formState.voucherNumber}
          label="Číslo poukazu"
          onChange={handleChange}
          className={`${foundValidVoucherNumber ? classes.voucherTextField : ""}`}
          error={isVNFieldValid()}
          disabled={isDisabledVoucherNumber()}
        />
      </Grid>
      <Grid item xs={2}>
        <EnhancedButton
            onClick={handleFind}
            className={classes.check}
            disabled={!!formState.paymentId}
            label={<Check />}
            authority="calendar.edit"
        />
      </Grid>
*/
  return (
    <>

      <Grid item xs={4}>
        <EnhancedTextField
          name="billUUID"
          value={formState.billUUID}
          label="Číslo dokladu"
          onChange={handleChange}
          disabled={!!formState.paymentId}
        />
      </Grid>
      <Grid item xs={12}>
        {formState.paymentId ? (
          <EnhancedTextField
            name="paymentTypeLabel"
            value={formState.paymentTypeLabel}
            label="Způsob platby"
            onChange={handleChange}
            disabled={true}
          />
        ) : (
          <EnhancedSelect
            name="paymentTypeId"
            value={formState.paymentTypeId}
            label="Způsob platby"
            list={paymentTypeList}
            onChange={handleChange}
            disabled={!!formState.paymentId}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        {formState.paymentId ? (
          <EnhancedTextField
            name="jednotkovaCenaLabel"
            value={formState.jednotkovaCenaLabel}
            label="Jednotková cena"
            onChange={handleChange}
            disabled={true}
          />
        ) : (
          <EnhancedSelect
            name="jednotkovaCenaId"
            value={formState.jednotkovaCenaId}
            label="Jednotková cena"
            list={typePriceOfMassageList}
            onChange={handleChange}
            disabled={!!formState.paymentId}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <EnhancedTextField
            name="invoiceAddress"
            value={formState.invoiceAddress}
            label="Fakturační adresa"
            onChange={handleChange}
            disabled={!!formState.paymentId}
        />
      </Grid>

      <Grid item xs={12}>
        <EnhancedTextField
            name="TEST"
            value={carId}
            label="label carId "
            onChange={handleChange}
            disabled={!!formState.paymentId}
        />
      </Grid>

    </>
  );
}

// label="Typ a cena masáže" "Jednotková cena"
// name="typeOfMassageLabel"jednotkovaCenaLabel
// value={formState.typeOfMassageLabel}

// name="typeOfMassageId"jednotkovaCenaId
// value={formState.typeOfMassageId}



const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%"
  },
  voucherTextField: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important" // override inline-style
    }
  },
  check: {
    color: "white",
    background: "#008ead",
    "&:hover": {
      color: "white",
      background: "#015e72"
    },
    height: 53,
    marginLeft: -15,
  }
}));

export default HMPaymentSection;
