import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { getExportFetchURL, getFetchURL } from "src/helpers/actionHelpers";
import { getSessionCompanyId } from "src/helpers/sessionHelper";
import {editHourEventAsync} from "./reservationSlice";

const initialState = {
  paymentTypeLimitedList: [],
  typePriceOfMassageList: [],
  filteredPaymentsList: [],
  paymentTypeList: [],
  paymentObj: {},
  validation: {}
};
/*
export const fetchMassagePrices = createAsyncThunk(
  "payment/fetchMassagePrices",
  async (paymentTypeId, { dispatch }) => {
    const response = await axios.get(getFetchURL(`/massage-type-price/get/${getSessionCompanyId()}/` + paymentTypeId));
	//console.log("DEBUG response.data: ", response.data);
	//  tabulka massage_type
    return response.data;
  }
);*/

export const fetchMassagePrices = createAsyncThunk(
  "payment/fetchMassagePrices",
  async (paymentTypeId, { dispatch }) => {
    const response = await axios.get(getFetchURL(`/massage-type-price/get/carId/` + paymentTypeId));
	//console.log("DEBUG response.data: ", response.data);
	//  tabulka massage_type
    return response.data;
  }
);

/*
export const fetchJednotkovaCena = createAsyncThunk(
  "payment/fetchJednotkovaCena",
  async (paymentTypeId, massage_type_id, { dispatch }) => {
    const response = await axios.get(getFetchURL(`/massage-price/get/${getSessionCompanyId()}/` + 
	paymentTypeId + `/` + massage_type_id));
	//console.log("DEBUG response.data: ", response.data);
	//  tabulka massage_price
	// massage_type_id: urcuje vozidlo_id
    return response.data;
  }
);
*/

export const fetchPassLogPrices = createAsyncThunk(
  "payment/fetchPassLogPrices",
  async (paymentTypeId, { dispatch }) => {
    const response = await axios.get(
      getFetchURL(`/massage-type-price/passlog/get/${getSessionCompanyId()}/` + paymentTypeId)
    );

    return response.data;
  }
);

export const fetchVoucherPrices = createAsyncThunk(
  "payment/fetchVoucherPrices",
  async (paymentTypeId, { dispatch }) => {
    const response = await axios.get(
      getFetchURL(`/massage-type-price/voucher/get/${getSessionCompanyId()}/` + paymentTypeId)
    );

    return response.data;
  }
);

export const fetchPaymentTypes = createAsyncThunk("payment/fetchPaymentTypes", async (_, { dispatch }) => {
  const response = await axios.get(getFetchURL("/payment-type/get"));
  return response.data;
});

export const fetchLimitedPaymentTypes = createAsyncThunk(
  "payment/fetchLimitedPaymentTypes",
  async (_, { dispatch }) => {
    const response = await axios.get(getFetchURL("/payment-type/get-limited"));
    return response.data;
  }
);

export const payBill = createAsyncThunk("payment/payBill", async ({ apiData, exportBill, reservationData }, { dispatch }) => {
  const options = {
    method: "PUT",
    data: apiData,
    headers: { "Content-Type": "application/json" },
    url: getFetchURL("/payment/add")
  };

  const response = await axios(options).then((r) => {
    if (exportBill) {
      exportBillPdf(r.data.billUUID);
    }

    if (reservationData) {
      reservationData.paymentId = r.data.id;
      dispatch(editHourEventAsync(reservationData));
    }

    return r;
  });

  return response.data;
});

export function exportBillPdf(billUUID) {
  window.open(getExportFetchURL(`/pdf-file/bill/${billUUID}`));
}

export const fetchFilteredPayments = createAsyncThunk(
  "payment/fetchFilteredPayments",
  async (filterText, { dispatch }) => {
    const response = await axios.get(getFetchURL(`/payment/find/${encodeURIComponent(filterText)}`));

    return response.data;
  }
);

export const checkFilteredPayments = createAsyncThunk(
  "calendar/checkFilteredPayments",
  async (filterText, { dispatch }) => {
    const response = await axios.get(getFetchURL(`/payment/find/${encodeURIComponent(filterText)}`));

    return response.data;
  }
);

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMassagePrices.fulfilled, (state, action) => {
        state.typePriceOfMassageList = action.payload;
      })
      .addCase(fetchPassLogPrices.fulfilled, (state, action) => {
        state.typePriceOfMassageList = action.payload;
      })
      .addCase(fetchVoucherPrices.fulfilled, (state, action) => {
        state.typePriceOfMassageList = action.payload;
      })
      .addCase(fetchLimitedPaymentTypes.fulfilled, (state, action) => {
        state.paymentTypeLimitedList = action.payload;
      })
      .addCase(payBill.fulfilled, (state, action) => {
        state.paymentObj = action.payload;
      })
      .addCase(fetchFilteredPayments.fulfilled, (state, action) => {
        state.filteredPaymentsList = action.payload;
      })
      .addCase(fetchPaymentTypes.fulfilled, (state, action) => {
        state.paymentTypeList = action.payload;
      })
      .addCase(checkFilteredPayments.fulfilled, (state, action) => {
        state.validation.isVoucherNumberValid = action.payload.length === 1;
      });
  }
});

export default paymentSlice.reducer;
