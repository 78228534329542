import { Paper, TableCell } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EnhancedTable from "src/components/enhanced/EnhancedTable";
import EnhancedTableToolbar from "src/components/enhanced/EnhancedTableToolbar";
import { fetchCompanySums } from "src/features/companySumSlice";
import NewCompanySumDialog from "src/components/companysum/NewCompanySumDialog";
import EnhancedIconButton from "../enhanced/EnhancedIconButton";
import { Edit } from "@mui/icons-material";//@material-ui/icons";
import EditCompanySumDialog from "./EditCompanySumDialog";

function CompanySumTable() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const companySumList = useSelector((state) => state.companySum.companySumList);

    const [openNew, setOpenNew] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [selectedCompanySum, setSelectedCompanySum] = useState({});

  useEffect(() => {
    dispatch(fetchCompanySums());
  }, [dispatch]);

    const handleEdit = (companySum) => {
        setSelectedCompanySum(companySum);
        setOpenEdit(true);
    };

  const headerList = [
    { align: "right", label: "" },
    { align: "right", label: "Organizace" },
    { align: "right", label: "Platnost za období" },
    { align: "right", label: "Celkem ze SumUpu" },
    { align: "right", label: "Počet uplatněných poukazů ze Slevomatu" },
    { align: "right", label: "Vytvořil/a" }
  ];

  const renderRowCells = (companySum) => (
    <>
      <TableCell align="right">
          <EnhancedIconButton
              authority="companysum.edit"
              ariaLabel="edit row"
              tooltip="Upravit"
              size="small"
              Icon={Edit}
              onClick={() => handleEdit(companySum)}
          />
      </TableCell>
      <TableCell align="right">{companySum?.company.name}</TableCell>
      <TableCell align="right">{companySum?.validityTextDate}</TableCell>
      <TableCell align="right">{companySum?.sumUpSum}</TableCell>
      <TableCell align="right">{companySum?.slevomatSum}</TableCell>
      <TableCell align="right">{companySum.createdBy}</TableCell>
    </>
  );

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar authority="companysum.edit" label="Sumarizace za organizaci" setOpenNew={setOpenNew} addible />
        <EnhancedTable headerList={headerList} dataList={companySumList} renderRowCells={renderRowCells} />
        <NewCompanySumDialog open={openNew} setOpenNew={setOpenNew} />
        <EditCompanySumDialog open={openEdit} setOpenEdit={setOpenEdit} companySum={selectedCompanySum} />
      </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  iconCell: {
    whiteSpace: "nowrap"
  }
}));

export default CompanySumTable;
