import { Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import Moment from "moment";

function Footer() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="body1">
        &copy;{" "}
        <Link component="a" href="https://....cz" target="_blank">
          ...
        </Link>{" "}
        2021 - {Moment().format("Y")}
      </Typography>
      <Typography variant="caption"></Typography>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  }
}));

export default Footer;
