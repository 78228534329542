import { AppBar, Toolbar } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { getSessionCompanyName } from "src/helpers/sessionHelper";

function Topbar() {
  const classes = useStyles();
  const headerLabel = getSessionCompanyName();

  return (
    <AppBar className={classes.root} color="primary" position="fixed">
      <Toolbar>
        <RouterLink to="/" className={classes.title}>
          <Typography variant="h1" component="h2" gutterBottom className={classes.title}>
            {headerLabel}
          </Typography>
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
}

Topbar.propTypes = {
  className: PropTypes.string
};

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: "none"
  },
  title: {
    color: "white",
    textDecoration: "none"
  }
}));

export default Topbar;
