import { Dialog, DialogActions, DialogContent, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editUser, insertUser } from "src/features/usersSlice";
import EnhancedConfirmButton from "src/components/enhanced/EnhancedConfirmButton";
import EnhancedSelect from "src/components/enhanced/EnhancedSelect";
import EnhancedTextField from "src/components/enhanced/EnhancedTextField";
import alertHelper from "src/helpers/alertHelper";
import { getSessionCheckedCompany } from "src/helpers/sessionHelper";
import EnhancedDialogTitle from "src/components/enhanced/EnhancedDialogTitle";

function UserDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const aclRoleList = useSelector((state) => state.lists.aclRoleList);
//console.log('DEBUG : aclRoleList: ', aclRoleList );

  const { user, openNew, setOpenNew, setOpenEdit, openEdit } = props;
  const [formState, setFormState] = useState({});

  useEffect(() => {
    if (openEdit)
      setFormState((formState) => ({
        ...formState,
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        phone: user?.phone,
        aclRoleId: user?.role?.id,
        active: user?.active
      }));
  }, [user, openEdit]);

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  };

  const handleClose = () => {
    if (openEdit) {
      setOpenEdit(false);
      setFormState({});
    } else {
      setOpenNew(false);
      setFormState({});
    }
  };

  const handleOk = () => {
    // phone validation
    const phoneFormat = /^[0-9\b]+$/;
    if (!phoneFormat.test(formState.phone)) {
      alertHelper.error({ message: "Telefoní číslo musí být číslo." });
      return false;
    }

    // email validator
    const emailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailFormat.test(String(formState.email).toLowerCase())) {
      alertHelper.error({ message: "Email není ve správném tvaru" });
      return false;
    }

    const apiData = {
      firstName: formState.firstName,
      lastName: formState.lastName,
      email: formState.email,
      phone: formState.phone,
      aclRoleId: formState.aclRoleId,
      password: formState.password,
      companyId: getSessionCheckedCompany()
    };

    if (openEdit) {
      apiData.id = user.id;
      apiData.active = formState.active;
      dispatch(editUser(apiData));
    } else {
      apiData.companyId = getSessionCheckedCompany();
      dispatch(insertUser(apiData));
    }
    handleClose();
  };

  return (
    <div className={classes.root}>
      <Dialog
        open={openNew || openEdit}
        onClose={handleClose}
        fullWidth={true}
        maxWidth="md"
        aria-labelledby="form-dialog-title">
        <EnhancedDialogTitle handleClose={handleClose} nameLabel="Uživatel" />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <EnhancedTextField name="firstName" value={formState.firstName} label="Jméno" onChange={handleChange} />
            </Grid>
            <Grid item xs={6}>
              <EnhancedTextField name="lastName" value={formState.lastName} label="Příjmení" onChange={handleChange} />
            </Grid>
            <Grid item xs={6}>
              <EnhancedTextField name="email" value={formState.email} label="E-mail" onChange={handleChange} />
            </Grid>
            <Grid item xs={6}>
              <EnhancedTextField name="phone" value={formState.phone} label="Telefon" onChange={handleChange} />
            </Grid>
            <Grid item xs={6}>
              <EnhancedSelect
                name="aclRoleId"
                value={formState.aclRoleId}
                label="Role"
                list={aclRoleList}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <EnhancedTextField
                name="password"
                value={formState.password}
                label="Heslo"
                onChange={handleChange}
                type="password"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <EnhancedSelect
                name="active"
                value={formState.active}
                label="Aktivní"
                list={[
                  { id: "true", name: "Aktivní" },
                  { id: "false", name: "Neaktivní" }
                ]}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <EnhancedConfirmButton handleClose={handleClose} handleOk={handleOk} />
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  }
}));

export default UserDialog;
