import { Paper, TableCell } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Delete, Edit, List, PictureAsPdf } from "@mui/icons-material";//@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EnhancedIconButton from "src/components/enhanced/EnhancedIconButton";
import EnhancedTable from "src/components/enhanced/EnhancedTable";
import EnhancedTableToolbar from "src/components/enhanced/EnhancedTableToolbar";
import NewPassLogDialog from "src/components/passlog/NewPasslogDialog";
import PassLogEditDialog from "src/components/passlog/PasslogEditDialog";
import PassLogHistoryDialog from "src/components/passlog/PasslogHistoryDialog";
import { deletePassLog, fetchPassLog, exportPassLogPdf } from "src/features/passLogSlice";
import alertHelper from "src/helpers/alertHelper";

function PassLogTable() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const passLogList = useSelector((state) => state.passLog.passLogList);

  const [open, setOpen] = useState(false);
  const [selectedPassLog, setSelectedPassLog] = useState({});
  const [openNew, setOpenNew] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);

  useEffect(() => {
    dispatch(fetchPassLog());
  }, [dispatch]);

  const headerList = [
    { align: "left", label: "" },
    { align: "right", label: "ID" },
    { align: "right", label: "Jméno" },
    { align: "right", label: "Číslo permanentky" },
    { align: "right", label: "Datum platnosti" },
    { align: "right", label: "Vytvořil/a" },
    { align: "right", label: "Vytvořeno dne" },
    { align: "right", label: "Číslo účtenky" },
    { align: "right", label: "Využito" },
    { align: "right", label: "Poznámka" },
  ];

  const handleEdit = (passlog) => {
    setSelectedPassLog(passlog);
    setOpen(true);
  };

  const handleDelete = (passlog) => {
    alertHelper.confirm({
      message: "Opravdu si přejete smazat permanentku?",
      callback: () => dispatch(deletePassLog(passlog.id))
    });
  };

  const showPassLogHistory = (passlog) => {
    setSelectedPassLog(passlog);
    setOpenHistory(true);
  };

    function handleExportPassLog(passlog) {
        if (passlog.id) {
            dispatch(exportPassLogPdf(passlog.id));
        }
    }

  const renderRowCells = (passlog) => (
    <>
      <TableCell className={classes.iconCell}>
        <EnhancedIconButton
          authority="passlog.view"
          ariaLabel="history row"
          tooltip="Historie návštev"
          size="small"
          Icon={List}
          onClick={() => showPassLogHistory(passlog)}
        />
        <EnhancedIconButton
          authority="passlog.edit"
          ariaLabel="pdf row"
          tooltip="Vygenerovat permanentku"
          size="small"
          Icon={PictureAsPdf}
          onClick={() => handleExportPassLog(passlog)}
        />
        <EnhancedIconButton
          authority="passlog.edit"
          ariaLabel="edit row"
          tooltip="Upravit"
          size="small"
          Icon={Edit}
          onClick={() => handleEdit(passlog)}
        />
        <EnhancedIconButton
          authority="passlog.delete"
          ariaLabel="remove row"
          tooltip="Smazat"
          size="small"
          Icon={Delete}
          onClick={() => handleDelete(passlog)}
        />
      </TableCell>
      <TableCell align="right">{passlog["id"]}</TableCell>
      <TableCell align="right">{passlog["title"]}</TableCell>
      <TableCell align="right">{passlog["number"]}</TableCell>
      <TableCell align="right">{passlog["afterExpire"] ? "Neplatný" : passlog["dateExpireText"]}</TableCell>
      <TableCell align="right">{passlog["createdBy"]}</TableCell>
      <TableCell align="right">{passlog["created"]}</TableCell>
      <TableCell align="right">{passlog["billUUID"]}</TableCell>
      <TableCell align="right">{passlog["used"]} / 11</TableCell>
      <TableCell align="right">{passlog["noteShort"]}</TableCell>
    </>
  );

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar authority="passlog.edit" label="Permanentky" setOpenNew={setOpenNew} addible />
        <EnhancedTable headerList={headerList} dataList={passLogList} renderRowCells={renderRowCells} />
      </Paper>
      <PassLogEditDialog open={open} passlog={selectedPassLog} setOpen={setOpen} />
      <NewPassLogDialog open={openNew} setOpenNew={setOpenNew} />
      <PassLogHistoryDialog open={openHistory} setOpenHistory={setOpenHistory} passlog={selectedPassLog} />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  iconCell: {
    whiteSpace: "nowrap"
  }
}));

export default PassLogTable;
