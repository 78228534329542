//import DateFnsUtils from "@date-io/date-fns";
import Grid from "@material-ui/core/Grid";
//import { DateTimePicker } from "@mui/x-date-pickers"; //import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import AdapterDateFns from '@mui/lab/AdapterDateFns';
//import LocalizationProvider from '@mui/lab/LocalizationProvider';
//import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers-pro";
import { TextField } from '@mui/material';

//import DatePicker from '@mui/lab/DatePicker'; //jina chyba

//import AdapterDateFns from '@date-io/date-fns';
//import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from "@material-ui/styles";
import "date-fns";
import csLocale from "date-fns/locale/cs";
import Moment from "moment";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import alertHelper from "../../../helpers/alertHelper";

function HMStartEndSection(props) {
  const classes = useStyles();

  const dataById = useSelector((state) => state.reservation.dataById);

  const { selected, setStart, setEnd, setFormState, formState, open, date, disabled } = props;

  useEffect(() => {
    if (open) {
      setStartEndAtWorkAfterChange(date);
	console.log('useEffect if (open), date: ', date);
    }
  }, [open, date]);

  useEffect(() => {
    if (open && dataById?.[selected]) {
      setFormState((formState) => ({
        ...formState,
        startAtWork: formatTime(dataById?.[selected]?.start),
        endAtWork: formatTime(dataById?.[selected]?.end)
      }));

      distributeValue(dataById?.[selected]?.start, dataById?.[selected]?.end);

    }
  }, [open, dataById, selected, setFormState]);

  function setStartEndAtWorkAfterChange(date) {
    let dateStr = new Moment(date);
    let dateStrHr = new Moment(date);
    dateStrHr.set({
      hour: dateStr.hour() + 1
    });
    setFormState((formState) => ({
      ...formState,
      startAtWork: dateStr,
      endAtWork: dateStrHr
    }));
	console.log("DEBUG setStartEndAtWorkAfterChange");
    distributeValue(dateStr, dateStrHr);
  }

  function formatTime(dateTime) {
    if (!dateTime) {
      return "";
    }
    return Moment(dateTime);
  }

  function formatDateTime(dateTime) {
    if (!dateTime) {
      return "";
    }

    return Moment(dateTime).format("YYYY-MM-DD HH:mm:ss");
  }

  function handleBlur(e) {
	console.log("DEBUG handleChangeEndDate handleBlur formState.startAtWork, formState.endAtWork: ", formState.startAtWork, formState.endAtWork); // nic pri pridavani
    distributeValue(formState.startAtWork, formState.endAtWork);
  }

  function handleChangeStartDate(date) {
    setStartEndAtWorkAfterChange(date);
  }

  function handleChangeEndDate(date) {
    setFormState((formState) => ({
      ...formState,
      endAtWork: date
    }));

	console.log("DEBUG handleChangeEndDate date, formState.endAtWork: ", date, formState.endAtWork); // date je dobre,formState.endAtWork je spatne
	console.log("DEBUG handleChangeEndDate formState ", formState);
	//Uncaught ReferenceError: endAtWork is not defined console.log("DEBUG handleChangeEndDate endAtWork ", endAtWork);

	//setEnd(date);

    //distributeValue(formState.startAtWork, formState.endAtWork);
    distributeValue(formState.startAtWork, date);
  } 

  function distributeValue(start, end) {
	console.log("DEBUG start, end: ", start, end); // je to useknuty
    let startObj = Moment(start);
    let endObj = Moment(end);
    let startAtWork;
    let endAtWork;
    let maxLongMassage = 120;

//    if (startObj > endObj || endObj - startObj > maxLongMassage * 60 * 1000) {
//      alertHelper.error({ message: "Zadané datum není validní" });
//    } else {
      startAtWork = formatDateTime(start);
      endAtWork = formatDateTime(end);
//    }

    setStart(startAtWork);
    setEnd(endAtWork);
  }

		//{/*renderInput={props => <TextField label="Date" helperText="Something" /> } */}
//{/*renderInput={(props) => <TextField {...props} />}*/}

//disabled={disabled}
/*
            name="startAtWork"
            value={formState.startAtWork}
            label="Začátek"
            onChange={handleChangeStartDate}
            onBlur={handleBlur}
            className={classes.textField}
            inputVariant="outlined"
            format="dd.MM. yyyy HH:mm"
            autoOk
            ampm={false}
		renderInput={(params) => <TextField {...params} />}

*/
  return (
    <>
      {/*<MuiPickersUtilsProvider utils={DateFnsUtils} locale={csLocale}>*/}
      {/*<LocalizationProvider dateAdapter={AdapterDateFns} locale={csLocale}>*/}
        <Grid item xs={6}>
	  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={csLocale}>
          <DateTimePicker
		disabled={disabled}
		name="startAtWork"
     		label="Začátek"
    		value={formState.startAtWork}
    		onChange={handleChangeStartDate}
		onBlur={handleBlur}
		className={classes.textField}
		inputVariant="outlined"
		format="dd.MM. yyyy HH:mm"
		autoOk
		ampm={false}
		views = {['day' ,'hours']}
    		renderInput={(params) => <TextField {...params} />}           
          />
	  </LocalizationProvider>
        </Grid>
        <Grid item xs={6}>
	  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={csLocale}>
          <DateTimePicker
		disabled={disabled}
		name="endAtWork"
		value={formState.endAtWork}
		label="Konec"
		onChange={handleChangeEndDate}
		onBlur={handleBlur}
		className={classes.textField}
		inputVariant="outlined"
		format="dd.MM. yyyy HH:mm"
		ampm={false}
		minutesStep={60}
		views = {['day' ,'hours']}
		
		renderInput={(params) => <TextField {...params} />}
          />
	  </LocalizationProvider>
        </Grid>
      {/*</LocalizationProvider>*/}
    </>
  );
}
//DateTimePicker
// renderInput={(props) => <TextField {...props} />}
// minutesStep={60} nepomohlo
// openTo={'day' | 'hours' | 'month'  | 'year' } porad zobrazuje i roky i bez | 'year'


const useStyles = makeStyles((theme) => ({
  textField: {
    width: 260
  }
}));

export default HMStartEndSection;
