import { IconButton, Tooltip } from "@material-ui/core";
import React from "react";
import GrantedAuthHelper from "src/helpers/GrantedAuthHelper";

function EnhancedIconButton(props) {
  const { ariaLabel, size, onClick, Icon, tooltip, className, color, authority, disabled } = props;
  const calcDisabled = disabled || (authority && !GrantedAuthHelper.hasAuthority(authority));

  return (
    <Tooltip title={tooltip}>
      <IconButton
        className={className}
        disabled={calcDisabled}
        aria-label={ariaLabel}
        size={size}
        color={color}
        onClick={onClick}>
        <Icon />
      </IconButton>
    </Tooltip>
  );
}

export default EnhancedIconButton;
