import { Grid } from "@material-ui/core";
import React, {useEffect} from "react";
import EnhancedSelect from "./EnhancedSelect";
import {useDispatch, useSelector} from "react-redux";
import {fetchLimitedPaymentTypes} from "../../features/paymentSlice";
import EnhancedTextField from "./EnhancedTextField";
import PaymentTypeResolver from "src/helpers/paymentTypeResolver";

function EnhancedTicketPayment(props) {
  const dispatch = useDispatch();

  const { formState, open, handleChange, disabled, fetchPriceMethod } = props;

  const paymentTypeLimitedList = useSelector((state) => state.payment.paymentTypeLimitedList);
  const typePriceOfMassageList = useSelector((state) => state.payment.typePriceOfMassageList);

  useEffect(() => {
    const { paymentTypeId } = formState;
    if (open) {
      dispatch(fetchLimitedPaymentTypes());
      paymentTypeId && dispatch(fetchPriceMethod(paymentTypeId));
    }
  }, [open, formState, dispatch, fetchPriceMethod]);

  function isDisabledBillNumber() {
    return !PaymentTypeResolver.isBill(formState.paymentTypeId);
  }

  return (
    <>
      <Grid item xs={6}>
        <EnhancedSelect
          name="paymentTypeId"
          value={formState.paymentTypeId}
          label="Způsob platby"
          list={paymentTypeLimitedList}
          onChange={handleChange}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6}>
        <EnhancedTextField
          name="billNumber"
          value={formState.billNumber}
          label="Číslo faktury"
          onChange={handleChange}
          disabled={isDisabledBillNumber()}
        />
      </Grid>
      <Grid item xs={12}>
        <EnhancedSelect
          name="typeOfMassageId"
          value={formState.typeOfMassageId}
          label="Typ a cena masáže"
          list={typePriceOfMassageList}
          onChange={handleChange}
          disabled={!!formState.paymentId}
        />
      </Grid>
      <Grid item xs={12}>
        <EnhancedTextField
          name="invoiceAddress"
          value={formState.invoiceAddress}
          label="Fakturační adresa"
          onChange={handleChange}
        />
      </Grid>
    </>
  );
}

export default EnhancedTicketPayment;
