import { Paper, TableCell } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Edit, Home, Settings } from "@mui/icons-material";//@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EnhancedIconButton from "src/components/enhanced/EnhancedIconButton";
import EnhancedTable from "src/components/enhanced/EnhancedTable";
import EnhancedTableToolbar from "src/components/enhanced/EnhancedTableToolbar";
import UserCompanyDialog from "src/components/users/UserCompanyDialog";
import UserDialog from "src/components/users/UserDialog";
import UserSpecialEditDialog from "src/components/users/UserSpecialEditDialog";
import { fetchUserListByCompanyId, fetchUserProfileTypes } from "src/features/usersSlice";
import { fetchAclRoles } from "src/features/listsSlice";

function UserTable() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const userList = useSelector((state) => state.users.userList);

  const [openSpecialEdit, setOpenSpecialEdit] = useState(false);
  const [openCompanyUser, setOpenCompanyUser] = useState(false);
  const [openNew, setOpenNew] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});

  useEffect(() => {
    dispatch(fetchUserListByCompanyId());
    dispatch(fetchAclRoles());
    dispatch(fetchUserProfileTypes());
  }, [dispatch]);

  const headerList = [
    { align: "left", label: "" },
    { align: "right", label: "Jméno" },
    { align: "right", label: "Email" },
    { align: "right", label: "Telefon" },
    { align: "right", label: "Role" }
  ];

  function handleEdit(user) {
    setSelectedUser(user);
    setOpenEdit(true);
  }

  function handleSpecialEdit(user) {
    setSelectedUser(user);
    setOpenSpecialEdit(true);
  }

  function handleCompanyUser(user) {
    setSelectedUser(user);
    setOpenCompanyUser(true);
  }

  const renderRowCells = (user) => (
    <>
      <TableCell>
        <EnhancedIconButton
          authority="user.edit"
          ariaLabel="edit row"
          size="small"
          tooltip="Upravit"
          Icon={Edit}
          onClick={() => handleEdit(user)}
        />
        <EnhancedIconButton
          authority="user.edit"
          ariaLabel="profile row"
          size="small"
          tooltip="Nastavení"
          Icon={Settings}
          onClick={() => handleSpecialEdit(user)}
        />
        <EnhancedIconButton
          authority="user.all"
          ariaLabel="organization row"
          size="small"
          tooltip="Nastavení organizace"
          Icon={Home}
          onClick={() => handleCompanyUser(user)}
        />
      </TableCell>
      <TableCell align="right">
        {user["firstName"]} {user["lastName"]}
      </TableCell>
      <TableCell align="right">{user["email"]}</TableCell>
      <TableCell align="right">{user["phone"]}</TableCell>
      <TableCell align="right">{user["role"]["name"]}</TableCell>
    </>
  );

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar authority="user.edit" label="Uživatelé" setOpenNew={setOpenNew} addible />
        <EnhancedTable headerList={headerList} dataList={userList} renderRowCells={renderRowCells} />
      </Paper>
      <UserSpecialEditDialog user={selectedUser} open={openSpecialEdit} setOpen={setOpenSpecialEdit} />
      <UserDialog
        user={selectedUser}
        openNew={openNew}
        openEdit={openEdit}
        setOpenEdit={setOpenEdit}
        setOpenNew={setOpenNew}
      />
      <UserCompanyDialog user={selectedUser} open={openCompanyUser} setOpen={setOpenCompanyUser} />
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%"
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  }
}));

export default UserTable;
