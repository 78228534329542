import { Dialog, DialogActions, DialogContent, Grid } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EnhancedConfirmButton from "src/components/enhanced/EnhancedConfirmButton";
import EnhancedSelect from "src/components/enhanced/EnhancedSelect";
import EnhancedTextField from "src/components/enhanced/EnhancedTextField";
import { /*fetchMasseurs,*/ fetchReceptionists } from "src/features/listsSlice";
import { fetchCars } from "src/features/carSlice";
import { createAllDayEventAsync, /*fetchReservationsByCompanyId*/ } from "src/features/reservationSlice";
import { fetchR2 } from "src/features/reservation2Slice";
import { getSessionCheckedCompany, getSessionCheckedRooms } from "src/helpers/sessionHelper";
import EnhancedDialogTitle from "../enhanced/EnhancedDialogTitle";

function AllDayModal(props) {
  const dispatch = useDispatch();
  const receptionistList = useSelector((state) => state.lists.receptionistList);
  const carList = useSelector((state) => state.car.carList);//  const masseurList = useSelector((state) => state.lists.masseurList); // !!! TODO
  //const reservationsByCompanyId = useSelector((state) => state.reservation.reservationsByCompanyId);
 //err const reservationsByCompanyId2 = useSelector((state) => state.reservation2.reservationsByCompanyId2);
  //const reservation2 = useSelector((state) => state.reservation2);

  const { open, date, setOpen } = props;

  const [formState, setFormState] = useState({});

/*  useEffect(() => {
    setFormState((formState) => ({
      ...formState,
      startAtWork: masseurList?.find((m) => m.id === +formState.masseur)?.startAtWork || "07:30"
    }));
  }, [formState.masseur, masseurList]);*/

  useEffect(() => {
    if (getSessionCheckedCompany()) {
      dispatch(fetchReceptionists());
      //dispatch(fetchMasseurs()); volalo se pri https://172.18.0.4:3000/calendar
 //     dispatch(fetchReservationsByCompanyId());
   //   dispatch(fetchR2());
    }
  }, [dispatch]);

 // console.log("DEBUG AllDayModal.jsx reservationsByCompanyId, ", reservationsByCompanyId); //empty
  //console.log("DEBUG AllDayModal.jsx reservation2, ", reservation2); //
  console.log("DEBUG carList, ", carList); //
   console.log("DEBUG AllDayModal.jsx receptionistList, ", receptionistList);

  const handleOk = () => {
    const apiData = {};
    apiData.masseurId = +formState.masseur;
    apiData.receptionistId = +formState.receptionist;
    apiData.roomId = getSessionCheckedRooms().map((c) => c[0])[0];
    apiData.startAtWork = formState.startAtWork;
    apiData.date = date;

    dispatch(createAllDayEventAsync(apiData));
    handleClose();
  };

  const handleClose = () => {
    setFormState({});
    setOpen(false);
  };

  const handleChange = (e) => {
    e.persist();
    setFormState((formState) => ({
      ...formState,
      [e.target.name]: e.target.value
    }));
  };

  const isOkDisabled = () => !formState.receptionist || !formState.masseur;

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="md">
        <EnhancedDialogTitle handleClose={handleClose} nameLabel="Naplánování celého dne" />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <EnhancedSelect
                name="receptionist"
                value={formState.receptionist}
                label="Recepční"
                list={receptionistList}
                itemName="fullName"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <EnhancedSelect
                name="esVozidlo"
                value={'1'}
                label="Vozidlo"
                list={carList}
                itemName="itemNameVozidlo"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <EnhancedTextField
                name="startAtWork"
                value={formState.startAtWork}
                label="Začátek práce"
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <EnhancedConfirmButton handleClose={handleClose} handleOk={handleOk} disabledOk={isOkDisabled()} />
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default AllDayModal;
