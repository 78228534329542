import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  exportCompanyCardReport,
  exportCompanySlevomatApplyReport,
  exportCompanySlevomatPaidReport,
  exportMasseursWorkSheetReport,
  exportReceptionistCashReport
} from "src/features/report";
import EnhancedConfirmButton from "src/components/enhanced/EnhancedConfirmButton";
import EnhancedSelect from "src/components/enhanced/EnhancedSelect";
import { fetchMasseurs, fetchReceptionists } from "src/features/listsSlice";
import GrantedAuthHelper from "src/helpers/GrantedAuthHelper";
import { getSessionCheckedCompany } from "src/helpers/sessionHelper";
import EnhancedTableToolbar from "../enhanced/EnhancedTableToolbar";
import EnhancedDateField from "src/components/enhanced/EnhancedDateField";

function ReportForm() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const masseurList = useSelector((state) => state.lists.masseurList);
  const receptionistList = useSelector((state) => state.lists.receptionistList);

  useEffect(() => {
    if (GrantedAuthHelper.hasAuthority("calendar.edit")) {
      dispatch(fetchMasseurs());
      dispatch(fetchReceptionists());
    }
  }, [dispatch]);

  const [formState, setFormState] = useState({});

  const handleChange = (event) => {
    event.persist();
    setFormState((formState) => ({
      ...formState,
      [event.target.name]: event.target.value
    }));
  };

  const handleGenerate = () => {
    const apiData = {
      dateSince: formState.dateSince,
      dateTo: formState.dateTo,
      companyId: getSessionCheckedCompany()
    };

    switch (formState.scenario) {
      case "monthly-receptionist":
        apiData.userId = formState.receptionistId || 0;
        dispatch(exportReceptionistCashReport(apiData));
        break;
      case "company-payment":
        dispatch(exportCompanyCardReport(apiData));
        break;
      case "monthly-masseurs":
        apiData.userId = formState.masseurId || 0;
        dispatch(exportMasseursWorkSheetReport(apiData));
        break;
      case "slevomat-paid":
        dispatch(exportCompanySlevomatPaidReport(apiData));
        break;
      case "slevomat-applied":
        dispatch(exportCompanySlevomatApplyReport(apiData));
        break;
      default:
        break;
    }
  };

  let scenarioList = [];

  if (GrantedAuthHelper.hasAuthority("report.monthly-receptionist")) {
    scenarioList.push({ id: "monthly-receptionist", name: "Výkaz práce recepčních" });
  }

  if (GrantedAuthHelper.hasAuthority("report.company-payment")) {
    scenarioList.push({ id: "company-payment", name: "Platby kartou za pobočku" });
  }

  if (GrantedAuthHelper.hasAuthority("report.monthly-masseurs")) {
    scenarioList.push({ id: "monthly-masseurs", name: "Výkaz práce po masérech" });
  }

  if (GrantedAuthHelper.hasAuthority("report.slevomat-paid")) {
    scenarioList.push({ id: "slevomat-paid", name: "Slevomat - zaplacené" });
  }

  if (GrantedAuthHelper.hasAuthority("report.slevomat-applied")) {
    scenarioList.push({ id: "slevomat-applied", name: "Slevomat - uplatněné" });
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar label="Reporty" />
        <Grid spacing={12}>
          <Grid item xs={12} className={classes.formControl}>
            <EnhancedDateField
                name="dateSince"
                value={formState.dateSince}
                onChange={handleChange}
                label="Datum od"
            />
          </Grid>
          <Grid item xs={12} className={classes.formControl}>
            <EnhancedDateField
                name="dateTo"
                value={formState.dateTo}
                onChange={handleChange}
                label="Datum do"
            />
          </Grid>
          <Grid item xs={12} className={classes.formControl}>
            <EnhancedSelect
              name="scenario"
              value={formState.scenario}
              label="Scénář"
              list={scenarioList}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} className={classes.formControl}>
            {formState.scenario === "monthly-receptionist" && receptionistList.length > 0 ? (
              <EnhancedSelect
                name="receptionistId"
                value={formState.receptionistId}
                label="Recepční"
                list={receptionistList}
                itemName={"fullName"}
                onChange={handleChange}
              />
            ) : null}
            {formState.scenario === "monthly-masseurs" && masseurList.length > 0 ? (
              <EnhancedSelect
                name="masseurId"
                value={formState.masseurId}
                label="Masér"
                list={masseurList}
                itemName={"fullName"}
                onChange={handleChange}
              />
            ) : null}
          </Grid>
          <Grid item xs={12}>
            <EnhancedConfirmButton handleOk={handleGenerate} okLabel="Vygenerovat report" />
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: theme.spacing(4),
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2)
  },
  formControl: {
    width: "100%",
    padding: '5px'
  }
}));

export default ReportForm;
