import { ThemeProvider } from "@material-ui/styles";
import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import { useSelector } from "react-redux";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import RouteCustom from "src/app/RouteCustom";
import AgendaList from "src/components/agenda/Agenda";
import Alert from "src/components/Alert";
import Calendar from "src/components/calendar/Calendar";
import CleanerAgendaList from "src/components/cleaner/Agenda";
import Company from "src/components/company/Company";
import CustomerPage from "src/components/customer/Customer";
import Dashboard from "src/components/dashboard/Dashboard";
import MainLayout from "src/components/layouts/main/Main";
import MinimalLayout from "src/components/layouts/minimal/Minimal";
import NotFound from "src/components/NotFound";
import PassLog from "src/components/passlog/PassLog";
import ReportForm from "src/components/report/Report";
import CompanySumTable from "src/components/companysum/CompanySum";
import SignIn from "src/components/SignIn";
import SignUp from "src/components/SignUp";
import Users from "src/components/users/Users";
//import Color from "src/components/color/Color";
import Car from "src/components/car/Car";
import Voucher from "src/components/voucher/Voucher";
import Pricing from "src/components/pricing/Pricing";
import AdvancedReportForm from "src/components/advancedreport/AdvancedReport";
import GrantedAuthHelper from "src/helpers/GrantedAuthHelper";
import theme from "src/theme";

const App = () => {
  useSelector((state) => {
    GrantedAuthHelper.load(state.auth.role);
    return state.auth.role;
  });
/*
let calendar2 = new Calendar(calendarEl, {
  plugins: [ resourceTimelinePlugin ],
  initialView: 'resourceTimeline',
  resources: [
    // your resource list
  ]
});*/

  return (
    <div>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Redirect exact from="/" to="/dashboard" />
            <RouteCustom component={Dashboard} layout={MainLayout} exact path="/dashboard" />
            <RouteCustom component={Calendar} layout={MainLayout} exact path="/calendar" />
            <RouteCustom component={CustomerPage} layout={MainLayout} exact path="/customer-management" />
            {/*<RouteCustom component={Color} layout={MainLayout} exact path="/color-management" />*/}
            <RouteCustom component={Users} layout={MainLayout} exact path="/user-management" />
            <RouteCustom component={Company} layout={MainLayout} exact path="/company-management" />
            <RouteCustom component={Car} layout={MainLayout} exact path="/car-management" />
            <RouteCustom component={Voucher} layout={MainLayout} exact path="/voucher-management" />
            <RouteCustom component={PassLog} layout={MainLayout} exact path="/pass-log-management" />
            <RouteCustom component={AgendaList} layout={MainLayout} exact path="/agenda" />
            <RouteCustom component={CleanerAgendaList} layout={MainLayout} exact path="/cleaner-agenda" />
            <RouteCustom component={ReportForm} layout={MainLayout} exact path="/report-management" />
            <RouteCustom component={AdvancedReportForm} layout={MainLayout} exact path="/adv-report-management" />
            <RouteCustom component={CompanySumTable} layout={MainLayout} exact path="/sum-management" />
            <RouteCustom component={Pricing} layout={MainLayout} exact path="/pricing-management" />
            <RouteCustom component={SignIn} layout={MinimalLayout} exact path="/sign-in" />
            <RouteCustom component={SignUp} layout={MinimalLayout} exact path="/sign-up" />
            <RouteCustom component={NotFound} layout={MinimalLayout} exact path="/not-found" />
            <Redirect to="/not-found" />
          </Switch>
        </Router>
        <Alert />
      </ThemeProvider>
    </div>
  );
};

export default App;
