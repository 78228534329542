import { configureStore } from "@reduxjs/toolkit";
import applyTicketReducer from "src/features/applySlice";
import authReducer from "src/features/authSlice";
import companySumReducer from "src/features/companySumSlice";
import dailyNoteReducer from "src/features/dailyNoteSlice";
import listsReducer from "src/features/listsSlice";
import modalEditReducer from "src/features/modalEditSlice";
import passLogReducer from "src/features/passLogSlice";
import paymentReducer from "src/features/paymentSlice";
import reservationReducer from "src/features/reservationSlice";
import usersReducer from "src/features/usersSlice";
import voucherReducer from "src/features/voucherSlice";
import pricingSlice from "src/features/pricingSlice";
import colorReducer from "src/features/colorSlice";
import carReducer from "src/features/carSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    companySum: companySumReducer,
    dailyNote: dailyNoteReducer,
    reservation: reservationReducer,
    lists: listsReducer,
    modalEdit: modalEditReducer,
    applyTicket: applyTicketReducer,
    payment: paymentReducer,
    passLog: passLogReducer,
    voucher: voucherReducer,
    users: usersReducer,
    pricing: pricingSlice,
    color: colorReducer,
    car: carReducer
  }
});
